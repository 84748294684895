import React from "react"
import { Row, Col, Image } from "antd"
import makeindia from "../../images/footer/makeindia.png"
import miilogo from "../../images/footer/miilogo.png";
import linkdin from "../../images/footer/linkedin.png";
import facebook from "../../images/footer/facebook.png";
import useWindowDimensions from "./../../useWindowDimensions"
import makeindiasvg from "../../images/footer/makeinindiasvg.svg"
import makeinindialogo from "../../images/footer/makeinindialogo.svg";

import "./style.less"
import Button from "../Button/Button"
const Footer = () => {
  const { height, width } = useWindowDimensions()

  return (
    <div className="footerContainer">
      <Row justify="center" className="secondrow">
        <Col lg={7} md={24} className="d-f a-c j-c">
          <div className="copyrighttext">
            Copyrights 2021 Acey Engineering | Crafted by{" "}
            <a href="https://www.togglehead.in/">Togglehead</a>
          </div>
        </Col>

        <Col lg={6} md={0}></Col>
        {width > 991 ? (
          <Col lg={8} md={24} className="d-f a-c jc-fe">
            <div className="makeInIndiaText">Make in India</div>
            <div>
              <img className="fluid miilogoclass" src={makeinindialogo} />
            </div>

            <div className="line"></div>
            <div className="makeInIndiaText">Follow us on</div>
            <img
              onClick={() =>
                window.open(
                  "https://www.linkedin.com/company/acey-engineering-pvt-ltd/",
                  "_blank"
                )
              }
              className="linkIcon"
              src={linkdin}
            />
            <img
              className="twittericon"
              onClick={() =>
                window.open("https://www.facebook.com/aceyindia", "_blank")
              }
              src={facebook}
            />
          </Col>
        ) : (
          <>
            <Col md={24} className="d-f a-c j-c">
              <div className="makeInIndiaText">Follow us on</div>
              <img
                onClick={() =>
                  window.open(
                    "https://www.linkedin.com/company/acey-engineering-pvt-ltd/",
                    "_blank"
                  )
                }
                className="linkIcon"
                src={linkdin}
              />
              <img
                onClick={() =>
                  window.open("https://www.facebook.com/aceyindia", "_blank")
                }
                className="twittericon"
                src={facebook}
              />
            </Col>
            <Col md={24} className="d-f a-c j-c">
              <div className="makeInIndiaText">Make in India</div>
              <img className="miilogoclass fuild" src={makeinindialogo} />
            </Col>
          </>
        )}
      </Row>
    </div>
  );
}

export default Footer
