import React, { useState, useEffect } from "react"
import BlueHeader from "../../component/BlueHeader/BlueHeader"
import WOW from "wowjs"

import deskheaderbg from "../../images/headerbg.png"

import rightArrow from "../../images/automotive/rightArrow.png"
import leftArrow from "../../images/automotive/leftArrow.png"
import cancel from "../../images/automotive/cancel.png"

//two
import Two_CBSCables from "../../images/automotive/TwoWheelers/CBSCables.png"
import Two_ClutchCable from "../../images/automotive/TwoWheelers/ClutchCable.png"
import Two_SeatLockCable from "../../images/automotive/TwoWheelers/SeatLockCable.png"
import Two_SpeedometerCable from "../../images/automotive/TwoWheelers/SpeedometerCable.png"
import Two_StarterCable from "../../images/automotive/TwoWheelers/StarterCable.png"
import Two_ThrottleCable from "../../images/automotive/TwoWheelers/ThrottleCable.png"

////three wheeler
import AcceleratorCable from "../../images/automotive/ThreeWheelers/AcceleratorCable.png"
import BrakeCableRear from "../../images/automotive/ThreeWheelers/BrakeCableRear.png"
import BrakeCable from "../../images/automotive/ThreeWheelers/BrakeCable.png"
import ChokeCable from "../../images/automotive/ThreeWheelers/ChokeCable.png"
import ClutchCable from "../../images/automotive/ThreeWheelers/ClutchCable.png"
import DecompressorCable from "../../images/automotive/ThreeWheelers/DecompressorCable.png"
import DoorCable from "../../images/automotive/ThreeWheelers/DoorCable.png"
import EngineStopCable from "../../images/automotive/ThreeWheelers/EngineStopCable.png"
import GearCableBLACK from "../../images/automotive/ThreeWheelers/GearCableBLACK.png"
import GearCableWHITE from "../../images/automotive/ThreeWheelers/GearCableWHITE.png"
import ReverseGearCable from "../../images/automotive/ThreeWheelers/ReverseGearCable.png"
import ReverseLockCable from "../../images/automotive/ThreeWheelers/ReverseLockCable.png"
import SpeedometerCable from "../../images/automotive/ThreeWheelers/SpeedometerCable.png"
import fuelcockcable from "../../images/automotive/ThreeWheelers/fuelcockcable.png"

////passange wheeler
import p_AcceleratorCable from "../../images/automotive/passanger/AcceleratorCable.png"
import p_BonnetOpenerCable from "../../images/automotive/passanger/BonnetOpenerCable.png"
import p_BrakeCable from "../../images/automotive/passanger/BrakeCable.png"
import p_ClutchCable from "../../images/automotive/passanger/ClutchCable.png"
import p_DipstickAssemblies from "../../images/automotive/passanger/DipstickAssemblies.png"
import p_EscapeHatchCable from "../../images/automotive/passanger/EscapeHatchCable.png"
import p_FuelLidCable from "../../images/automotive/passanger/FuelLidCable.png"
import p_HoodReleaseCable from "../../images/automotive/passanger/HoodReleaseCable.png"
import p_ParkingBrakeCables from "../../images/automotive/passanger/ParkingBrakeCables.png"
import p_SeatLockCable from "../../images/automotive/passanger/SeatLockCable.png"
import p_GearShifter from "../../images/automotive/passanger/GearShifter.png"

////lcv

import l_BonnetReleaseCable from "../../images/automotive/LCV_MCV_HCV/BonnetReleaseCable.png"
import l_ClutchCable from "../../images/automotive/LCV_MCV_HCV/ClutchCable.png"
import l_DipstickAssembly from "../../images/automotive/LCV_MCV_HCV/DipstickAssembly.png"
import l_EngineStopCableShutoffCable from "../../images/automotive/LCV_MCV_HCV/EngineStopCableShutoffCable.png"
import l_ExcessFuelCable from "../../images/automotive/LCV_MCV_HCV/ExcessFuelCable.png"
import l_FuelLidCable from "../../images/automotive/LCV_MCV_HCV/FuelLidCable.png"
import l_parkingbrakeassistfrontpng from "../../images/automotive/LCV_MCV_HCV/parkingbrakeassistfrontpng.png"
import l_ParkingBrakeCable from "../../images/automotive/LCV_MCV_HCV/ParkingBrakeCable.png"
import l_ParkingBrakerear from "../../images/automotive/LCV_MCV_HCV/ParkingBrakerear.png"
import l_SpeedometerCable from "../../images/automotive/LCV_MCV_HCV/SpeedometerCable.png"
import l_VentillationCables from "../../images/automotive/LCV_MCV_HCV/VentillationCables.png"
import l_AcceleratorCable from "../../images/automotive/LCV_MCV_HCV/AcceleratorCable.png"
import l_BrakeCableAssyFront from "../../images/automotive/LCV_MCV_HCV/BrakeCableAssyFront.png"

////tractors
import t_CableLiftomatic from "../../images/automotive/tractors/CableLiftomatic.png"
import t_FootOpetaredAcceleratorCable from "../../images/automotive/tractors/FootOpetaredAcceleratorCable.png"
import t_HandOperatedAcceleratorCable from "../../images/automotive/tractors/HandOperatedAcceleratorCable.png"
import t_StopCable from "../../images/automotive/tractors/StopCable.png"
import t_TachometerCables from "../../images/automotive/tractors/TachometerCables.png"
import t_ThrottleCable from "../../images/automotive/tractors/ThrottleCable.png"
// import t_AcceleratorCable from '../../images/automotive/tractors/AcceleratorCable.png'
import t_TransmissionCable from "../../images/automotive/tractors/TransmissionCable.png"

////railways
import r_WireRopeArrangementforBrakingSystem from "../../images/automotive/railways/WireRopeArrangementforBrakingSystem.png"

//earth moving
import e_GearselectcableEarthMovingEquipment from "../../images/automotive/earth/GearselectcableEarthMovingEquipment.png"
import e_GearShiftAssyWithCable from "../../images/automotive/earth/GearShiftAssyWithCable.png"
import e_PUSHPULLCABLEWITHLEVERASSEMBLY from "../../images/automotive/earth/PUSHPULLCABLEWITHLEVERASSEMBLY.jpg"

//marine_app
import m_throttlecableandmarinecable from "../../images/automotive/marine/throttlecableandmarinecable.png"

//other
import o_BrakeCableforTrolleys from "../../images/automotive/other/BrakeCableforTrolleys.png"
import o_CentreCables from "../../images/automotive/other/CentreCables.png"
import o_HVACCables from "../../images/automotive/other/HVACCables.png"
import o_PanelOperatoryCables from "../../images/automotive/other/PanelOperatoryCables.png"
import o_SafetyCableforFans from "../../images/automotive/other/SafetyCableforFans.png"

//bicycles
import b_bicyclesbrakecable from "../../images/automotive/bicycles/bicyclesbrakecable.png"

import shortid from "shortid"

import dots from "../../images/homepage/dots.png"

import "./style.less"

import { Row, Col } from "antd"
import useWindowDimensions from "../../useWindowDimensions"
import CsrItem from "../../component/CsrComponent/CsrItem"
import AutomotiveListItem from "../../component/AutomotiveListItem/AutomotiveListItem"
import AutomotiveCardItem from "../../component/AutomotiveCardItem/AutomotiveCardItem"
import PaginationComponent from "../../component/PaginationComponent/PaginationComponent"
import Button from "../../component/Button/Button"

// const csrItemArr = [
//     {img:rowimg1,smImg:tree,title:"TREE PLANTATION DRIVE",desc:"We have planted over 350 trees in the surrounding areas of all plants over the past three years. We have a mission to plant a minimum of 100 trees every year.",reverse:false},
//     {img:rowimg2,smImg:plastic,title:"NO TO PLASTICS",desc:"Use of Recyclable if and where required is the way forward at Acey. For us, it’s a big NO to single use plastics.",reverse:true},
//     {img:rowimg3,smImg:education,title:"EDUCATION",desc:"Providing free education to the underprivileged in surrounding schools, books and stationary to the needy, donating clothes, shoes and school bags where required is an annual activity at Acey.",reverse:false},
//     {img:rowimg4,smImg:community,title:"Community",desc:"Acey has allocated a community hall for social gatherings, seminars and cultural programmes so as to encourage communal activities and educational seminars.",reverse:true},

// ]

const AutomotiveArr = [
  { img: Two_ClutchCable, title: "Clutch Cable" },
  { img: Two_ThrottleCable, title: "Throttle Cable" },
  { img: Two_SeatLockCable, title: "Seat Lock Cable" },
  { img: Two_SpeedometerCable, title: "Speedometer Cable" },
  { img: Two_StarterCable, title: "Starter Cable" },
  { img: Two_CBSCables, title: "CBS Cables" },

  { img: ClutchCable, title: "Clutch Cable" },
  { img: AcceleratorCable, title: "Accelerator Cable" },
  { img: GearCableBLACK, title: "Gear Cable Black" },
  { img: GearCableWHITE, title: "Gear Cable White" },
  { img: ReverseGearCable, title: "Reverse Gear Cable" },
  { img: DecompressorCable, title: "Decompression Cable" },
  { img: ChokeCable, title: "Choke Cable" },
  { img: EngineStopCable, title: "Engine Stop Cable" },
  { img: BrakeCable, title: "Brake Cable Front" },
  { img: BrakeCableRear, title: "Brake Cable Rear" },
  { img: ReverseLockCable, title: "Reverse Lock Cable" },
  { img: fuelcockcable, title: "Fuel Cock Cable" },
  { img: DoorCable, title: "Door Cable" },
  { img: SpeedometerCable, title: "Speedometer Cable" },

  {
    img: p_GearShifter,
    title: "Gear Shifter/Selector Cables(Transmission Shift Cable)",
  }, // n
  { img: p_ParkingBrakeCables, title: "PARKING BRAKE CABLE Rear Rear" },
  { img: p_BrakeCable, title: "PARKING BRAKE CABLE Rear Front" },
  { img: p_ClutchCable, title: "Clutch Cable" },
  { img: p_AcceleratorCable, title: "Accelerator Cable" },
  { img: p_HoodReleaseCable, title: "Hood Release Cable" },
  { img: p_BonnetOpenerCable, title: "Bonnet Opener Cable" },
  // {img:ClutchCable,title:"Window Regulator Cable"}, removed
  { img: p_FuelLidCable, title: "Fuel Lid Cable" },
  { img: p_SeatLockCable, title: "Seat Lock Cable" },
  { img: p_EscapeHatchCable, title: "Escape Hatch Cable" },
  // {img:ClutchCable,title:"Head Lamp Cable"}, removed
  { img: p_DipstickAssemblies, title: "Dipstick Assemblies" },
  // {img:ClutchCable,title:"Key Interlock Cable"},
  // {img:ClutchCable,title:"Trunk Operator Cable"}, removed

  { img: l_ParkingBrakerear, title: "PARKING BRAKE CABLE Rear" },
  { img: l_BrakeCableAssyFront, title: "PARKING BRAKE CABLE Front" }, // n
  { img: l_AcceleratorCable, title: "Accelerator Cable" }, //nnnn
  { img: l_ClutchCable, title: "Clutch Cable" },
  {
    img: l_EngineStopCableShutoffCable,
    title: "Engine Stop Cable/ Shut off Cable",
  },
  { img: l_BonnetReleaseCable, title: "Bonnet Release Cable" },
  { img: l_FuelLidCable, title: "Fuel Lid Cable" },
  // {img:ClutchCable,title:"Gear Shifter Cable"},
  // {img:ClutchCable,title:"Bonnet Release Cable"},  twice

  // {img:ClutchCable,title:"Gear Shifter Cable/Selector Cable"},
  { img: l_VentillationCables, title: "Ventillation Cables" },
  { img: l_SpeedometerCable, title: "Speedometer Cable" },
  { img: l_ExcessFuelCable, title: "Excess Fuel Cable" },
  // {img:ClutchCable,title:"Window Regulator Cable"},
  { img: l_DipstickAssembly, title: "Dipstick Assembly" },
  // {img:GearCableBLACK,title:"Shut Off Cable"},
  {
    img: e_PUSHPULLCABLEWITHLEVERASSEMBLY,
    title: "PUSH PULL CABLE WITH LEVER ASSEMBLY",
  },

  { img: b_bicyclesbrakecable, title: "Caliper Brake Cables" },
]

const twowheelersArr = [
  { img: Two_ClutchCable, title: "Clutch Cable" },
  { img: Two_ThrottleCable, title: "Throttle Cable" },
  { img: Two_SeatLockCable, title: "Seat Lock Cable" },
  { img: Two_SpeedometerCable, title: "Speedometer Cable" },
  { img: Two_StarterCable, title: "Starter Cable" },
  { img: Two_CBSCables, title: "CBS Cables" },
]

const threeWheelerArr = [
  { img: ClutchCable, title: "Clutch Cable" },
  { img: AcceleratorCable, title: "Accelerator Cable" },
  { img: GearCableBLACK, title: "Gear Cable Black" },
  { img: GearCableWHITE, title: "Gear Cable White" },
  { img: ReverseGearCable, title: "Reverse Gear Cable" },
  { img: DecompressorCable, title: "Decompression Cable" },
  { img: ChokeCable, title: "Choke Cable" },
  { img: EngineStopCable, title: "Engine Stop Cable" },
  { img: BrakeCable, title: "Brake Cable Front" },
  { img: BrakeCableRear, title: "Brake Cable Rear" },
  { img: ReverseLockCable, title: "Reverse Lock Cable" },
  { img: fuelcockcable, title: "Fuel Cock Cable" },
  { img: DoorCable, title: "Door Cable" },
  { img: SpeedometerCable, title: "Speedometer Cable" },
]

const passengevehicleArr = [
  {
    img: p_GearShifter,
    title: "Gear Shifter/Selector Cables(Transmission Shift Cable)",
  }, // n
  { img: p_ParkingBrakeCables, title: "PARKING BRAKE CABLE Rear" },
  { img: p_BrakeCable, title: "PARKING BRAKE CABLE Front" },
  { img: p_ClutchCable, title: "Clutch Cable" },
  { img: p_AcceleratorCable, title: "Accelerator Cable" },
  { img: p_HoodReleaseCable, title: "Hood Release Cable" },
  { img: p_BonnetOpenerCable, title: "Bonnet Opener Cable" },
  // {img:ClutchCable,title:"Window Regulator Cable"}, removed
  { img: p_FuelLidCable, title: "Fuel Lid Cable" },
  { img: p_SeatLockCable, title: "Seat Lock Cable" },
  { img: p_EscapeHatchCable, title: "Escape Hatch Cable" },
  // {img:ClutchCable,title:"Head Lamp Cable"}, removed
  { img: p_DipstickAssemblies, title: "Dipstick Assemblies" },
  // {img:ClutchCable,title:"Key Interlock Cable"},
  // {img:ClutchCable,title:"Trunk Operator Cable"}, removed
]

const lcvmcvArr = [
  { img: l_ParkingBrakerear, title: "PARKING BRAKE CABLE Rear" },
  { img: l_BrakeCableAssyFront, title: "PARKING BRAKE CABLE Front" }, // n
  { img: l_AcceleratorCable, title: "Accelerator Cable" }, //nnnn
  { img: l_ClutchCable, title: "Clutch Cable" },
  {
    img: l_EngineStopCableShutoffCable,
    title: "Engine Stop Cable/ Shut off Cable",
  },
  { img: l_BonnetReleaseCable, title: "Bonnet Release Cable" },
  { img: l_FuelLidCable, title: "Fuel Lid Cable" },
  // {img:ClutchCable,title:"Gear Shifter Cable"},
  // {img:ClutchCable,title:"Bonnet Release Cable"},  twice

  // {img:ClutchCable,title:"Gear Shifter Cable/Selector Cable"},
  { img: l_VentillationCables, title: "Ventillation Cables" },
  { img: l_SpeedometerCable, title: "Speedometer Cable" },
  { img: l_ExcessFuelCable, title: "Excess Fuel Cable" },
  // {img:ClutchCable,title:"Window Regulator Cable"},
  { img: l_DipstickAssembly, title: "Dipstick Assembly" },
  {
    img: e_PUSHPULLCABLEWITHLEVERASSEMBLY,
    title: "PUSH PULL CABLE WITH LEVER ASSEMBLY",
  },
  // {img:GearCableBLACK,title:"Shut Off Cable"},
]

const nonAutomotiveArr = [
  {
    img: t_HandOperatedAcceleratorCable,
    title: "Hand Operated Accelerator Cable",
  },
  {
    img: t_FootOpetaredAcceleratorCable,
    title: "Foot Opetared Accelerator Cable",
  },
  { img: t_TransmissionCable, title: "Transmission Cable" },
  { img: t_ThrottleCable, title: "Throttle Cable" },
  { img: t_StopCable, title: "Stop Cable" },
  { img: t_CableLiftomatic, title: "Cable Liftomatic" },
  { img: t_TachometerCables, title: "Tachometer Cables" },
  // {img:ClutchCable,title:"Gear Shifter /Selector Cable"},
  // {img:ClutchCable,title:"Brake Cable Assembly"},

  {
    img: r_WireRopeArrangementforBrakingSystem,
    title: "Wire Rope Arrangement for Braking System",
  },

  {
    img: e_GearselectcableEarthMovingEquipment,
    title: "Gear Select Assy with Cable",
  },

  { img: m_throttlecableandmarinecable, title: "Throttle Cables - Any Length" },

  { img: o_SafetyCableforFans, title: "Safety Cable for Fans" },
  { img: o_BrakeCableforTrolleys, title: "Brake Cable for Trolleys" },
  { img: o_PanelOperatoryCables, title: "Panel Operatory Cables" },
  { img: o_CentreCables, title: "Centre Cables" },
  { img: o_HVACCables, title: "HVAC Cables" },
]

const tractorsArr = [
  {
    img: t_HandOperatedAcceleratorCable,
    title: "Hand Operated Accelerator Cable",
  },
  {
    img: t_FootOpetaredAcceleratorCable,
    title: "Foot Opetared Accelerator Cable",
  },
  { img: t_TransmissionCable, title: "Transmission Cable" },
  { img: t_ThrottleCable, title: "Throttle Cable" },
  { img: t_StopCable, title: "Stop Cable" },
  { img: t_CableLiftomatic, title: "Cable Liftomatic" },
  { img: t_TachometerCables, title: "Tachometer Cables" },
  // {img:ClutchCable,title:"Gear Shifter /Selector Cable"},
  // {img:ClutchCable,title:"Brake Cable Assembly"},
]

const railwaysArr = [
  {
    img: r_WireRopeArrangementforBrakingSystem,
    title: "Wire Rope Arrangement for Braking System",
  },
]

const earthmovingArr = [
  {
    img: e_GearselectcableEarthMovingEquipment,
    title: "Gear Select Assy with Cable",
  },
]

const marineAppArr = [
  { img: m_throttlecableandmarinecable, title: "Throttle Cables - Any Length" },
]

const otherAppArr = [
  { img: o_SafetyCableforFans, title: "Safety Cable for Fans" },
  { img: o_BrakeCableforTrolleys, title: "Brake Cable for Trolleys" },
  { img: o_PanelOperatoryCables, title: "Panel Operatory Cables" },
  { img: o_CentreCables, title: "Centre Cables" },
  { img: o_HVACCables, title: "HVAC Cables" },
]

const bicycleArr = [
  { img: b_bicyclesbrakecable, title: "Caliper Brake Cables" },
]

const Automotive = (props) => {
  const { height, width } = useWindowDimensions()

  const [titles, setTitle] = useState("")
  const [selectedPage, setSelectedPage] = useState(0)
  const [selectedArray, setSelectedArray] = useState([])
  const [totalPage, setTotalPage] = useState(0)
  const [totalPageArray, setTotalPageArray] = useState([])
  const [totalSlicePageArray, settotalSlicePageArray] = useState([])
  const [popupShow, setPopupShow] = useState(false)
  const [bannerTitle, setBannerTitle] = useState("")
  const [isautomotive, SetisAutomotive] = useState(true)

  useEffect(() => {
    window.scrollTo(0, 0)

    new WOW.WOW({
      live: false,
    }).init()
  }, [])

  useEffect(() => {
    setSelectedPage(0)
    window.scrollTo(0, 0)
    //console.log(props.match.params.id, "oooo")
    if (props.match.params.id === undefined) {
      switch (props.match.params.name) {
        case "automotive":
          SetisAutomotive(true)
          setTitle("Automotive")
          setBannerTitle("Automotive products")
          if (width < 1024) {
            setTotalPage(Math.ceil(AutomotiveArr.length / 4))
            let temp = []
            //console.log(
            //   Math.ceil(AutomotiveArr.length / 4),
            //   AutomotiveArr.length
            // )
            for (let i = 1; i <= Math.ceil(AutomotiveArr.length / 4); i++) {
              temp.push(i)
            }
            setTotalPageArray(...[temp])
            //console.log(temp)
            settotalSlicePageArray(...[temp.slice(0, 3)])

            let pagestart = 0
            let temp2 = [...AutomotiveArr]
            setSelectedArray(temp2.slice(pagestart, pagestart + 4))
          } else {
            setTotalPage(Math.ceil(AutomotiveArr.length / 6))

            let temp = []

            for (let i = 1; i <= Math.ceil(AutomotiveArr.length / 6); i++) {
              temp.push(i)
            }

            //console.log(temp)
            setTotalPageArray(...[temp])
            settotalSlicePageArray(...[temp.slice(0, 3)])

            let pagestart = 0
            let temp2 = [...AutomotiveArr]
            setSelectedArray(temp2.slice(pagestart, pagestart + 6))
          }

          break

        case "nonautomotive":
          SetisAutomotive(false)

          setTitle("Non Automotive")
          setBannerTitle("Non-Automotive products")
          if (width < 1024) {
            setTotalPage(Math.ceil(nonAutomotiveArr.length / 4))
            let temp = []
            //console.log(
            //   Math.ceil(nonAutomotiveArr.length / 4),
            //   nonAutomotiveArr.length
            // )
            for (let i = 1; i <= Math.ceil(nonAutomotiveArr.length / 4); i++) {
              temp.push(i)
            }
            setTotalPageArray(...[temp])

            settotalSlicePageArray(...[temp.slice(0, 3)])

            let pagestart = 0
            let temp2 = [...nonAutomotiveArr]
            setSelectedArray(temp2.slice(pagestart, pagestart + 6))
          } else {
            setTotalPage(Math.ceil(nonAutomotiveArr.length / 6))

            let temp = []

            for (let i = 1; i <= Math.ceil(nonAutomotiveArr.length / 6); i++) {
              temp.push(i)
            }

            setTotalPageArray(...[temp])
            settotalSlicePageArray(...[temp.slice(0, 3)])

            let pagestart = 0
            let temp2 = [...nonAutomotiveArr]
            setSelectedArray(temp2.slice(pagestart, pagestart + 6))
          }

          break

        default:
          break
      }
    } else {
      //console.log(props.match.params.id)
      switch (props.match.params.id) {
        case "twowheelers":
          SetisAutomotive(true)
          setTitle("2 Wheelers")
          setBannerTitle("2 Wheelers")
          if (width < 1024) {
            setTotalPage(Math.ceil(twowheelersArr.length / 4))
            let temp = []
            //console.log(
            //   Math.ceil(twowheelersArr.length / 4),
            //   twowheelersArr.length
            // )
            for (let i = 1; i <= Math.ceil(twowheelersArr.length / 4); i++) {
              temp.push(i)
            }
            setTotalPageArray(temp)
            //console.log(temp)
            settotalSlicePageArray(temp.slice(0, 3))

            let temp3 = [...twowheelersArr]
            setSelectedArray(temp3.slice(0, 4))
          } else {
            setTotalPage(Math.ceil(twowheelersArr.length / 6))
            //console.log("===>", twowheelersArr)
            let temp = []

            for (let i = 1; i <= Math.ceil(twowheelersArr.length / 6); i++) {
              temp.push(i)
            }
            //console.log(twowheelersArr.length)
            //console.log(temp)
            setTotalPageArray(temp)
            settotalSlicePageArray(temp.slice(0, 3))

            let pagestart = 0
            let temp2 = [...twowheelersArr]
            setSelectedArray(temp2.slice(pagestart, pagestart + 6))
          }

          break

        case "threeWheelers":
          SetisAutomotive(true)

          setTitle("3 wheelers")
          setBannerTitle("3 wheelers")
          if (width < 1024) {
            setTotalPage(Math.ceil(threeWheelerArr.length / 4))
            let temp = []
            //console.log(
            //   Math.ceil(threeWheelerArr.length / 4),
            //   threeWheelerArr.length
            // )
            for (let i = 1; i <= Math.ceil(threeWheelerArr.length / 4); i++) {
              temp.push(i)
            }
            setTotalPageArray(temp)
            //console.log(temp)
            settotalSlicePageArray(temp.slice(0, 3))

            setSelectedArray(...[threeWheelerArr.slice(0, 4)])
          } else {
            setTotalPage(Math.ceil(threeWheelerArr.length / 6))

            let temp = []

            for (let i = 1; i <= Math.ceil(threeWheelerArr.length / 6); i++) {
              temp.push(i)
            }
            //console.log(threeWheelerArr.length)
            //console.log(temp)
            setTotalPageArray(temp)
            settotalSlicePageArray(temp.slice(0, 3))

            setSelectedArray(...[threeWheelerArr.slice(0, 6)])
          }

          break

        case "passengerVehicles":
          SetisAutomotive(true)

          setTitle("PASSENGER VEHICLES")
          setBannerTitle("PASSENGER VEHICLES")
          if (width < 1024) {
            setTotalPage(Math.ceil(passengevehicleArr.length / 4))
            let temp = []
            //console.log(
            //   Math.ceil(passengevehicleArr.length / 4),
            //   passengevehicleArr.length
            // )
            for (
              let i = 1;
              i <= Math.ceil(passengevehicleArr.length / 4);
              i++
            ) {
              temp.push(i)
            }
            setTotalPageArray(temp)
            //console.log(temp)
            settotalSlicePageArray(temp.slice(0, 3))

            setSelectedArray(passengevehicleArr.slice(0, 4))
          } else {
            setTotalPage(Math.ceil(passengevehicleArr.length / 6))

            let temp = []

            for (
              let i = 1;
              i <= Math.ceil(passengevehicleArr.length / 6);
              i++
            ) {
              temp.push(i)
            }
            //console.log(passengevehicleArr.length)
            //console.log(temp)
            setTotalPageArray(temp)
            settotalSlicePageArray(temp.slice(0, 3))

            setSelectedArray(passengevehicleArr.slice(0, 6))
          }

          break

        case "lcvmcvhcv":
          SetisAutomotive(true)

          setTitle("LCV/MCV/HCV")
          setBannerTitle("LCV/MCV/HCV")
          if (width < 1024) {
            setTotalPage(Math.ceil(lcvmcvArr.length / 4))
            let temp = []
            //console.log(Math.ceil(lcvmcvArr.length / 4), lcvmcvArr.length)
            for (let i = 1; i <= Math.ceil(lcvmcvArr.length / 4); i++) {
              temp.push(i)
            }
            setTotalPageArray(temp)
            //console.log(temp)
            settotalSlicePageArray(temp.slice(0, 3))

            setSelectedArray(lcvmcvArr.slice(0, 4))
          } else {
            setTotalPage(Math.ceil(lcvmcvArr.length / 6))

            let temp = []

            for (let i = 1; i <= Math.ceil(lcvmcvArr.length / 6); i++) {
              temp.push(i)
            }
            //console.log(lcvmcvArr.length)
            //console.log(temp)
            setTotalPageArray(temp)
            settotalSlicePageArray(temp.slice(0, 3))

            setSelectedArray(lcvmcvArr.slice(0, 6))
          }

          break

        case "bicycles":
          SetisAutomotive(true)

          setTitle("BICYCLES")
          setBannerTitle("BICYCLES")
          if (width < 1024) {
            setTotalPage(Math.ceil(bicycleArr.length / 4))
            let temp = []
            //console.log(Math.ceil(bicycleArr.length / 4), bicycleArr.length)
            for (let i = 1; i <= Math.ceil(bicycleArr.length / 4); i++) {
              temp.push(i)
            }
            setTotalPageArray(temp)
            //console.log(temp)
            settotalSlicePageArray(temp.slice(0, 3))

            setSelectedArray(bicycleArr.slice(0, 4))
          } else {
            setTotalPage(Math.ceil(bicycleArr.length / 6))

            let temp = []

            for (let i = 1; i <= Math.ceil(bicycleArr.length / 6); i++) {
              temp.push(i)
            }
            //console.log(bicycleArr.length)
            //console.log(temp)
            setTotalPageArray(temp)
            settotalSlicePageArray(temp.slice(0, 3))

            setSelectedArray(bicycleArr.slice(0, 6))
          }

          break

        case "tractors":
          SetisAutomotive(false)

          setTitle("Tractors")
          setBannerTitle("Tractors")
          if (width < 1024) {
            setTotalPage(Math.ceil(tractorsArr.length / 4))
            let temp = []
            //console.log(Math.ceil(tractorsArr.length / 4), tractorsArr.length)
            for (let i = 1; i <= Math.ceil(tractorsArr.length / 4); i++) {
              temp.push(i)
            }
            setTotalPageArray(temp)
            //console.log(temp)
            settotalSlicePageArray(temp.slice(0, 3))

            setSelectedArray(tractorsArr.slice(0, 4))
          } else {
            setTotalPage(Math.ceil(tractorsArr.length / 6))

            let temp = []

            for (let i = 1; i <= Math.ceil(tractorsArr.length / 6); i++) {
              temp.push(i)
            }
            //console.log(tractorsArr.length)
            //console.log(temp)
            setTotalPageArray(temp)
            settotalSlicePageArray(temp.slice(0, 3))

            setSelectedArray(tractorsArr.slice(0, 6))
          }

          break

        case "railways":
          SetisAutomotive(false)

          setTitle("Railways")
          setBannerTitle("Railways")
          if (width < 1024) {
            setTotalPage(Math.ceil(railwaysArr.length / 4))
            let temp = []
            //console.log(Math.ceil(railwaysArr.length / 4), railwaysArr.length)
            for (let i = 1; i <= Math.ceil(railwaysArr.length / 4); i++) {
              temp.push(i)
            }
            setTotalPageArray(temp)
            //console.log(temp)
            settotalSlicePageArray(temp.slice(0, 3))

            setSelectedArray(railwaysArr.slice(0, 4))
          } else {
            setTotalPage(Math.ceil(railwaysArr.length / 6))

            let temp = []

            for (let i = 1; i <= Math.ceil(railwaysArr.length / 6); i++) {
              temp.push(i)
            }
            //console.log(railwaysArr.length)
            //console.log(temp)
            setTotalPageArray(temp)
            settotalSlicePageArray(temp.slice(0, 3))

            setSelectedArray(railwaysArr.slice(0, 6))
          }

          break

        case "earthmovingequipment":
          SetisAutomotive(false)

          setTitle("Earth Moving Equipment")
          setBannerTitle("Earth Moving Equipment")
          if (width < 1024) {
            setTotalPage(Math.ceil(earthmovingArr.length / 4))
            let temp = []
            //console.log(
            //   Math.ceil(earthmovingArr.length / 4),
            //   earthmovingArr.length
            // )
            for (let i = 1; i <= Math.ceil(earthmovingArr.length / 4); i++) {
              temp.push(i)
            }
            setTotalPageArray(temp)
            //console.log(temp)
            settotalSlicePageArray(temp.slice(0, 3))

            setSelectedArray(earthmovingArr.slice(0, 4))
          } else {
            setTotalPage(Math.ceil(earthmovingArr.length / 6))

            let temp = []

            for (let i = 1; i <= Math.ceil(earthmovingArr.length / 6); i++) {
              temp.push(i)
            }
            //console.log(earthmovingArr.length)
            //console.log(temp)
            setTotalPageArray(temp)
            settotalSlicePageArray(temp.slice(0, 3))

            setSelectedArray(earthmovingArr.slice(0, 6))
          }

          break

        case "marineapplication":
          SetisAutomotive(false)

          setTitle("Marine Applications")
          setBannerTitle("Marine Applications")
          if (width < 1024) {
            setTotalPage(Math.ceil(marineAppArr.length / 4))
            let temp = []
            //console.log(Math.ceil(marineAppArr.length / 4), marineAppArr.length)
            for (let i = 1; i <= Math.ceil(marineAppArr.length / 4); i++) {
              temp.push(i)
            }
            setTotalPageArray(temp)
            //console.log(temp)
            settotalSlicePageArray(temp.slice(0, 3))

            setSelectedArray(marineAppArr.slice(0, 4))
          } else {
            setTotalPage(Math.ceil(marineAppArr.length / 6))

            let temp = []

            for (let i = 1; i <= Math.ceil(marineAppArr.length / 6); i++) {
              temp.push(i)
            }
            //console.log(marineAppArr.length)
            //console.log(temp)
            setTotalPageArray(temp)
            settotalSlicePageArray(temp.slice(0, 3))

            setSelectedArray(marineAppArr.slice(0, 6))
          }

          break

        case "otherApplication":
          SetisAutomotive(false)

          setTitle("Other Applications")
          setBannerTitle("Other Applications")
          if (width < 1024) {
            setTotalPage(Math.ceil(otherAppArr.length / 4))
            let temp = []
            //console.log(Math.ceil(otherAppArr.length / 4), otherAppArr.length)
            for (let i = 1; i <= Math.ceil(otherAppArr.length / 4); i++) {
              temp.push(i)
            }
            setTotalPageArray(temp)
            //console.log(temp)
            settotalSlicePageArray(temp.slice(0, 3))

            setSelectedArray(otherAppArr.slice(0, 4))
          } else {
            setTotalPage(Math.ceil(otherAppArr.length / 6))

            let temp = []

            for (let i = 1; i <= Math.ceil(otherAppArr.length / 6); i++) {
              temp.push(i)
            }
            //console.log(otherAppArr.length)
            //console.log(temp)
            setTotalPageArray(temp)
            settotalSlicePageArray(temp.slice(0, 3))

            setSelectedArray(otherAppArr.slice(0, 6))
          }

          break

        default:
          break
      }
    }
  }, [props.match.params])

  useEffect(() => {
    document.title = "Acey - Products"
    if (props.match.params.id === undefined) {
      switch (props.match.params.name) {
        case "automotive":
          if (width < 1024) {
            let pagestart = selectedPage * 4
            let temp = [...AutomotiveArr]
            setSelectedArray(temp.slice(pagestart, pagestart + 4))
          } else {
            let pagestart = selectedPage * 6
            let temp = [...AutomotiveArr]
            setSelectedArray(temp.slice(pagestart, pagestart + 6))
          }

          break

        case "nonautomotive":
          if (width < 1024) {
            let pagestart = selectedPage * 4
            let temp = [...nonAutomotiveArr]
            setSelectedArray(temp.slice(pagestart, pagestart + 4))
          } else {
            let pagestart = selectedPage * 6
            let temp = [...nonAutomotiveArr]
            setSelectedArray(temp.slice(pagestart, pagestart + 6))
          }
          break

        default:
          break
      }
    } else {
      switch (props.match.params.id) {
        case "twowheelers":
          if (width < 1024) {
            let pagestart = selectedPage * 4
            let temp = [...twowheelersArr]
            setSelectedArray(temp.slice(pagestart, pagestart + 4))
          } else {
            let pagestart = selectedPage * 6
            let temp = [...twowheelersArr]
            setSelectedArray(temp.slice(pagestart, pagestart + 6))
          }

          break

        case "threeWheelers":
          if (width < 1024) {
            let pagestart = selectedPage * 4
            setSelectedArray(
              ...[threeWheelerArr.slice(pagestart, pagestart + 4)]
            )
          } else {
            let pagestart = selectedPage * 6
            setSelectedArray(
              ...[threeWheelerArr.slice(pagestart, pagestart + 6)]
            )
          }

          break

        case "passengerVehicles":
          if (width < 1024) {
            let pagestart = selectedPage * 4

            setSelectedArray(passengevehicleArr.slice(pagestart, pagestart + 4))
          } else {
            let pagestart = selectedPage * 6

            setSelectedArray(passengevehicleArr.slice(pagestart, pagestart + 6))
          }

          break

        case "lcvmcvhcv":
          if (width < 1024) {
            let pagestart = selectedPage * 4
            setSelectedArray(lcvmcvArr.slice(pagestart, pagestart + 4))
          } else {
            let pagestart = selectedPage * 6
            setSelectedArray(lcvmcvArr.slice(pagestart, pagestart + 6))
          }

          break

        case "bicycles":
          if (width < 1024) {
            let pagestart = selectedPage * 4
            setSelectedArray(bicycleArr.slice(pagestart, pagestart + 4))
          } else {
            let pagestart = selectedPage * 6
            setSelectedArray(bicycleArr.slice(pagestart, pagestart + 6))
          }

          break

        case "tractors":
          if (width < 1024) {
            let pagestart = selectedPage * 4
            setSelectedArray(tractorsArr.slice(pagestart, pagestart + 4))
          } else {
            let pagestart = selectedPage * 6
            setSelectedArray(tractorsArr.slice(pagestart, pagestart + 6))
          }

          break

        case "railways":
          if (width < 1024) {
            let pagestart = selectedPage * 4
            setSelectedArray(railwaysArr.slice(pagestart, pagestart + 4))
          } else {
            let pagestart = selectedPage * 6
            setSelectedArray(railwaysArr.slice(pagestart, pagestart + 6))
          }

          break

        case "earthmovingequipment":
          if (width < 1024) {
            let pagestart = selectedPage * 4
            setSelectedArray(earthmovingArr.slice(pagestart, pagestart + 4))
          } else {
            let pagestart = selectedPage * 6
            setSelectedArray(earthmovingArr.slice(pagestart, pagestart + 6))
          }

          break

        case "marineapplication":
          if (width < 1024) {
            let pagestart = selectedPage * 4
            setSelectedArray(marineAppArr.slice(pagestart, pagestart + 4))
          } else {
            let pagestart = selectedPage * 6
            setSelectedArray(marineAppArr.slice(pagestart, pagestart + 6))
          }

          break

        case "otherApplication":
          if (width < 1024) {
            let pagestart = selectedPage * 4
            setSelectedArray(otherAppArr.slice(pagestart, pagestart + 4))
          } else {
            let pagestart = selectedPage * 6
            setSelectedArray(otherAppArr.slice(pagestart, pagestart + 6))
          }

          break

        default:
          break
      }
    }

    leftArrowClickOpacityHandle()
    rightArrowClickOpacityHandle()
  }, [selectedPage])

  const leftArrowClickOpacityHandle = () => {
    if (selectedPage === 0) {
      try {
        document.getElementById("paginationLeftarrow").style.opacity = ".5"
        document.getElementById("paginationLeftarrow").style.cursor = "initial"
      } catch (error) {}
    } else {
      try {
        document.getElementById("paginationLeftarrow").style.opacity = "1"
        document.getElementById("paginationLeftarrow").style.cursor = "pointer"
      } catch (error) {}
    }
  }

  const rightArrowClickOpacityHandle = () => {
    if (totalPage === selectedPage + 1) {
      try {
        document.getElementById("paginationrightarrow").style.opacity = ".5"
        document.getElementById("paginationrightarrow").style.cursor = "initial"
      } catch (error) {}
    } else {
      try {
        document.getElementById("paginationrightarrow").style.opacity = "1"
        document.getElementById("paginationrightarrow").style.cursor = "pointer"
      } catch (error) {}
    }
  }

  const leftArrowClick = () => {
    if (!(selectedPage - 1 < 0)) {
      //   //console.log("total page", totalPageArray.length)
      //   //console.log("current page", selectedPage)
      //   //console.log("total page-2", totalPageArray.length - 2)
      //   //console.log("==>", totalPageArray.length - 2 >= selectedPage)

      if (totalPageArray.length - 2 >= selectedPage) {
        settotalSlicePageArray(
          totalPageArray.slice(selectedPage - 1, selectedPage + 2)
        )
      }

      setSelectedPage(selectedPage - 1)
      scrollToDiv()
    }
  }
  const rightArrowClick = () => {
    if (selectedPage + 1 !== totalPageArray.length) {
      if ((selectedPage + 1) % 2 === 0 || selectedPage >= 2) {
        settotalSlicePageArray(
          totalPageArray.slice(selectedPage, selectedPage + 3)
        )
      }

      setSelectedPage(selectedPage + 1)

      scrollToDiv()
    }
    // totalPageArray.length === (selectedPage+1) ?null :
  }

  const scrollToDiv = () => {
    const id = "list"
    const yOffset = -170
    const element = document.getElementById(id)
    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset

    window.scrollTo({ top: y, behavior: "smooth" })
  }

  const paginationNoClick = (i) => {
    setSelectedPage(i - 1)
    scrollToDiv()
  }

  const popupBtnClick = () => {
    setPopupShow(true)
  }

  const closePopup = () => {
    setPopupShow(false)
  }

  const cancelBtnClick = () => setPopupShow(false)

  //console.log("=======>silelele", selectedArray)
  const threewheelerList = selectedArray.map((item, i) => (
    <AutomotiveCardItem
      img={item.img}
      key={shortid.generate()}
      title={item.title}
    />
  ))

  if (popupShow === true) {
    return (
      <div key="1" className="poupcontainer wow fadeIn">
        <div className="cancelContainer">
          <div onClick={cancelBtnClick}>
            <img src={cancel} className="fluid" />{" "}
          </div>
        </div>
        <div className="title">CATEGORIES</div>

        <div className="d-f f-c listContainer">
          {isautomotive ? (
            <>
              <AutomotiveListItem
                onClick={closePopup}
                link="/product/automotive/twowheelers"
                selected={titles === "2 Wheelers"}
                title="2 WHEELERS"
              />
              <AutomotiveListItem
                onClick={closePopup}
                link="/product/automotive/threeWheelers"
                selected={titles === "3 wheelers"}
                title="3 WHEELERS"
              />
              <AutomotiveListItem
                onClick={closePopup}
                link="/product/automotive/passengerVehicles"
                selected={titles === "PASSENGER VEHICLES"}
                title="PASSENGER VEHICLES"
              />
              <AutomotiveListItem
                onClick={closePopup}
                link="/product/automotive/lcvmcvhcv"
                selected={titles === "LCV/MCV/HCV"}
                title="LCV/MCV/HCV"
              />
              <AutomotiveListItem
                onClick={closePopup}
                link="/product/automotive/bicycles"
                selected={titles === "BICYCLES"}
                title="BICYCLES"
              />
            </>
          ) : (
            <>
              <AutomotiveListItem
                onClick={closePopup}
                link="/product/nonautomotive/tractors"
                selected={titles === "Tractors"}
                title="Tractors"
              />
              <AutomotiveListItem
                onClick={closePopup}
                link="/product/nonautomotive/railways"
                selected={titles === "Railways"}
                title="Railways"
              />
              <AutomotiveListItem
                onClick={closePopup}
                link="/product/nonautomotive/earthmovingequipment"
                selected={titles === "Earth Moving Equipment"}
                title="Earth Moving Equipment"
              />
              <AutomotiveListItem
                onClick={closePopup}
                link="/product/nonautomotive/marineapplication"
                selected={titles === "Marine Applications"}
                title="Marine Applications"
              />
              <AutomotiveListItem
                onClick={closePopup}
                link="/product/nonautomotive/otherApplication"
                selected={titles === "Other Applications"}
                title="Other Applications"
              />
            </>
          )}
        </div>
      </div>
    )
  }

  return (
    <>
      <BlueHeader title={bannerTitle} img={deskheaderbg} />
      <section className="auto_sec1 wow fadeIn">
        {props.match.params.id === undefined ? (
          <>
            <div className="autoDesc">
              For the past four decades, Acey has been successfully
              manufacturing and delivering value-added products that elevate
              performance. Our reliable technology, infrastructure and
              well-defined processes make us a trusted partner for
              quality-conscious organisations. We have developed an expertise
              for manufacturing customized control cables like the following:
            </div>
            {width > 1280 ? (
              <div className="dotContainer1">
                <div className="d-f f-c">
                  <div
                    className="wow fadeIn d-f f-c"
                    data-wow-duration="2s"
                    data-wow-delay=".5s"
                  >
                    <img src={dots} className="fluid dots" />
                  </div>
                </div>
              </div>
            ) : null}
          </>
        ) : (
          <div className="gap"></div>
        )}
        {width < 1024 ? (
          <div className="btnContainer">
            <div className="whiteBtnAuto" onClick={popupBtnClick}>
              SHOW MORE CATEGORIES
            </div>
          </div>
        ) : null}
      </section>

      <section className="auto_sec2" id="list">
        <Row className="auto_home_row">
          <Col lg={6} md={0}>
            {width >= 1024 ? (
              <div className="d-f f-c listContainer">
                {isautomotive ? (
                  <>
                    <AutomotiveListItem
                      link="/product/automotive/twowheelers"
                      selected={titles === "2 Wheelers"}
                      title="2 WHEELERS"
                    />
                    <AutomotiveListItem
                      link="/product/automotive/threeWheelers"
                      selected={titles === "3 wheelers"}
                      title="3 WHEELERS"
                    />
                    <AutomotiveListItem
                      link="/product/automotive/passengerVehicles"
                      selected={titles === "PASSENGER VEHICLES"}
                      title="PASSENGER VEHICLES"
                    />
                    <AutomotiveListItem
                      link="/product/automotive/lcvmcvhcv"
                      selected={titles === "LCV/MCV/HCV"}
                      title="LCV/MCV/HCV"
                    />
                    <AutomotiveListItem
                      link="/product/automotive/bicycles"
                      selected={titles === "BICYCLES"}
                      title="BICYCLES"
                    />
                  </>
                ) : (
                  <>
                    <AutomotiveListItem
                      link="/product/nonautomotive/tractors"
                      selected={titles === "Tractors"}
                      title="Tractors"
                    />
                    <AutomotiveListItem
                      link="/product/nonautomotive/railways"
                      selected={titles === "Railways"}
                      title="Railways"
                    />
                    <AutomotiveListItem
                      link="/product/nonautomotive/earthmovingequipment"
                      selected={titles === "Earth Moving Equipment"}
                      title="Earth Moving Equipment"
                    />
                    <AutomotiveListItem
                      link="/product/nonautomotive/marineapplication"
                      selected={titles === "Marine Applications"}
                      title="Marine Applications"
                    />
                    <AutomotiveListItem
                      link="/product/nonautomotive/otherApplication"
                      selected={titles === "Other Applications"}
                      title="Other Applications"
                    />
                  </>
                )}
              </div>
            ) : null}
          </Col>
          <Col lg={18} md={24}>
            <Row className="productListRow">{threewheelerList}</Row>
            {totalPageArray.length === 1 ? null : (
              <div className="d-f j-c a-c productListPaginationContainer">
                <div>
                  <img
                    className="c-p fluid"
                    id="paginationLeftarrow"
                    src={leftArrow}
                    onClick={leftArrowClick}
                  />
                </div>
                <PaginationComponent
                  paginationNoClick={(item) => paginationNoClick(item)}
                  totalPageArray={totalSlicePageArray}
                  selectedPage={selectedPage}
                />

                <div>
                  <img
                    className="c-p fluid"
                    id="paginationrightarrow"
                    src={rightArrow}
                    onClick={rightArrowClick}
                  />
                </div>
              </div>
            )}
          </Col>
        </Row>
        {width > 1280 ? (
          <div className="dotContainer2">
            <div className="d-f f-c">
              <div
                className="wow fadeIn d-f f-c"
                data-wow-duration="2s"
                data-wow-delay=".5s"
              >
                <img src={dots} className="fluid dots" />
              </div>
            </div>
          </div>
        ) : null}
      </section>
    </>
  )
}

export default Automotive
