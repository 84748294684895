import React, {useState, useEffect} from 'react';

import nextIcon from "../../images/csr/next.png"
import prevIcon from "../../images/csr/previous.png"

import './style.less'

const CarouselComponent =({imageArr}) =>{

  const [selected, setSelected] =useState(0);

  const onNextClick=()=>{
    if(selected===imageArr.length-1){
      setSelected(0)
    }else{
      setSelected(selected+1)
    }
  }

  const onPrevClick=()=>{
    if(selected===0){
      setSelected(imageArr.length-1)
    }else{
      setSelected(selected-1)
    }
  }

  useEffect(()=>{
    setTimeout(()=>{
      onNextClick();
    },3000) // auto change the slide after 1500ms
  })

    return(
        <>
          <div className='carousel-wrapper'>
          <div className='btn-prev' onClick={onPrevClick}>
            <img
              className="btn-icon"
              src={prevIcon}
            />
          </div>
          <div className='btn-next' onClick={onNextClick}>
            <img
              className="btn-icon"
              src={nextIcon}
            />
          </div>
            <div>
              { 
                  <img
                    className="fluid"
                    src={imageArr[selected]}
                    
                  />
              }
            </div>
            <div className='indicator-wrapper'>
              {imageArr.map((dot,i)=>{
                return(
                  <div 
                    className={`${selected===i?'indicator-selected':'indicator'}`}
                    onClick={()=>setSelected(i)}
                    ></div>
                );
              })}
            </div>
          </div>
        </>
    );
}

export default CarouselComponent;