import React from 'react'
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect,
} from 'react-router-dom'
import { createBrowserHistory } from 'history'

import Homepage from './container/Homepage/HomePage'
import './App.css'

import Footer from './component/Footer/Footer'
import Header from './component/Header/Header'
import OurValues from './container/OurValues/OurValues'
import Ourstory from './container/Ourstory/Ourstory'
import ContactUs from './container/ContactUs/ContactUs'
import Clients from './container/Clients/Clients'
import Manufacturing from './container/Manufacturing/Manufacturing'
import DevelopmentQuality from './container/DevelopentQuality/DevelopmentQuality'
import Csr from './container/Csr/Csr'
import Automotive from './container/Automotive/Automotive'
// import Login from './container/Login/Login'
import DefaultLayout from './DefaultLayout'
// import Dashboard from './container/Dashboard/Dashboard'
import PageNotFound from './container/PageNotFound/PageNotFound'

import Login from '../src/Admin/container/Login/Login'
import Dashboard from '../src/Admin/container/Dashboard/Dashboard'

const history = createBrowserHistory()

const PrivateRoute = ({ component: Component, ...rest }) => {
	const isAuth = localStorage.getItem('isLoggedIn')
	return (
		// Show the component only when the user is logged in
		// Otherwise, redirect the user to /signin page
		<Route
			{...rest}
			render={(props) =>
				isAuth ? <Component {...props} /> : <Redirect to='/admin' />
			}
		/>
	)
}
const App = (props) => {
	return (
		<>
			<Router history={history}>
				<Switch>
					<Route exact path='/404' component={PageNotFound} />
					<Route exact path='/admin' component={Login} />
					<PrivateRoute path='/admin/dashboard/' component={Dashboard} />
					<Route path='/' component={DefaultLayout} />
				</Switch>
			</Router>
		</>
	)
}

export default App
