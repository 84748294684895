import React,{useState,useEffect} from 'react'

import './style.less'
import { Row,Col } from 'antd'

const AutomotiveCardItem = props => {

  
    return (
        <Col lg={8} md={12} className="productListCol">
                <div className="cardContainer">
                <img src={props.img} className="fluid"/>
                <div className="cardTitle">{props.title}</div>
            </div>
        </Col>
                  
    );
}

export default AutomotiveCardItem;