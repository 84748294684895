import React, { useState, useEffect } from "react"
import BlueHeader from "../../component/BlueHeader/BlueHeader"
import WOW from "wowjs"
import dots from "../../images/homepage/dots.png"

import deskheaderbg from "../../images/headerbg.png"
import vision from "../../images/ourvalues/vision.png"
import mission from "../../images/ourvalues/mission.png"
import mbvision from "../../images/ourvalues/mbvision.png"
import mbmission from "../../images/ourvalues/mbmission.png"

import mbvisionsvg from "../../images/ourvalues/mbvisionsvg.svg"
import mbmissionsvg from "../../images/ourvalues/mbmissionsvg.svg"

import first from "../../images/ourvalues/first.png"
import second from "../../images/ourvalues/second.png"
import third from "../../images/ourvalues/third.png"
import fourth from "../../images/ourvalues/fourth.png"
import fifth from "../../images/ourvalues/fifth.png"

import leftarrow from "../../images/ourvalues/leftarrow.png"
import rightarrow from "../../images/ourvalues/rightarrow.png"
import circlemiddlelogo from "../../images/ourvalues/circlemiddlelogo.png"
import downarrow from "../../images/ourvalues/downarrow.png"

import Polygon from "../../images/ourvalues/Polygon.png"

import "./style.less"
import Swiper, { Navigation, Pagination, Controller, Autoplay } from "swiper"
import "swiper/swiper-bundle.css"

import { Row, Col } from "antd"
import CardOurValues from "../../component/CardOurValues/CardOurValues"
import useWindowDimensions from "../../useWindowDimensions"

Swiper.use([Navigation, Pagination, Controller, Autoplay])
const OurValues = (props) => {
  const { height, width } = useWindowDimensions()
  const [rotation, setrotation] = useState(0)
  const [ItemListId, setItemListId] = useState(0)
  var location_name_swiper
  var location_img_swiper

  var itemsId, htmlCollection, sectionDeg, radianSectionDeg

  useEffect(() => {
    //alert("hi")
    window.scrollTo(0, 0)
    document.title = "Acey - Our Values"

    new WOW.WOW({
      live: false,
    }).init()

    location_name_swiper = new Swiper(".ourValues-swiper", {
      slidesPerView: 1,
      spaceBetween: 0,
      loop: true,
      simulateTouch: false,
      slideToClickedSlide: false,
      observer: true,
      observeParents: true,

      navigation: {
        nextEl: ".pagination .rightarrow",
        prevEl: ".pagination .leftarrow",
      },
      // observer: true,
      // observeParents: true,
    })
    location_name_swiper.on("slideNextTransitionStart", function () {
      turnRight()
    })
    location_name_swiper.on("slidePrevTransitionStart", function () {
      turnLeft()
    })

    //console.log(location_name_swiper)

    //we're moving the elements by changing their position in the array

    htmlCollection = document.getElementsByClassName("item")
    //getting elements by class name into an HTMLCollection

    itemsId = Array.from(htmlCollection)
    //turning the HTMLcollection into an array for easier manipulation of the elements

    sectionDeg = 360 / itemsId.length
    //sectioning the (imaginary) circle into a number of section equalling the number of items
    //it can be used on more elements

    radianSectionDeg = (sectionDeg * Math.PI * 2) / 360
    //transforming from degrees into radians

    var radiusLength
    if (width <= 1920) {
      radiusLength = 308.5
    }
    if (width <= 1680) {
      radiusLength = 250
    }
    if (width <= 1520) {
      radiusLength = 240
    }
    if (width <= 1440) {
      radiusLength = 210
    }
    if (width <= 1280) {
      radiusLength = 190
    }
    if (width <= 834) {
      radiusLength = 200
    }
    if (width <= 414) {
      radiusLength = 130
    }
    if (width <= 375) {
      radiusLength = 130
    }
    //the distance between the center of the circle to the element
    //edit this number to increase/decrease that distance

    for (var i = 0; i < itemsId.length; i++) {
      itemsId[i].style.top =
        radiusLength * Math.sin(radianSectionDeg * i - 1.5708) - 50 + "px"
      itemsId[i].style.left =
        radiusLength * Math.cos(radianSectionDeg * i - 1.5708) - 50 + "px"
    }

    //setting the top and left positions of each elemenent based on the following formula:
    //(x, y) = (r * cos(θ), r * sin(θ)) like this:
    //x = (r * cos(θ) => left
    //y = r * sin(θ) => top
    //1.5708 is a radian used put the first element on top - basically 90deg
  }, [])

  const itemIdData = () => {
    htmlCollection = document.getElementsByClassName("item")
    //getting elements by class name into an HTMLCollection

    itemsId = Array.from(htmlCollection)
    //turning the HTMLcollection into an array for easier manipulation of the elements

    sectionDeg = 360 / itemsId.length
    //sectioning the (imaginary) circle into a number of section equalling the number of items
    //it can be used on more elements

    radianSectionDeg = (sectionDeg * Math.PI * 2) / 360
    //transforming from degrees into radians

    var radiusLength
    if (width <= 1920) {
      radiusLength = 308.5
    }
    if (width <= 1680) {
      radiusLength = 250
    }
    if (width <= 1520) {
      radiusLength = 240
    }
    if (width <= 1440) {
      radiusLength = 210
    }
    if (width <= 1280) {
      radiusLength = 190
    }
    if (width <= 834) {
      radiusLength = 200
    }
    if (width <= 414) {
      radiusLength = 130
    }
    if (width <= 375) {
      radiusLength = 130
    }
    //the distance between the center of the circle to the element
    //edit this number to increase/decrease that distance

    for (var i = 0; i < itemsId.length; i++) {
      itemsId[i].style.top =
        radiusLength * Math.sin(radianSectionDeg * i - 1.5708) - 50 + "px"
      itemsId[i].style.left =
        radiusLength * Math.cos(radianSectionDeg * i - 1.5708) - 50 + "px"
    }
  }

  const makeATurn = () => {
    var radiusLength = 308.5
    if (width <= 1680) {
      radiusLength = 250
    }
    if (width <= 1520) {
      radiusLength = 240
    }
    if (width <= 1440) {
      radiusLength = 220
    }
    if (width <= 1280) {
      radiusLength = 190
    }
    if (width <= 834) {
      radiusLength = 200
    }
    if (width <= 414) {
      radiusLength = 130
    }
    if (width <= 375) {
      radiusLength = 130
    }
    for (var i = 0; i < itemsId.length; i++) {
      itemsId[i].style.top =
        radiusLength * Math.sin(radianSectionDeg * i - 1.5708) - 50 + "px"
      itemsId[i].style.left =
        radiusLength * Math.cos(radianSectionDeg * i - 1.5708) - 50 + "px"
    }
  }
  //function used to set the new position of the elements

  const turnLeft = async () => {
    //console.log("ii",itemsId)
    // alert(itemsId)
    if (itemsId === undefined) {
      await itemIdData()
    }
    // rotation = rotation + radianSectionDeg;
    // document.getElementById('center').style.transform = 'rotate('+ rotation +'rad)';
    //   for(var i=0; i<itemsId.length; i++){
    //     itemsId[i].style.transform = 'rotate('+ -rotation +'rad)';
    //     itemsId[i].classList.add('active_item');
    //     // itemsId[i-1].classList.remove('active_item');
    //   }

    // location_name_swiper.slidePrev();

    var holder = itemsId.shift()
    itemsId.push(holder)
    makeATurn()
  }
  const turnRight = async () => {
    if (itemsId === undefined) {
      await itemIdData()
    }
    // rotation = rotation - radianSectionDeg;
    // document.getElementById('center').style.transform = 'rotate('+rotation+'rad)';
    //    // $('.item').removeClass('active_item');
    //   for(var i=0; i<itemsId.length; i++){
    //     itemsId[i].style.transform = 'rotate('+ -rotation +'rad)';
    //     itemsId[i].classList.add('active_item');
    //     // itemsId[i-1].classList.remove('active_item');
    //   }

    // location_name_swiper.slideNext();
    var holder = itemsId.pop()
    itemsId.unshift(holder)
    makeATurn()
  }

  return (
    <>
      <BlueHeader title="our values" img={deskheaderbg} />
      <section className="ourvalue_sec1">
        {width > 1280 ? (
          <div className="dotContainer1">
            <div className="d-f f-c">
              <div
                className="wow fadeIn d-f f-c"
                data-wow-duration="2s"
                data-wow-delay=".5s"
              >
                <img src={dots} className="fluid dots" />
              </div>
            </div>
          </div>
        ) : null}
        <div className="d-f jc-sb">
          <Row>
            <Col lg={12} md={24} className="wow fadeInLeft">
              <CardOurValues
                img={width >= 1024 ? vision : mbvisionsvg}
                title="vision"
                desc="We aspire to be the most preferred brand in the cable industry and achieve a sustainable, competitive share globally whilst implementing socially responsible practices."
              />
            </Col>
            <Col lg={12} md={24} className="col2 wow fadeInRight">
              <CardOurValues
                img={width >= 1024 ? mission : mbmissionsvg}
                title="Mission"
                desc="To drive excellence through our products and services by constantly upgrading technology and processes, whereby building long term relationships with all our stakeholders."
              />
            </Col>
          </Row>
        </div>
      </section>
      <section className="ourvalue_sec2 wow fadeInUp">
        <Row className="a-c">
          <Col lg={10} md={24} className="first_col">
            {width >= 1024 ? (
              <>
                {" "}
                <div className="title">core values</div>
                <div className="desc">
                  While we are evolving continuously to face new challenges, we
                  adhere and abide by the core values that define our brand:
                </div>
                <div className="ourvalueSwiperContainer d-f">
                  <div className="ourValues-swiper swiper-container">
                    <div className="swiper-wrapper">
                      <div className="swiper-slide">
                        <div className="d-f jc-sb">
                          <div className="count">01</div>
                          <div className="d-f f-c">
                            <div className="swiper_title">
                              Customer First Approach
                            </div>
                            <div className="swiper_desc">
                              Our age-old belief is built on the mantra of CDQ –
                              Cost which is optimal, Delivery that’s timely, and
                              Quality that’s excellent.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="swiper-slide">
                        <div className="d-f jc-sb">
                          <div className="count">02</div>
                          <div className="d-f f-c">
                            <div className="swiper_title">Transparency</div>
                            <div className="swiper_desc">
                              It passes through everything we do – from our
                              processes and people, to our products and our
                              commitments to the stakeholders.{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="swiper-slide">
                        <div className="d-f jc-sb">
                          <div className="count">03</div>
                          <div className="d-f f-c">
                            <div className="swiper_title">
                              Long-term Relationships
                            </div>
                            <div className="swiper_desc">
                              We strive towards forging long-term relationships
                              that are mutually beneficial. For us, our clients
                              and suppliers are not revenue streams but also
                              business partners.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="swiper-slide">
                        <div className="d-f jc-sb">
                          <div className="count">04</div>
                          <div className="d-f f-c">
                            <div className="swiper_title">
                              Sustainable Growth
                            </div>
                            <div className="swiper_desc">
                              We believe in taking calculated risks so that our
                              growth model remains anchored in the long run.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="swiper-slide">
                        <div className="d-f jc-sb">
                          <div className="count">05</div>
                          <div className="d-f f-c">
                            <div className="swiper_title">
                              Deep-Rooted Morals
                            </div>
                            <div className="swiper_desc">
                              People define a company’s ethos and we consider it
                              our moral responsibility to help our employees
                              achieve their individual goals while being aligned
                              with our vision.{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <img src={Polygon} className="polygon " />
                  </div>
                </div>
                <div className="pagination d-f">
                  <div onClick={turnLeft} className="leftarrow c-p us-none">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="45"
                      height="45"
                      viewBox="0 0 63 63"
                    >
                      <g
                        id="Group_1088"
                        data-name="Group 1088"
                        transform="translate(-247 -1435)"
                      >
                        <circle
                          id="Ellipse_138"
                          data-name="Ellipse 138"
                          cx="31.5"
                          cy="31.5"
                          r="31.5"
                          transform="translate(247 1435)"
                          fill="#f3f3f3"
                        />
                        <g
                          id="Icon_feather-arrow-right"
                          data-name="Icon feather-arrow-right"
                          transform="translate(270 1450)"
                        >
                          <path
                            id="Path_2143"
                            data-name="Path 2143"
                            d="M34.809,7.5,18,24.309,34.809,41.118"
                            transform="translate(-18 -7.5)"
                            fill="none"
                            stroke="#000"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="3"
                          />
                        </g>
                      </g>
                    </svg>
                  </div>

                  <div onClick={turnRight} className="rightarrow c-p us-none">
                    <svg
                      id="Group_1089"
                      data-name="Group 1088"
                      xmlns="http://www.w3.org/2000/svg"
                      width="45"
                      height="45"
                      viewBox="0 0 63 63"
                    >
                      <circle
                        id="Ellipse_139"
                        data-name="Ellipse 139"
                        cx="31.5"
                        cy="31.5"
                        r="31.5"
                        fill="#f3f3f3"
                      />
                      <g
                        id="Icon_feather-arrow-right"
                        data-name="Icon feather-arrow-right"
                        transform="translate(23.191 15)"
                      >
                        <path
                          id="Path_2144"
                          data-name="Path 2143"
                          d="M18,7.5,34.809,24.309,18,41.118"
                          transform="translate(-18 -7.5)"
                          fill="none"
                          stroke="#000"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="3"
                        />
                      </g>
                    </svg>
                  </div>
                </div>
              </>
            ) : null}
            {width < 1024 ? (
              <>
                <div className="title"> core values</div>
                <div className="desc">
                  While we are evolving continuously to face new challenges, we
                  adhere and abide by the core values that define our brand:
                </div>
                <div>
                  <div className="ourValues-swiper swiper-container">
                    <div className="swiper-wrapper">
                      <div className="swiper-slide">
                        <div className="mbswiper_title">
                          01 Customer First Approach
                        </div>
                        <div className="mbswiper_desc">
                          Our age-old belief is built on the mantra of CDQ –
                          Cost which is Optimal, Delivery that’s Timely, and
                          Quality that’s Excellent.<br></br>
                          <br></br>
                        </div>
                      </div>
                      <div className="swiper-slide">
                        <div className="mbswiper_title">02 Transparency</div>
                        <div className="mbswiper_desc">
                          It passes through everything we do – from our
                          processes and people, to our products and our
                          commitments to the stakeholders.<br></br>
                        </div>
                      </div>
                      <div className="swiper-slide">
                        <div className="mbswiper_title">
                          03 Long-term Relationships
                        </div>
                        <div className="mbswiper_desc">
                          We strive towards forging long-term relationships that
                          are mutually beneficial. For us, our clients and
                          suppliers are not revenue streams but also business
                          partners.
                        </div>
                      </div>
                      <div className="swiper-slide">
                        <div className="mbswiper_title">
                          04 Sustainable Growth
                        </div>
                        <div className="mbswiper_desc">
                          Our age-old belief is built on the mantra of CDQ –
                          Cost which is optimal, Delivery that’s timely, and
                          Quality that’s excellent.<br></br>
                          <br></br>
                        </div>
                      </div>
                      <div className="swiper-slide">
                        <div className="mbswiper_title">
                          05 Deep-Rooted Morals
                        </div>
                        <div className="mbswiper_desc">
                          People define a company’s ethos and we consider it our
                          moral responsibility to help our employees achieve
                          their individual goals while being aligned with our
                          vision.{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="downarrowContainer">
                    <div>
                      {" "}
                      <img src={downarrow} className="fluid" />
                    </div>{" "}
                  </div>
                </div>
              </>
            ) : null}
          </Col>
          <Col
            lg={14}
            md={24}
            style={{ textAlign: "start" }}
            className="col2Circle"
          >
            <div className="circularContainer">
              <div className="circular_location_slider">
                <div id="center" className="wrapper">
                  <div className="item active_item">
                    <div className="location_icon_box">
                      <img src={first} className="circleimg1 fluid" />
                    </div>
                  </div>
                  <div className="item">
                    <div className="location_icon_box">
                      <img src={second} className="circleimg1 fluid" />
                    </div>
                  </div>
                  <div className="item">
                    <div className="location_icon_box">
                      <img src={third} className="circleimg1 fluid" />
                    </div>
                  </div>
                  <div className="item">
                    <div className="location_icon_box">
                      <img src={fourth} className="circleimg1 fluid" />
                    </div>
                  </div>
                  <div className="item">
                    <div className="location_icon_box">
                      <img src={fifth} className="circleimg1 fluid" />
                    </div>
                  </div>
                </div>
                <div className="controls">
                  <img src={circlemiddlelogo} />
                </div>
                <div className="grey_circle"></div>
              </div>
              {width < 1024 ? (
                <div className="pagination ">
                  <img
                    onClick={turnLeft}
                    className="leftarrow c-p us-none"
                    src={leftarrow}
                  />
                  <img
                    onClick={turnRight}
                    className="rightarrow c-p us-none"
                    src={rightarrow}
                  />
                </div>
              ) : null}
            </div>
          </Col>
        </Row>
        {width > 1280 ? (
          <div className="dotContainer2">
            <div className="d-f f-c">
              <div
                className="wow fadeIn d-f f-c"
                data-wow-duration="2s"
                data-wow-delay=".5s"
              >
                <img src={dots} className="fluid dots" />
              </div>
            </div>
          </div>
        ) : null}
      </section>
    </>
  )
}

export default OurValues
