import React, { useState, useEffect } from 'react'
import SimpleImageSlider from "react-simple-image-slider";
import WOW from 'wowjs';
import Carousel from "../../component/Carousel/Carousel";

import { Row, Col } from 'antd'

import './style.less'




const CsrItem = props => {

    const title = props.title;
   console.log('title is'+title);
   // window.print('title is'+title);

    return (
        <>
            <Row className={props.reverse ? "reverse csrrow" : "csrrow"}>
                <Col lg={12} className="wow fadeInUp">
                <div className={props.reverse ? "ml-auto descContainer":"descContainer"}>
                    <div className="d-f  a-c imgContainer">

                            <div><img src={props.smImg} className="fluid smimg descImg" /> </div>
                            <div className="colTitle">{props.title}</div>
                        </div>
                        <div className="colDesc">
                            {props.desc}
                        </div>
                    </div>
                </Col>
                <Col lg={12} className="wow fadeInUp">
                {/* <img src={props.img} className="fluid"/> */}
              
      
                <Carousel imageArr={props.imageArr}/>
    
                
            </Col>
        </Row>


        </>
    );
}

export default CsrItem