import React from 'react'

import './style.css'


const Tab = props => {
    return    <div onClick={props.onClick} className={props.active? "selectedtab tab c-p" :"tab c-p"}>
                    <div className="title">{props.title}</div>
                </div>
}

export default Tab;