import React, { useState, useEffect } from "react"
import BlueHeader from "../../component/BlueHeader/BlueHeader"
import WOW from "wowjs"

import deskheaderbg from "../../images/headerbg.png"

import auto from "../../images/clients/auto.png"
import other from "../../images/clients/other.png"
import bike from "../../images/clients/bike.png"
import car from "../../images/clients/car.png"
import tempo from "../../images/clients/tempo.png"
import tractor from "../../images/clients/tractor.png"
import train from "../../images/clients/train.png"
import jcb from "../../images/clients/jcb.png"
import ships from "../../images/clients/ship.png"

import mbauto from "../../images/clients/mbauto.png"
import mbother from "../../images/clients/mbother.png"
import mbtwowheeler from "../../images/clients/mbtwowheeler.png"
import mbcar from "../../images/clients/mbcar.png"
import mbjcb from "../../images/clients/mbjcb.png"
import mbtempo from "../../images/clients/mbtempo.png"
import mbtractor from "../../images/clients/mbtractor.png"
import mbtrain from "../../images/clients/mbtrain.png"
import mbship from "../../images/clients/mbship.png"

import netherland from "../../images/clients/netherland.png"
import columbia from "../../images/clients/columbia.png"
import srilanka from "../../images/clients/srilanka.png"
import usa from "../../images/clients/usa.png"
import africa from "../../images/clients/africa.png"

import mbnetherland from "../../images/clients/mbnetherland.png"
import mbuk from "../../images/clients/mbuk.png"
import mbcolumbia from "../../images/clients/mbcolumbia.png"
import mbsrilanka from "../../images/clients/Sri Lanka.png"
import mbusa from "../../images/clients/mbusa.png"
import mbafrica from "../../images/clients/mbafrica.png"
import mbspain from "../../images/clients/Spain.png"
import mbcambodia from "../../images/clients/cambodia.png"
import mbegypt from "../../images/clients/Egypt.png"
import mbbangaladesh from "../../images/clients/Bangladesh.png"
import mbukk from "../../images/clients/uk_new.png"
import mbnepal from "../../images/clients/Nepal.png"

import dots from "../../images/homepage/dots.png"
import Tab from "../../component/Tab/Tab"

import InputText from "../../component/InputText/InputText"
import Button from "../../component/Button/Button"


import Amson from "../../images/clients/Amson.png"
import bajaj from "../../images/clients/bajaj.png"
import Escorts from "../../images/clients/Escorts.png"

import Fiat from "../../images/clients/fiat.png"
import Force from "../../images/clients/force.png"
import Himakshi from "../../images/clients/Himakshi.png"

import JBM from "../../images/clients/jbm.png"
import LNT from "../../images/clients/LNT.png"
import MahindraRise from "../../images/clients/MahindraRise.png"

import MahindraTruck from "../../images/clients/MahindraTruck.png"
//import MAHINDRA from "../../images/clients/MAHINDRA.png"

import RCJ from "../../images/clients/RCJ.png"
import SL from "../../images/clients/SL.png"
import speevo from "../../images/clients/speevo.png"

import TATA from "../../images/clients/TATA.png"

//import Marcopolo from '../../images/clients/Marcopolo.png'

import ActionConstructionEquipmentLtd from "../../images/clients/ActionConstructionEquipmentLtd.png"
import BrakesIndiaPvtLtd from "../../images/clients/BrakesIndiaPvtLtd.png"
import ContinentalEnginesPvtLtd from "../../images/clients/ContinentalEnginesPvtLtd.png"
import MahindraElectricMobilityLtd from "../../images/clients/MahindraElectricMobilityLtd.png"
import PavnaAutoEngineeringPvtLtd from "../../images/clients/PavnaAutoEngineeringPvtLtd.png"
import ReflexallenIndiaPvtLtd from "../../images/clients/ReflexallenIndiaPvtLtd.png"
import SpicerIndiaPvtLtd from "../../images/clients/SpicerIndiaPvt.Ltd.png"
import UcalfuelsystemsLtd from "../../images/clients/UcalfuelsystemsLtd.png"
import VossAutomotiveIndiaPvtLtd from "../../images/clients/VossAutomotiveIndiaPvtLtd.png"
import simpsonandco from "../../images/clients/simpsonandco.png"
import advik from "../../images/clients/advik.png"
import ors from "../../images/clients/ors.png"
import pure from "../../images/clients/pure.png"
import bga from "../../images/clients/bga.png"
import cooper from "../../images/clients/cooper.png"
import kawa from "../../images/clients/kawa.png"
import mahle from "../../images/clients/mahle.png"
//mb

import mbAmson from "../../images/clients/mbAmson.png"
import mbBajaj from "../../images/clients/mbBajaj.png"
import mbEscorts from "../../images/clients/mbEscorts.png"
import mbFiat from "../../images/clients/mbFiat.png"
import mbForce from "../../images/clients/mbForce.png"
import mbJBM from "../../images/clients/mbJBM.png"
import mbLNT from "../../images/clients/mbLNT.png"
//import mbMAHINDRA from "../../images/clients/mbMAHINDRA.png"
import mbMahindraRise from "../../images/clients/mbMahindraRise.png"
import mbMahindraTruck from "../../images/clients/mbMahindraTruck.png"
//import mbMarcopolo from "../../images/clients/mbMarcopolo.png"
import mbRCJ from "../../images/clients/mbRCJ.png"
import mbSL from "../../images/clients/mbSL.png"
import mbspeevo from "../../images/clients/mbspeevo.png"
import mbTATA from "../../images/clients/mbTATA.png"


import mbActionConstructionEquipmentLtd from "../../images/clients/mbActionConstructionEquipmentLtd.png"
import mbBrakesIndiaPvtLtd from "../../images/clients/mbBrakesIndiaPvtLtd.png"
import mbContinentalEnginesPvtLtd from "../../images/clients/mbContinentalEnginesPvtLtd.png"
import mbMahindraElectricMobilityLtd from "../../images/clients/mbMahindraElectricMobilityLtd.png"
import mbPavnaAutoEngineeringPvtLtd from "../../images/clients/mbPavnaAutoEngineeringPvtLtd.png"
import mbReflexallenIndiaPvtLtd from "../../images/clients/mbReflexallenIndiaPvtLtd.png"
import mbSpicerIndiaPvtLtd from "../../images/clients/mbSpicerIndiaPvt.Ltd.png"
import mbUcalfuelsystemsLtd from "../../images/clients/mbUcalfuelsystemsLtd.png"
import mbVossAutomotiveIndiaPvtLtd from "../../images/clients/mbVossAutomotiveIndiaPvtLtd.png"
import mbsimpsonandco from "../../images/clients/mbsimpsonandco.png"

import "./style.less"
import Swiper, { Navigation, Pagination, Controller, Breakpoints } from "swiper"
import "swiper/swiper-bundle.css"
import { Row, Col } from "antd"
import useWindowDimensions from "../../useWindowDimensions"
Swiper.use([Navigation, Pagination, Controller])

const Clients = (props) => {
  const { height, width } = useWindowDimensions()
  const [tab, setTab] = useState(0)

  useEffect(() => {
    window.scrollTo(0, 0)
    document.title = "Acey - Clients"

    new WOW.WOW({
      live: false,
    }).init()
  }, [])

  return (
    <>
      <BlueHeader title="CLIENTS" img={deskheaderbg} />
      <section className="clients_sec1 wow fadeIn">
        {width >= 1280 ? (
          <div className="dotContainer1">
            <div className="d-f f-c">
              <div
                className="wow fadeIn"
                data-wow-duration="2s"
                data-wow-delay=".5s"
              >
                <img src={dots} className="fluid dots" />
              </div>
            </div>
          </div>
        ) : null}

        <div className="sectitle">our valued business partners</div>
        <div className="secdesc">
          We have always worked towards building long term business
          relationships with our clients. From local to global arenas, Acey has
          partnered with some of the biggest & leading names in the industry,
          both automotive & non automotive. We also cater to the aftermarket,
          both domestic as well as overseas & are proud to be associated with
          some of the leading business houses.
        </div>

        <div className="d-f tabcontainer">
          <Tab
            onClick={() => setTab(0)}
            active={tab === 0 ? true : false}
            title="OEM'S"
          />
          <Tab
            onClick={() => setTab(1)}
            active={tab === 1 ? true : false}
            title="AFTERMARKET"
          />
          <Tab
            onClick={() => setTab(2)}
            active={tab === 2 ? true : false}
            title="EXPORT"
          />
        </div>
        {tab === 0 ? (
          <div className="oem">
            {width < 1024 ? (
              <div className="slider">
                <div className="slide-track">
                  <div className="slide slidemarginright">
                    <div className="d-f f-c">
                      <div>
                        <img src={mbBajaj} className="fluid" alt="" />
                      </div>
                      <div className="slidemargintop">
                        <img src={mbTATA} className="fluid" alt="" />
                      </div>
                    </div>
                  </div>

                  <div className="slide slidemarginright">
                    <div className="d-f f-c">
                      <div>
                        <img src={mbMahindraRise} className="fluid" alt="" />
                      </div>
                      <div className="slidemargintop">
                        <img src={mbForce} className="fluid" alt="" />
                      </div>
                    </div>
                  </div>

                  <div className="slide slidemarginright">
                    <div className="d-f f-c">
                      <div>
                        <img src={advik} className="fluid" alt="" />
                      </div>
                      <div className="slidemargintop">
                        <img src={ors} className="fluid" alt="" />
                      </div>
                    </div>
                  </div>

                  <div className="slide slidemarginright">
                    <div className="d-f f-c">
                      <div>
                        <img src={pure} className="fluid" alt="" />
                      </div>
                      <div className="slidemargintop">
                        <img src={bga} className="fluid" alt="" />
                      </div>
                    </div>
                  </div>

                  <div className="slide slidemarginright">
                    <div className="d-f f-c">
                      <div>
                        <img src={cooper} className="fluid" alt="" />
                      </div>
                      <div className="slidemargintop">
                        <img src={kawa} className="fluid" alt="" />
                      </div>
                    </div>
                  </div>

                  <div className="slide slidemarginright">
                    <div className="d-f f-c">
                      <div>
                        <img src={mahle} className="fluid" alt="" />
                      </div>
                      <div className="slidemargintop">
                        <img src={mbEscorts} className="fluid" alt="" />
                      </div>
                    </div>
                  </div>

                  <div className="slide slidemarginright">
                    <div className="d-f f-c">
                      <div>
                        <img src={mbFiat} className="fluid" alt="" />
                      </div>
                      <div className="slidemargintop">
                        <img src={mbMahindraTruck} className="fluid" alt="" />
                      </div>
                    </div>
                  </div>

                  <div className="slide slidemarginright">
                    <div className="d-f f-c">
                      <div>
                        <img
                          src={mbMahindraElectricMobilityLtd}
                          className="fluid"
                          alt=""
                        />
                      </div>
                      <div className="slidemargintop">
                        {" "}
                        <img src={mbEscorts} className="fluid" alt="" />
                      </div>
                    </div>
                  </div>

                  <div className="slide slidemarginright">
                    <div className="d-f f-c">
                      <div>
                        <img
                          src={mbActionConstructionEquipmentLtd}
                          className="fluid"
                          alt=""
                        />
                      </div>
                      <div className="slidemargintop">
                        <img
                          src={mbSpicerIndiaPvtLtd}
                          className="fluid"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>

                  <div className="slide slidemarginright">
                    <div className="d-f f-c">
                      <div>
                        <img
                          src={mbVossAutomotiveIndiaPvtLtd}
                          className="fluid"
                          alt=""
                        />
                      </div>
                      <div className="slidemargintop">
                        <img
                          src={mbContinentalEnginesPvtLtd}
                          className="fluid"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>

                  <div className="slide slidemarginright">
                    <div className="d-f f-c">
                      <div>
                        <img src={mbJBM} className="fluid" alt="" />
                      </div>
                      <div className="slidemargintop">
                        <img
                          src={mbBrakesIndiaPvtLtd}
                          className="fluid"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>

                  <div className="slide slidemarginright">
                    <div className="d-f f-c">
                      <div>
                        <img src={mbsimpsonandco} className="fluid" alt="" />
                      </div>
                      <div className="slidemargintop">
                        {" "}
                        <img src={mbLNT} className="fluid" alt="" />
                      </div>
                    </div>
                  </div>

                  <div className="slide slidemarginright">
                    <div className="d-f f-c">
                      <div>
                        <img
                          src={mbPavnaAutoEngineeringPvtLtd}
                          className="fluid"
                          alt=""
                        />
                      </div>
                      <div className="slidemargintop">
                        <img
                          src={mbReflexallenIndiaPvtLtd}
                          className="fluid"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>

                  <div className="slide slidemarginright">
                    <div className="d-f f-c">
                      <div>
                        <img
                          src={mbUcalfuelsystemsLtd}
                          className="fluid"
                          alt=""
                        />
                      </div>
                      <div className="slidemargintop">
                        <img src={mbAmson} className="fluid" alt="" />
                      </div>
                    </div>
                  </div>
                  {/* <div className="slide slidemarginright">
                    <div className="d-f f-c">
                      <div>
                        <img src={mbHimakshi} className="fluid" alt="" />
                      </div>
                      <div className="slidemargintop">
                        <img
                          src={mbBrakesIndiaPvtLtd}
                          className="fluid"
                          alt=""
                        />
                      </div>
                    </div>
                  </div> */}
                  <div className="slide slidemarginright">
                    <div className="d-f f-c">
                      <div>
                        <img src={mbRCJ} className="fluid" alt="" />
                      </div>
                      <div className="slidemargintop">
                        <img src={mbspeevo} className="fluid" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="slider">
                <div className="slide-track">
                  <div className="slide">
                    <div className="d-f f-c">
                      <div className="slidermargintopOemdesktop">
                        {" "}
                        <img src={bajaj} className="fluid logoDesk" alt="" />
                      </div>
                      <div className="slidermargintopOemdesktop">
                        {" "}
                        <img src={TATA} className="fluid logoDesk" alt="" />
                      </div>
                    </div>
                  </div>

                  <div className="slide">
                    <div className="d-f f-c">
                      <div className="slidermargintopOemdesktop">
                        {" "}
                        <img
                          src={MahindraRise}
                          className="fluid logoDesk"
                          alt=""
                        />
                      </div>
                      <div className="slidermargintopOemdesktop">
                        {" "}
                        <img src={Force} className="fluid logoDesk" alt="" />
                      </div>
                    </div>
                  </div>

                  <div className="slide">
                    <div className="d-f f-c">
                      <div className="slidermargintopOemdesktop">
                        {" "}
                        <img
                          src={advik}
                          className="fluid logoDesk"
                          alt=""
                        />
                      </div>
                      <div className="slidermargintopOemdesktop">
                        {" "}
                        <img src={ors} className="fluid logoDesk" alt="" />
                      </div>
                    </div>
                  </div>

                  <div className="slide">
                    <div className="d-f f-c">
                      <div className="slidermargintopOemdesktop">
                        {" "}
                        <img src={Fiat} className="fluid logoDesk" alt="" />
                      </div>
                      <div className="slidermargintopOemdesktop">
                        <img
                          src={MahindraTruck}
                          className="fluid logoDesk"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>

                  <div className="slide">
                    <div className="d-f f-c">
                      <div className="slidermargintopOemdesktop">
                        {" "}
                        <img
                          src={MahindraElectricMobilityLtd}
                          className="fluid logoDesk"
                          alt=""
                        />
                      </div>
                      <div className="slidermargintopOemdesktop">
                        {" "}
                        <img src={Escorts} className="fluid logoDesk" alt="" />
                      </div>
                    </div>
                  </div>

                  <div className="slide">
                    <div className="d-f f-c">
                      <div className="slidermargintopOemdesktop">
                        {" "}
                        <img
                          src={pure}
                          className="fluid logoDesk"
                          alt=""
                        />
                      </div>
                      <div className="slidermargintopOemdesktop">
                        {" "}
                        <img src={bga} className="fluid logoDesk" alt="" />
                      </div>
                    </div>
                  </div>

                  <div className="slide">
                    <div className="d-f f-c">
                      <div className="slidermargintopOemdesktop">
                        {" "}
                        <img
                          src={ActionConstructionEquipmentLtd}
                          className="fluid logoDesk"
                          alt=""
                        />
                      </div>
                      <div className="slidermargintopOemdesktop">
                        {" "}
                        <img
                          src={SpicerIndiaPvtLtd}
                          className="fluid logoDesk"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="slide">
                    <div className="d-f f-c">
                      <div className="slidermargintopOemdesktop">
                        {" "}
                        <img
                          src={cooper}
                          className="fluid logoDesk"
                          alt=""
                        />
                      </div>
                      <div className="slidermargintopOemdesktop">
                        {" "}
                        <img src={kawa} className="fluid logoDesk" alt="" />
                      </div>
                    </div>
                  </div>

                  <div className="slide">
                    <div className="d-f f-c">
                      <div className="slidermargintopOemdesktop">
                        {" "}
                        <img
                          src={VossAutomotiveIndiaPvtLtd}
                          className="fluid logoDesk"
                          alt=""
                        />
                      </div>
                      <div className="slidermargintopOemdesktop">
                        {" "}
                        <img
                          src={ContinentalEnginesPvtLtd}
                          className="fluid logoDesk"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="slide">
                    <div className="d-f f-c">
                      <div className="slidermargintopOemdesktop">
                        {" "}
                        <img src={JBM} className="fluid logoDesk" alt="" />
                      </div>
                      <div className="slidermargintopOemdesktop">
                        {" "}
                        <img
                          src={BrakesIndiaPvtLtd}
                          className="fluid logoDesk"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>

                  <div className="slide">
                    <div className="d-f f-c">
                      <div className="slidermargintopOemdesktop">
                        {" "}
                        <img
                          src={simpsonandco}
                          className="fluid logoDesk"
                          alt=""
                        />
                      </div>
                      <div className="slidermargintopOemdesktop">
                        {" "}
                        <img src={LNT} className="fluid logoDesk" alt="" />
                      </div>
                    </div>
                  </div>

                  <div className="slide">
                    <div className="d-f f-c">
                      <div className="slidermargintopOemdesktop">
                        {" "}
                        <img
                          src={PavnaAutoEngineeringPvtLtd}
                          className="fluid logoDesk"
                          alt=""
                        />
                      </div>
                      <div className="slidermargintopOemdesktop">
                        {" "}
                        <img
                          src={ReflexallenIndiaPvtLtd}
                          className="fluid logoDesk"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>

                  <div className="slide">
                    <div className="d-f f-c">
                      <div className="slidermargintopOemdesktop">
                        {" "}
                        <img
                          src={UcalfuelsystemsLtd}
                          className="fluid logoDesk"
                          alt=""
                        />
                      </div>
                      <div className="slidermargintopOemdesktop">
                        {" "}
                        <img src={Amson} className="fluid logoDesk" alt="" />
                      </div>
                    </div>
                  </div>

                  {/* <div className="slide">
                    <div className="d-f f-c">
                      <div className="slidermargintopOemdesktop">
                        {" "}
                        <img src={Himakshi} className="fluid logoDesk" alt="" />
                      </div>
                      <div className="slidermargintopOemdesktop">
                        <img
                          src={SpicerIndiaPvtLtd}
                          className="fluid logoDesk"
                          alt=""
                        />
                      </div>
                    </div>
                  </div> */}


                  <div className="slide">
                    <div className="d-f f-c">
                      <div className="slidermargintopOemdesktop">
                        {" "}
                        <img src={RCJ} className="fluid logoDesk" alt="" />
                      </div>
                      <div className="slidermargintopOemdesktop">
                        {" "}
                        <img src={speevo} className="fluid logoDesk" alt="" />
                      </div>
                    </div>
                  </div>

                  <div className="slide">
                    <div className="d-f f-c">
                      <div className="slidermargintopOemdesktop">
                        {" "}
                        <img src={bajaj} className="fluid logoDesk" alt="" />
                      </div>
                      <div className="slidermargintopOemdesktop">
                        {" "}
                        <img src={TATA} className="fluid logoDesk" alt="" />
                      </div>
                    </div>
                  </div>

                  <div className="slide">
                    <div className="d-f f-c">
                      <div className="slidermargintopOemdesktop">
                        {" "}
                        <img
                          src={MahindraRise}
                          className="fluid logoDesk"
                          alt=""
                        />
                      </div>
                      <div className="slidermargintopOemdesktop">
                        {" "}
                        <img src={Force} className="fluid logoDesk" alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="slide">
                    <div className="d-f f-c">
                      <div className="slidermargintopOemdesktop">
                        {" "}
                        <img src={Fiat} className="fluid logoDesk" alt="" />
                      </div>
                      <div className="slidermargintopOemdesktop">
                        <img
                          src={MahindraTruck}
                          className="fluid logoDesk"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>

                  <div className="slide">
                    <div className="d-f f-c">
                      <div className="slidermargintopOemdesktop">
                        {" "}
                        <img
                          src={MahindraElectricMobilityLtd}
                          className="fluid logoDesk"
                          alt=""
                        />
                      </div>
                      <div className="slidermargintopOemdesktop">
                        {" "}
                        <img src={Escorts} className="fluid logoDesk" alt="" />
                      </div>
                    </div>
                  </div>

                  <div className="slide">
                    <div className="d-f f-c">
                      <div className="slidermargintopOemdesktop">
                        {" "}
                        <img
                          src={ActionConstructionEquipmentLtd}
                          className="fluid logoDesk"
                          alt=""
                        />
                      </div>
                      <div className="slidermargintopOemdesktop">
                        {" "}
                        <img
                          src={SpicerIndiaPvtLtd}
                          className="fluid logoDesk"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>

                  <div className="slide">
                    <div className="d-f f-c">
                      <div className="slidermargintopOemdesktop">
                        {" "}
                        <img
                          src={VossAutomotiveIndiaPvtLtd}
                          className="fluid logoDesk"
                          alt=""
                        />
                      </div>
                      <div className="slidermargintopOemdesktop">
                        {" "}
                        <img
                          src={ContinentalEnginesPvtLtd}
                          className="fluid logoDesk"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="slide">
                    <div className="d-f f-c">
                      <div className="slidermargintopOemdesktop">
                        {" "}
                        <img src={JBM} className="fluid logoDesk" alt="" />
                      </div>
                      <div className="slidermargintopOemdesktop">
                        {" "}
                        <img
                          src={BrakesIndiaPvtLtd}
                          className="fluid logoDesk"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>

                  <div className="slide">
                    <div className="d-f f-c">
                      <div className="slidermargintopOemdesktop">
                        {" "}
                        <img
                          src={simpsonandco}
                          className="fluid logoDesk"
                          alt=""
                        />
                      </div>
                      <div className="slidermargintopOemdesktop">
                        {" "}
                        <img src={LNT} className="fluid logoDesk" alt="" />
                      </div>
                    </div>
                  </div>

                  <div className="slide">
                    <div className="d-f f-c">
                      <div className="slidermargintopOemdesktop">
                        {" "}
                        <img
                          src={PavnaAutoEngineeringPvtLtd}
                          className="fluid logoDesk"
                          alt=""
                        />
                      </div>
                      <div className="slidermargintopOemdesktop">
                        {" "}
                        <img
                          src={ReflexallenIndiaPvtLtd}
                          className="fluid logoDesk"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>

                  <div className="slide">
                    <div className="d-f f-c">
                      <div className="slidermargintopOemdesktop">
                        {" "}
                        <img
                          src={UcalfuelsystemsLtd}
                          className="fluid logoDesk"
                          alt=""
                        />
                      </div>
                      <div className="slidermargintopOemdesktop">
                        {" "}
                        <img src={Amson} className="fluid logoDesk" alt="" />
                      </div>
                    </div>
                  </div>

                  <div className="slide">
                    <div className="d-f f-c">
                      <div className="slidermargintopOemdesktop">
                        {" "}
                        <img src={Himakshi} className="fluid logoDesk" alt="" />
                      </div>
                      <div className="slidermargintopOemdesktop">
                        <img
                          src={SpicerIndiaPvtLtd}
                          className="fluid logoDesk"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>

                  <div className="slide">
                    <div className="d-f f-c">
                      <div className="slidermargintopOemdesktop">
                        {" "}
                        <img src={RCJ} className="fluid logoDesk" alt="" />
                      </div>
                      <div className="slidermargintopOemdesktop">
                        {" "}
                        <img src={speevo} className="fluid logoDesk" alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="slide">
                    <div className="d-f f-c">
                      <div className="slidermargintopOemdesktop">
                        {" "}
                        <img
                          src={mahle}
                          className="fluid logoDesk"
                          alt=""
                        />
                      </div>
                      {/* <div className="slidermargintopOemdesktop">
                        {" "}
                        <img src={TATA} className="fluid logoDesk" alt="" />
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : null}

        {tab === 1 ? (
          <div className="aftermarket">
            <div className="sliderTitle">AUTOMOTIVE</div>
            <div className="slider">
              <div className="slide-track">
                <div className="slide slidemarginright">
                  <div className="d-f f-c j-c">
                    <div className=" bordershadow">
                      {" "}
                      <img
                        src={width >= 1024 ? bike : mbtwowheeler}
                        className="fluid"
                        alt=""
                      />
                    </div>
                    <div className="cardTitle">2 WHEELER</div>
                  </div>
                </div>

                <div className="slide slidemarginright">
                  <div className="d-f f-c j-c">
                    <div className="bordershadow">
                      {" "}
                      <img
                        src={width >= 1024 ? auto : mbauto}
                        className="fluid"
                        alt=""
                      />
                    </div>
                    <div className="cardTitle">3 WHEELER</div>
                  </div>
                </div>

                <div className="slide slidemarginright">
                  <div className="d-f f-c j-c">
                    <div className="bordershadow">
                      {" "}
                      <img
                        src={width >= 1024 ? car : mbcar}
                        className="fluid"
                        alt=""
                      />
                    </div>
                    <div className="cardTitle">PASSENGER VEHICLES</div>
                  </div>
                </div>

                <div className="slide slidemarginright">
                  <div className="d-f f-c j-c">
                    <div className="bordershadow">
                      {" "}
                      <img
                        src={width >= 1024 ? tempo : mbtempo}
                        className="fluid"
                        alt=""
                      />
                    </div>
                    <div className="cardTitle">LCV/MCV/HCV</div>
                  </div>
                </div>

                <div className="slide slidemarginright">
                  <div className="d-f f-c j-c">
                    <div className=" bordershadow">
                      {" "}
                      <img
                        src={width >= 1024 ? bike : mbtwowheeler}
                        className="fluid"
                        alt=""
                      />
                    </div>
                    <div className="cardTitle">2 WHEELER</div>
                  </div>
                </div>

                <div className="slide slidemarginright">
                  <div className="d-f f-c j-c">
                    <div className="bordershadow">
                      {" "}
                      <img
                        src={width >= 1024 ? auto : mbauto}
                        className="fluid"
                        alt=""
                      />
                    </div>
                    <div className="cardTitle">3 WHEELER</div>
                  </div>
                </div>

                <div className="slide slidemarginright">
                  <div className="d-f f-c j-c">
                    <div className="bordershadow">
                      {" "}
                      <img
                        src={width >= 1024 ? car : mbcar}
                        className="fluid"
                        alt=""
                      />
                    </div>
                    <div className="cardTitle">PASSENGER VEHICLES</div>
                  </div>
                </div>

                <div className="slide slidemarginright">
                  <div className="d-f f-c j-c">
                    <div className="bordershadow">
                      {" "}
                      <img
                        src={width >= 1024 ? tempo : mbtempo}
                        className="fluid"
                        alt=""
                      />
                    </div>
                    <div className="cardTitle">LCV/MCV/HCV</div>
                  </div>
                </div>
              </div>
            </div>

            <div className="sliderTitle">NON-AUTOMOTIVE</div>
            <div className="slider">
              <div className="slide-track">
                <div className="slide slidemarginright">
                  <div className="d-f f-c j-c">
                    <div className="bordershadow">
                      {" "}
                      <img
                        src={width >= 1024 ? tractor : mbtractor}
                        className="fluid"
                        alt=""
                      />
                    </div>
                    <div className="cardTitle">TRACTORS</div>
                  </div>
                </div>

                <div className="slide slidemarginright">
                  <div className="d-f f-c j-c">
                    <div className="bordershadow">
                      {" "}
                      <img
                        src={width >= 1024 ? jcb : mbjcb}
                        className="fluid"
                        alt=""
                      />
                    </div>
                    <div className="cardTitle">EARTH MOVING EQUIPMENT</div>
                  </div>
                </div>

                <div className="slide slidemarginright">
                  <div className="d-f f-c j-c">
                    <div className="bordershadow">
                      {" "}
                      <img
                        src={width >= 1024 ? train : mbtrain}
                        className="fluid"
                        alt=""
                      />
                    </div>
                    <div className="cardTitle">RAILWAYS</div>
                  </div>
                </div>

                <div className="slide slidemarginright">
                  <div className="d-f f-c j-c">
                    <div className="bordershadow">
                      {" "}
                      <img
                        src={width >= 1024 ? other : mbother}
                        className="fluid"
                        alt=""
                      />
                    </div>
                    <div className="cardTitle">OTHER APPLICATIONS</div>
                  </div>
                </div>

                <div className="slide slidemarginright">
                  <div className="d-f f-c j-c">
                    <div className="bordershadow">
                      {" "}
                      <img
                        src={width >= 1024 ? ships : mbship}
                        className="fluid"
                        alt=""
                      />
                    </div>
                    <div className="cardTitle">MARINE APPLICATIONS</div>
                  </div>
                </div>

                <div className="slide slidemarginright">
                  <div className="d-f f-c j-c">
                    <div className="bordershadow">
                      {" "}
                      <img
                        src={width >= 1024 ? tractor : mbtractor}
                        className="fluid"
                        alt=""
                      />
                    </div>
                    <div className="cardTitle">TRACTORS</div>
                  </div>
                </div>

                <div className="slide slidemarginright">
                  <div className="d-f f-c j-c">
                    <div className="bordershadow">
                      {" "}
                      <img
                        src={width >= 1024 ? jcb : mbjcb}
                        className="fluid"
                        alt=""
                      />
                    </div>
                    <div className="cardTitle">EARTH MOVING EQUIPMENT</div>
                  </div>
                </div>

                <div className="slide slidemarginright">
                  <div className="d-f f-c j-c">
                    <div className="bordershadow">
                      {" "}
                      <img
                        src={width >= 1024 ? train : mbtrain}
                        className="fluid"
                        alt=""
                      />
                    </div>
                    <div className="cardTitle">RAILWAYS</div>
                  </div>
                </div>

                <div className="slide slidemarginright">
                  <div className="d-f f-c j-c">
                    <div className="bordershadow">
                      {" "}
                      <img
                        src={width >= 1024 ? other : mbother}
                        className="fluid"
                        alt=""
                      />
                    </div>
                    <div className="cardTitle">OTHER APPLICATIONS</div>
                  </div>
                </div>

                <div className="slide slidemarginright">
                  <div className="d-f f-c j-c">
                    <div className="bordershadow">
                      {" "}
                      <img
                        src={width >= 1024 ? ships : mbship}
                        className="fluid"
                        alt=""
                      />
                    </div>
                    <div className="cardTitle">MARINE APPLICATIONS</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {tab === 2 ? (
          <div className="export">
            {width < 1024 ? (
              <div className="slider">
                <div className="slide-track">
                  <div className="slide d-f f-c slidermarginright">
                    <div className="d-f f-c j-c a-c exportcol">
                      <div>
                        <img src={mbspain} className="fluid" />
                      </div>
                      <div className="countryName">SPAIN</div>
                    </div>

                    <div className="d-f f-c j-c a-c exportcol">
                      <div>
                        <img src={mbnepal} className="fluid" />
                      </div>
                      <div className="countryName">NEPAL</div>
                    </div>
                  </div>

                  <div className="slide d-f f-c slidermarginright">
                    <div className="d-f f-c j-c a-c exportcol">
                      <div>
                        <img src={mbcolumbia} className="fluid" />
                      </div>
                      <div className="countryName">CAMBODIA</div>
                    </div>

                    <div className="d-f f-c j-c a-c exportcol">
                      <div>
                        <img src={mbegypt} className="fluid" />
                      </div>
                      <div className="countryName">EGYPT</div>
                    </div>
                  </div>


                  <div className="slide d-f f-c slidermarginright">
                    <div className="d-f f-c j-c a-c exportcol">
                      <div>
                        <img src={mbsrilanka} className="fluid" />
                      </div>
                      <div className="countryName">SRI LANKA</div>
                    </div>

                    <div className="d-f f-c j-c a-c exportcol">
                      <div>
                        <img src={mbbangaladesh} className="fluid" />
                      </div>
                      <div className="countryName">BANGALADESH</div>
                    </div>
                  </div>
                  <div className="slide d-f f-c slidermarginright">
                    <div className="d-f f-c j-c a-c exportcol">
                      <div>
                        <img src={mbuk} className="fluid" />
                      </div>
                      <div className="countryName">UK</div>
                    </div>

                    
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className="d-f firstrowexport">

                  <div className="d-f f-c j-c a-c exportcol">
                    <div>
                      <img src={mbspain} className="fluid" />
                    </div>
                    <div className="countryName">SPAIN</div>
                  </div>

                  <div className="d-f f-c j-c a-c exportcol">
                    <div>
                      <img src={mbcambodia} className="fluid" />
                    </div>
                    <div className="countryName">CAMBODIA</div>
                  </div>
                  <div className="d-f f-c j-c a-c exportcol">
                    <div>
                      <img src={mbsrilanka} className="fluid" />
                    </div>
                    <div className="countryName">SRI LANKA</div>
                  </div>

                  <div className="d-f f-c j-c a-c exportcol">
                    <div>
                      <img src={mbukk} className="fluid" />
                    </div>
                    <div className="countryName">UK</div>
                  </div>


                </div>

                <div className="d-f secondRowexport">
                  <div className="d-f f-c j-c a-c exportcol photo">
                    <div>
                      <img src={mbnepal} className="fluid" />
                    </div>
                    <div className="countryName">NEPAL</div>
                  </div>

                  <div className="d-f f-c j-c a-c exportcol photo ">
                    <div>
                      <img src={mbegypt} className="fluid" />
                    </div>
                    <div className="countryName">EGYPT</div>
                  </div>

                  <div className="d-f f-c j-c a-c exportcol photo">
                    <div>
                      <img src={mbbangaladesh} className="fluid" />
                    </div>
                    <div className="countryName">BANGALADESH</div>
                  </div>
                </div>





              </>
            )}
          </div>
        ) : null}
      </section>
    </>
  )
}

export default Clients
