import React from 'react'
import Button from '../Button/Button'
import './style.less'


const FacilityHomeItem = props => {
return (
    props.mobile ?
        <div style={{backgroundImage:`url(${props.bg})`}} className="facilitiesContainer">
        <div className="item_title">{props.title}</div>
<div className="item_desc">{props.desc}</div>
        <div className="btnContainer">
                            <Button onClick={props.onClick} white title="KNOW MORE"/>
        </div>
        </div>
        :
    <div onMouseEnter={props.onEnter} className="FacilityContainer c-p d-f f-c a-c j-c">
        <div className="titleContainer">
                            <div className="title1">
                                {props.title}
                            </div>
                            <div className="descr1">
                            {props.desc}
                            </div>
                            <div className="btnContainer">
                            <Button onClick={props.onClick}white title="KNOW MORE"/>
                            </div>
        </div>
    </div>
    
)
}

export default FacilityHomeItem