import React from 'react'
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect,
} from 'react-router-dom'
import { createBrowserHistory } from 'history'

import Homepage from './container/Homepage/HomePage'
import Footer from './component/Footer/Footer'
import Header from './component/Header/Header'
import OurValues from './container/OurValues/OurValues'
import Ourstory from './container/Ourstory/Ourstory'
import ContactUs from './container/ContactUs/ContactUs'
import Clients from './container/Clients/Clients'
import Manufacturing from './container/Manufacturing/Manufacturing'
import DevelopmentQuality from './container/DevelopentQuality/DevelopmentQuality'
import Csr from './container/Csr/Csr'
import Automotive from './container/Automotive/Automotive'
import Media from './container/Media/Media'

const history = createBrowserHistory()

const DefaultLayout = (props) => {
	return (
		<>
			<Header />
			<div className='marginMiddleHomeContainer'>
				<Switch>
					<Route exact path='/' component={Homepage} />
					<Route exact path='/ourvalues' component={OurValues} />
					<Route exact path='/ourstory' component={Ourstory} />
					<Route exact path='/contactus' component={ContactUs} />
					<Route exact path='/clients' component={Clients} />
					<Route exact path ='/media' component={Media}/>
					<Route
						exact
						path='/manufacturingFacilities'
						component={Manufacturing}
					/>
					<Route
						exact
						path='/developentandqualitycontrol'
						component={DevelopmentQuality}
					/>
					<Route exact path='/csr' component={Csr} />
					<Route exact path='/product/:name' component={Automotive} />
					<Route exact path='/product/:name/:id' component={Automotive} />
					<Route exact path='/automotive' component={Automotive} />
					<Redirect to='/404' />
				</Switch>
			</div>
			<Footer />
		</>
	)
}

export default DefaultLayout
