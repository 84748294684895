import React, { useState, useEffect } from "react"
import BlueHeader from "../../component/BlueHeader/BlueHeader"
import WOW from "wowjs"

import deskheaderbg from "../../images/headerbg.png"
import banner1 from "../../images/developement/banner1.jpg"
import mbbanner1 from "../../images/developement/mbbanner1.jpg"
import banner2 from "../../images/developement/banner2.jpg"
import mbbanner2 from "../../images/developement/mbbanner2.jpg"

import c1 from "../../images/developement/c1.jpg"
import c2 from "../../images/developement/c2.jpg"
import c3 from "../../images/developement/c3.jpg"

import dots from "../../images/homepage/dots.png"
import Tab from "../../component/Tab/Tab"

import "./style.less"
import Swiper, { Navigation, Pagination, Controller, Breakpoints } from "swiper"
import "swiper/swiper-bundle.css"

import { Row, Col } from "antd"
import useWindowDimensions from "../../useWindowDimensions"
Swiper.use([Navigation, Pagination, Controller])

const DevelopmentQuality = (props) => {
  const { height, width } = useWindowDimensions()
  const [tab, setTab] = useState(0)

  useEffect(() => {
    window.scrollTo(0, 0)

    document.title = "Acey - Developement & Quality Control"

    new WOW.WOW({
      live: false,
    }).init()
  }, [])

  useEffect(() => {
    const mbtestinglist = new Swiper(".certificate-swiper", {
      slidesPerView: 1,
      loop: true,
      navigation: {
        nextEl: ".development_sec1 .next",
        prevEl: ".development_sec1 .prev",
      },
    })
  }, [tab])

  return (
    <>
      <BlueHeader title="DEVELOPMENT & QUALITY CONTROL" img={deskheaderbg} />
      <section className="development_sec1 ">
        {width >= 1280 ? (
          <div className="dotContainer1">
            <div className="d-f f-c">
              <div
                className="wow fadeIn"
                data-wow-duration="2s"
                data-wow-delay=".5s"
              >
                <img src={dots} className="fluid dots" />
              </div>
            </div>
          </div>
        ) : null}

        {width >= 1280 ? (
          <div className="dotContainer2">
            <div className="d-f f-c">
              <div
                className="wow fadeIn"
                data-wow-duration="2s"
                data-wow-delay=".5s"
              >
                <img src={dots} className="fluid dots" />
              </div>
            </div>
          </div>
        ) : null}

        <div className="d-f tabcontainer wow fadeIn">
          <Tab
            onClick={() => setTab(0)}
            active={tab === 0 ? true : false}
            title="DEVELOPMENT"
          />
          <Tab
            onClick={() => setTab(1)}
            active={tab === 1 ? true : false}
            title="QUALITY CONTROL"
          />
        </div>

        {tab === 0 ? (
          <div className="developmentContainer wow fadeInUp">
            <div className="imgContainer d-f j-c">
              <div>
                {" "}
                <img
                  src={width >= 1024 ? banner1 : mbbanner1}
                  className="fluid"
                />
              </div>
            </div>

            <div className="desc">
              Acey has a dedicated research and development team of engineers,
              committed to innovate, develop and provide the most efficient and
              resilient product at the best possible price. Adding value by way
              of design, material and value engineering is the path forward to
              deliver more than what was committed.
            </div>
            <div className="desc2">
              We develop products as per the customer requirements, taking total
              care of the application and specification. Our team is well-versed
              with the knowledge of all international Standards like
            </div>
            <div
              style={{ backgroundColor: "#F3F3F3" }}
              className="countryContainer"
            >
              <div className="countrylist">
                {width >= 1024 ? (
                  <div style={{ color: "#034EA2" }} className="contryText">
                    DIN (Germany)&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;SAE/AISI
                    (America)&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;JASO/JIS
                    (Japan)&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;BS
                    (Britain)&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;ISO (India)
                  </div>
                ) : (
                  <>
                    <div style={{ color: "#034EA2" }} className="contryText">
                      DIN (Germany)
                    </div>
                    <div style={{ color: "#034EA2" }} className="contryText">
                      SAE/AISI (America)
                    </div>
                    <div style={{ color: "#034EA2" }} className="contryText">
                      JASO/JIS (Japan)
                    </div>
                    <div style={{ color: "#034EA2" }} className="contryText">
                      BS (Britain)
                    </div>
                    <div style={{ color: "#034EA2" }} className="contryText">
                      ISO (India)
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="desc3">
              We believe that continued R&D activities are critical to
              maintaining our growth and position in the industry and help us in
              developing and manufacturing world class products.
            </div>
          </div>
        ) : (
          <div className="developmentContainer wow fadeInUp">
            <div className="imgContainer d-f j-c">
              <div>
                {" "}
                <img
                  src={width >= 1024 ? banner2 : mbbanner2}
                  className="fluid"
                />
              </div>
            </div>

            <div className="desc">
              Quality forms the foundation of all Acey’s operations. Each part
              manufactured at Acey has to undergo stringent Quality checks &
              inspection at all stages of manufacturing. We are committed to
              deliver a Zero Defect product to all end users. Our Quality
              Assurance Process works towards continuous improvements through
              <span>
                &nbsp;Quality Circles, Employee Training, Environment Management
                systems, Kaizens & Lean Manufacturing Methodologies.
              </span>
              &nbsp;Excellent ratings & constant appreciation from our customers
              stand proof of the effectiveness & efficiency of our Quality
              Management Systems.<br></br>
              <br></br>
            </div>

            <div className="marginbottom">
              <div className="countryContainer">
                <div style={{ textAlign: "center" }} className="title">
                  Our Quality & Environment Management Systems Certifications
                </div>
                {width >= 1024 ? (
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        textAlign: "center",
                      }}
                    >
                      <div>
                        <img
                          style={{ border: "2px solid #034ea2" }}
                          className="fluid"
                          src={c1}
                        />
                        <div className="contryText">IATF 16949:2016</div>
                      </div>
                      <div>
                        <img
                          style={{ border: "2px solid #034ea2" }}
                          className="fluid"
                          src={c2}
                        />

                        <div className="contryText">ISO 14001:2015</div>
                      </div>
                      <div>
                        <img
                          style={{ border: "2px solid #034ea2" }}
                          className="fluid"
                          src={c3}
                        />

                        <div className="contryText">ISO 45001:2018</div>
                      </div>
                    </div>
                    {/* <div className="desc4">
                      Our certifications are a testament to the consistent
                      improvement in the quality of our products, processes and
                      our purpose to deliver service to the entire satisfaction
                      of our clients.
                    </div>
                    <div className="countrylist">
                      <div
                        className="contryText"
                        style={{
                          paddingTop: "1.250em",
                          paddingBottom: "1.964em",
                        }}
                      >
                        IATF 16949:2016&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;ISO
                        14001:2015&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;ISO
                        45001:2018
                      </div>
                    </div> */}
                  </>
                ) : (
                  <>
                    <Row className="awardsrow">
                      <Col
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        className="firstcol mbleft"
                        md={3}
                        xs={3}
                      >
                        <div className="c-p prev">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="45"
                            height="45"
                            viewBox="0 0 79 79"
                          >
                            <g
                              id="Group_1087"
                              data-name="Group 1087"
                              transform="translate(-247 -1435)"
                            >
                              <g
                                id="Group_1088"
                                data-name="Group 1088"
                                transform="translate(247 1435)"
                              >
                                <circle
                                  id="Ellipse_160"
                                  data-name="Ellipse 138"
                                  cx="39.5"
                                  cy="39.5"
                                  r="39.5"
                                  fill="#f3f3f3"
                                />
                                <g
                                  id="Icon_feather-arrow-right"
                                  data-name="Icon feather-arrow-right"
                                  transform="translate(30.967 23.168)"
                                >
                                  <path
                                    id="Path_2160"
                                    data-name="Path 2143"
                                    d="M34.809,7.5,18,24.309,34.809,41.118"
                                    transform="translate(-18 -7.5)"
                                    fill="none"
                                    stroke="#000"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="3"
                                  />
                                </g>
                              </g>
                            </g>
                          </svg>
                        </div>
                        {/* <div>
                  <img src={width>=1024?leftarrow:mbleftarrow} className="fluid prev"/>
                </div> */}
                      </Col>
                      <Col md={18} xs={18} className="secondCol">
                        <div className="certificate-swiper swiper-container">
                          <div className="swiper-wrapper">
                            <div className="swiper-slide">
                              <div
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                <img
                                  style={{ border: "2px solid #034ea2" }}
                                  src={c1}
                                />
                                <div className="contryText">
                                  IATF 16949:2016
                                </div>
                              </div>
                            </div>
                            <div className="swiper-slide">
                              <div style={{ textAlign: "center" }}>
                                <img
                                  style={{ border: "2px solid #034ea2" }}
                                  src={c2}
                                />
                                <div className="contryText">
                                  IATF 16949:2016
                                </div>
                              </div>
                            </div>
                            <div className="swiper-slide">
                              <div style={{ textAlign: "center" }}>
                                <img
                                  style={{ border: "2px solid #034ea2" }}
                                  src={c3}
                                />
                                <div className="contryText">
                                  IATF 16949:2016
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        className="firstcol mbRight"
                        md={3}
                        xs={3}
                      >
                        <div className="c-p next">
                          <svg
                            id="Group_1090"
                            data-name="Group 1090"
                            xmlns="http://www.w3.org/2000/svg"
                            width="45"
                            height="45"
                            viewBox="0 0 79 79"
                          >
                            <g id="Group_1089" data-name="Group 1088">
                              <circle
                                id="Ellipse_161"
                                data-name="Ellipse 138"
                                cx="39.5"
                                cy="39.5"
                                r="39.5"
                                fill="#f3f3f3"
                              />
                              <g
                                id="Icon_feather-arrow-right"
                                data-name="Icon feather-arrow-right"
                                transform="translate(31.224 23.168)"
                              >
                                <path
                                  id="Path_2161"
                                  data-name="Path 2143"
                                  d="M18,7.5,34.809,24.309,18,41.118"
                                  transform="translate(-18 -7.5)"
                                  fill="none"
                                  stroke="#000"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="3"
                                />
                              </g>
                            </g>
                          </svg>
                        </div>
                        {/* <div>
                    <img src={width>=1024?rightarrow:mbrightarrow} className="fluid next"/>
                    </div> */}
                      </Col>
                    </Row>

                    {/* <div className="desc4">
                      Our certifications are a testament to the consistent
                      improvement in the quality of our products, processes and
                      our purpose to deliver service to the entire satisfaction
                      of our clients.
                    </div>
                    <div className="countrylist">
                      <div className="contryText">IATF 16949:2016</div>
                      <div className="contryText">ISO 14001:2015</div>
                      <div className="contryText">ISO 45001:2018</div>
                    </div> */}
                  </>
                )}
              </div>
              <div className="whitespace"></div>
            </div>
          </div>
        )}
      </section>
    </>
  )
}

export default DevelopmentQuality
