import React from 'react'
import './style.css'
import logo from '../../images/404Desktop.png'
import mlogo from '../../images/404mb.png'
import useWindowDimensions from '../../useWindowDimensions'
import Button from '../../component/Button/Button'

const PageNotFound = props=>{
    const { height, width } = useWindowDimensions();
    return(
        <main className="pageNotFound">
        {width > 834 ?<h1>4<span><img src={logo} className="fluid"/></span>4</h1> :<h1>4<span><img src={mlogo} className="fluid"/></span>4</h1>}
        <h2>Error: 404 page not found</h2>
        <p>Sorry, the page you're looking for cannot be accessed</p>
        <Button title="GO BACK TO HOMEPAGE" onClick={()=>props.history.push('/')}/>
      </main>
    );
}

export default PageNotFound;