import React, { useState, useEffect } from 'react'
import Dropdown from 'react-dropdown';
import BlueHeader from '../../component/BlueHeader/BlueHeader'
import WOW from 'wowjs'

import deskheaderbg from '../../images/headerbg.png'
import location from '../../images/contactus/location.png'
import user from '../../images/contactus/user.png'
import phone from '../../images/contactus/phone.png'
import mail from '../../images/contactus/mail.png'
import mbuser from '../../images/contactus/mbuser.png'
import mbphone from '../../images/contactus/mbphone.png'
import mbmail from '../../images/contactus/mbmail.png'
import mblocation from '../../images/contactus/mblocation.png'

import mbrightarrow from '../../images/ourstory/mbrightarrow.png'
import mbleftarrow from '../../images/ourstory/mbleftarrow.png'
import dots from '../../images/homepage/dots.png'
import Tab from '../../component/Tab/Tab'
import moment from 'moment'

import InputText from '../../component/InputText/InputText'
import Button from '../../component/Button/Button'

import urldata from '../../urldata'
import axios from 'axios'
import {
	ToastsContainer,
	ToastsStore,
	ToastsContainerPosition,
} from 'react-toasts'

import './style.less'
import Swiper, { Navigation, Pagination, Controller, Breakpoints } from 'swiper'
import 'swiper/swiper-bundle.css'

import { Row, Col } from 'antd'
import useWindowDimensions from '../../useWindowDimensions'
Swiper.use([Navigation, Pagination, Controller])

Swiper.use([Navigation, Pagination, Controller])

const Media = (props) => {
	const { height, width } = useWindowDimensions()
	const [tab, setTab] = useState(0)
	const [name, setName] = useState('')
	const [nameerr, setNameerr] = useState('')
	const [phoneNumber, setPhoneNumber] = useState('')
	const [phoneNumbererr, setPhoneNumbererr] = useState('')
	const [emailId, setEmailId] = useState('')
	const [emailIderr, setEmailIderr] = useState('')
	const [message, setMessage] = useState('')
	const [messageerr, setMessageErr] = useState('')

	const [contactName, setContactName] = useState('')
	const [contactMail, setContactMail] = useState('')
	const [contactPhone, setContactPhone] = useState('')

	const [Careers, setCareers] = useState(false)
	const [Sales, setSales] = useState(false)
	const [Purchase, setPurchase] = useState(false)


	const handleChange = (event) => {

		if (event.name == "Careers")
			setCareers(event.target.checked);
		setSales(false)
	}

	useEffect(() => {
		window.scrollTo(0, 0)

		new WOW.WOW({
			live: false,
		}).init()

		document.title = 'Acey - Contact Us'

		const contactusswiper = new Swiper('.contact-swiper', {
			slidesPerView: 1,
			loop: true,
			spaceBetween: 0,
			autoHeight: true,
			pagination: {
				el: '.contactus_sec1 .swiper-pagination',
				clickable: true,
			},
		})
	}, [])

	const submitBtnClick = () => {
		var formIsValid = true

		if (!name) {
			formIsValid = false
			setNameerr('*Please enter your name.')
		} else {
			setNameerr('')
		}

		if (typeof name !== 'undefined') {
			if (!name.match(/^[a-zA-Z ]*$/)) {
				formIsValid = false
				setNameerr('*Please enter alphabet characters only.')
			}
		}

		if (typeof emailId !== 'undefined') {
			//regular expression for email validation
			var pattern = new RegExp(
				/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
			)
			if (!pattern.test(emailId)) {
				formIsValid = false
				setEmailIderr('*Please enter valid email-Id.')
			} else {
				setEmailIderr('')
			}
		}

		if (typeof phoneNumber !== 'undefined') {
			if (!phoneNumber.match(/^[0-9]{10}$/)) {
				formIsValid = false
				setPhoneNumbererr('*Please enter valid mobile no.')
			} else {
				setPhoneNumbererr('')
			}
		}

		if (formIsValid) {
			let data = {}
			data.name = name
			data.emailId = emailId
			data.phoneNumber = phoneNumber
			data.message = message
			data.date = moment().format('DD-MM-YYYY')
			data.time = moment().format('hh:mm a')
			console.log(checkBoxName);
			if (tab === 0 && checkBoxName === "checkbox1") {
				data.type = 'careers'
			}

			if (tab === 1 && checkBoxName === "checkbox2") {
				data.type = 'sales'
			}

			if (tab === 2 && checkBoxName === "checkbox3") {
				data.type = 'supplier'
			}

			let url = new URL(urldata + 'user/enquiry')

			axios({
				method: 'post',
				url: url,
				data: data,
			})
				.then((response) => {
					setName('')
					setNameerr('')
					setEmailId('')
					setEmailIderr('')
					setPhoneNumber('')
					setPhoneNumbererr('')
					setMessage('')
					setMessageErr('')
					//console.log("login success  ")
					ToastsStore.success('Thank you for your query!')
				})
				.catch((error) => {
					// alert("Something Went Wrong")
					ToastsStore.error('Something Went Wrong')
				})
		}
	}

	useEffect(() => {
		switch (tab) {
			case 0:
				setContactName('Mr. Kiran Variya')
				setContactPhone('9016444155')
				setContactMail('hrbilimora@aceyindia.com')
				break
			case 1:
				setContactName('Ms. Brinda Garasia')
				setContactPhone('6354632440')
				setContactMail('sales@aceyindia.com')
				break

			case 2:
				setContactName('Mr. Dhaval Choksi')
				setContactPhone('99043319141')
				setContactMail('purchase@aceyindia.com')
				break

			default:
				break
		}
	}, [tab])
	const [checkboxState, setCheckboxState] = useState({
		checkbox1: false,
		checkbox2: false,
		checkbox3: false,
	});
	const [checkBoxName, setcheckBoxName] = useState('')

	const handleCheckboxChange = (checkboxName) => {
		setcheckBoxName(checkboxName)
		setCheckboxState((prevState) => ({
			...prevState,
			[checkboxName]: true,
		}));

		for (const key in checkboxState) {
			if (key !== checkboxName) {
				setCheckboxState((prevState) => ({
					...prevState,
					[key]: false,
				}));
			}
		}
	};

	return (
		<>


			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					height: '100vh',
				}}
			>
				
			</div>



			<ToastsContainer
				position={ToastsContainerPosition.BOTTOM_RIGHT}
				store={ToastsStore}
			/>
		</>
	)
}
export default Media
