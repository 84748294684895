import React, { useEffect } from 'react'


import './style.css'



const PaginationComponent = props =>{

    const paginNumberList =props.totalPageArray.map((item,i)=> <div key={i} className={props.selectedPage +1 ===item?"SelectedPaginationNumberTxt" :"paginationNumberTxt c-p"} onClick={()=>props.paginationNoClick(item)} >{item}</div>)


  //  console.log(props.totalPageArray)
    return(
        <div className="d-f a-c">
            {paginNumberList}
        </div>
    );
}

export default PaginationComponent