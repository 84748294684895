import React from 'react'


import './style.less'

const CardOurValues = props => {
    return (
   
                        <div className="cardContainer">
                        <div className="d-f imageContainer">
                            <div>
                            <img src={props.img} className="fluid ourValuesImg"/>
                            </div>
                            <div className="title">
                                {props.title}
                            </div>
                        </div>
                        <div className="desc">
                            {props.desc}
                        </div>
                    </div>
   
    );
}

export default CardOurValues;