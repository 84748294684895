import React, { useState, useEffect } from "react"
import SimpleImageSlider from "react-simple-image-slider";
import BlueHeader from "../../component/BlueHeader/BlueHeader"
import WOW from "wowjs"
import Carousel from "../../component/Carousel/Carousel";

import deskheaderbg from "../../images/headerbg.png"
import rowimg1 from "../../images/csr/rowimg1.jpg"
import rowimg2 from "../../images/csr/rowimg2.jpg"
import rowimg3 from "../../images/csr/rowimg3.jpg"
import rowimg4 from "../../images/csr/rowimg4.jpg"
import tree from "../../images/csr/tree.png"
import plastic from "../../images/csr/plastic.png"
import education from "../../images/csr/education.png"
import community from "../../images/csr/community.png"


import tree1 from '../../images/csr/treeplantation/tree1.jpg'
import tree2 from '../../images/csr/treeplantation/tree2.jpg'
import tree3 from '../../images/csr/treeplantation/tree3.jpg'
import tree4 from '../../images/csr/treeplantation/tree4.jpg'
import tree5 from '../../images/csr/treeplantation/tree5.jpg'


import community1 from '../../images/csr/community/com1.jpg'
import community2 from '../../images/csr/community/com2.jpg'

import edu1 from '../../images/csr/education/edu1.jpeg'
import edu2 from '../../images/csr/education/edu2.jpeg'
import edu3 from '../../images/csr/education/edu3.jpeg'
import edu4 from '../../images/csr/education/edu4.jpeg'

import dots from "../../images/homepage/dots.png"

import "./style.less"

import { Row, Col } from "antd"
import useWindowDimensions from "../../useWindowDimensions"
import CsrItem from "../../component/CsrComponent/CsrItem"

const csrItemArr = [
  {
    img: rowimg1,
    smImg: tree,
    title: "TREE PLANTATION DRIVE",
    desc:
      "We have planted over 350 trees in the surrounding areas of all our plants since the past three years. We have a mission to plant a minimum of 100 trees every year.",
    reverse: false,
    imageArr:[tree1, tree2, tree3, tree4, tree5,]
  },
  {
    img: rowimg2,
    smImg: plastic,
    title: "NO TO PLASTICS",
    desc:
      "Use of Recyclable if and where required is the way forward at Acey. For us, it’s a big NO to single use plastics.",
    reverse: true,
    imageArr:[rowimg2, rowimg2, rowimg2, rowimg2]
  },
  {
    img: rowimg3,
    smImg: education,
    title: "EDUCATION",
    desc:
      "Providing free education to the underprivileged in surrounding schools, books and stationary to the needy, donating clothes, shoes and school bags where required is an annual activity at Acey.",
    reverse: false,
    imageArr:[edu1, edu2, edu3, edu4]
  },
  {
    img: rowimg4,
    smImg: community,
    title: "Community",
    desc:
      "Acey has allocated a community hall for social gatherings, seminars and cultural programmes so as to encourage communal activities and educational seminars.",
    reverse: true,
    imageArr:[community1, community2]
  },
]

const Csr = (props) => {
  const { height, width } = useWindowDimensions()

  useEffect(() => {
    window.scrollTo(0, 0)
    document.title = "Acey - CSR"
    new WOW.WOW({
      live: false,
    }).init()
  }, [])
  const list = csrItemArr.map((item, i) => (
    <CsrItem
      img={item.img}
      smImg={item.smImg}
      title={item.title}
      desc={item.desc}
      reverse={item.reverse}
      imageArr={item.imageArr}
    />
  ))
  return (
    <>
      <BlueHeader title="CORPORATE SOCIAL RESPONSIBILITY" img={deskheaderbg} />
   
      <section className="csr_sec1 wow fadeIn">
        {width > 1280 ? (
          <div className="dotContainer1">
            <div className="d-f f-c">
              <div
                className="wow fadeIn"
                data-wow-duration="2s"
                data-wow-delay=".5s"
              >
                <img src={dots} className="fluid dots" />
              </div>
            </div>
          </div>
        ) : null}

        {width > 1280 ? (
          <div className="dotContainer2">
            <div className="d-f f-c">
              <div
                className="wow fadeIn"
                data-wow-duration="2s"
                data-wow-delay=".5s"
              >
                <img src={dots} className="fluid dots" />
              </div>
            </div>
          </div>
        ) : null}

        <div className="csrdesc wow fadeInUp">
          Acey strongly believes that we have a responsibility towards our
          employees, business partners, community and the environment as they
          all contribute to our success. We are actively involved in promoting
          and doing the following activities
        </div>

        {list}
      </section>
    </>
  )
}

export default Csr
