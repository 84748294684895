import React, { useState, useEffect } from "react"
import BlueHeader from "../../component/BlueHeader/BlueHeader"
import WOW from "wowjs"

import leftarrowmb from "../../images/ourvalues/leftarrow.png"
import rightarrowmb from "../../images/ourvalues/rightarrow.png"

import deskheaderbg from "../../images/headerbg.png"

import dots from "../../images/homepage/dots.png"

//missing 3
import InnerStrandingMachinesdesktop from "../../images/manufacturing/InnerStrandingMachinesdesktop.jpg"
import AutomaticSpringCoilersAutoWindersdesktop from "../../images/manufacturing/AutomaticSpringCoilersAutoWindersdesktop.jpg"
import VerticalDieCastingMachinesdesktop from "../../images/manufacturing/VerticalDieCastingMachinesdesktop.jpg"
import HydroPneumaticImpactPressesdesktop from "../../images/manufacturing/HydroPneumaticImpactPressesdesktop.jpg"
import SwagingMachinesforRailwaycablesdesktop from "../../images/manufacturing/SwagingMachinesforRailwaycablesdesktop.jpg"
import PLCControlledInjectionMoldingdesktop from "../../images/manufacturing/PLCControlledInjectionMoldingdesktop.jpg"
import conveyorassemblylinesdesktop from "../../images/manufacturing/conveyorassemblylinesdesktop.jpg"
import machineshopmanufacturing from "../../images/manufacturing/machineshopmanufacturing.jpg"

//mobile missing

import mbInnerStrandingMachinesdesktop from "../../images/manufacturing/mbInnerStrandingMachinesdesktop.jpg"
import mbAutomaticSpringCoilersAutoWindersdesktop from "../../images/manufacturing/mbAutomaticSpringCoilersAutoWindersdesktop.jpg"
import mbVerticalDieCastingMachinesdesktop from "../../images/manufacturing/mbVerticalDieCastingMachinesdesktop.jpg"
import mbHydroPneumaticImpactPressesdesktop from "../../images/manufacturing/mbHydroPneumaticImpactPressesdesktop.jpg"
import mbSwagingMachinesforRailwaycablesdesktop from "../../images/manufacturing/mbSwagingMachinesforRailwaycablesdesktop.jpg"
import mbPLCControlledInjectionMoldingdesktop from "../../images/manufacturing/mbPLCControlledInjectionMoldingdesktop.jpg"
import mbconveyorassemblylinesdesktop from "../../images/manufacturing/mbconveyorassemblylinesdesktop.jpg"
import mbmachineshopmanufacturing from "../../images/manufacturing/mbmachineshopmanufacturing.jpg"

//tesing

import BucklingTestingRig from "../../images/manufacturing/testing/BucklingTestingRig.jpg"
import CrushingLoad from "../../images/manufacturing/testing/CrushingLoad.jpg"
import DigitalUniversalTensileTestingMachine from "../../images/manufacturing/testing/DigitalUniversalTensileTestingMachine.jpg"
import DipstickAssemblyEnduranceTestingRig from "../../images/manufacturing/testing/DipstickAssemblyEnduranceTestingRig.jpg"
import DustTestChamber from "../../images/manufacturing/testing/DustTestChamber.jpg"
import EnduranceTestingRig from "../../images/manufacturing/testing/EnduranceTestingRig.jpg"
import EnvironmentalHotColdChamber from "../../images/manufacturing/testing/EnvironmentalHotColdChamber.jpg"
import LoadEfficiencyTestingRig from "../../images/manufacturing/testing/LoadEfficiencyTestingRig.jpg"
import MultipleEnduranceTestingRig from "../../images/manufacturing/testing/MultipleEnduranceTestingRig.jpg"
import ProofLoadTestingFixtures from "../../images/manufacturing/testing/ProofLoadTestingFixtures.jpg"
import RockingDurabilityTestingRig from "../../images/manufacturing/testing/RockingDurabilityTestingRig.jpg"
import SaltSprayTestingChamber from "../../images/manufacturing/testing/SaltSprayTestingChamber.jpg"
import SpeedometerCableEnduranceTesting from "../../images/manufacturing/testing/SpeedometerCableEnduranceTesting.jpg"
import SpeedometerInnerTorsionalLoadTesting from "../../images/manufacturing/testing/SpeedometerInnerTorsionalLoadTesting.jpg"
import TestingLab from "../../images/manufacturing/testing/TestingLab.jpg"

import mbBucklingTestingRig from "../../images/manufacturing/testing/mbBucklingTestingRig.jpg"
import mbCrushingLoad from "../../images/manufacturing/testing/mbCrushingLoad.jpg"
import mbDigitalUniversalTensileTestingMachine from "../../images/manufacturing/testing/mbDigitalUniversalTensileTestingMachine.jpg"
import mbDipstickAssemblyEnduranceTestingRig from "../../images/manufacturing/testing/mbDipstickAssemblyEnduranceTestingRig.jpg"
import mbDustTestChamber from "../../images/manufacturing/testing/mbDustTestChamber.jpg"
import mbEnduranceTestingRig from "../../images/manufacturing/testing/mbEnduranceTestingRig.png"
import mbEnvironmentalHotColdChamber from "../../images/manufacturing/testing/mbEnvironmentalHotColdChamber.jpg"
import mbLoadEfficiencyTestingRig from "../../images/manufacturing/testing/mbLoadEfficiencyTestingRig.jpg"
import mbMultipleEnduranceTestingRig from "../../images/manufacturing/testing/mbMultipleEnduranceTestingRig.jpg"
import mbProofLoadTestingFixtures from "../../images/manufacturing/testing/mbProofLoadTestingFixtures.jpg"
import mbRockingDurabilityTestingRig from "../../images/manufacturing/testing/mbRockingDurabilityTestingRig.jpg"
import mbSaltSprayTestingChamber from "../../images/manufacturing/testing/mbSaltSprayTestingChamber.jpg"
import mbSpeedometerCableEnduranceTesting from "../../images/manufacturing/testing/mbSpeedometerCableEnduranceTesting.jpg"
import mbSpeedometerInnerTorsionalLoadTesting from "../../images/manufacturing/testing/mbSpeedometerInnerTorsionalLoadTesting.jpg"
import mbtestingLab from "../../images/manufacturing/testing/mbtestingLab.jpg"

// import leftarrow from '../../images/ourstory/leftarrow.png'
// import rightarrow from '../../images/ourstory/rightarrow.png'

import "./style.less"
import Swiper, {
  Navigation,
  Pagination,
  Controller,
  Breakpoints,
  Autoplay,
} from "swiper"
import "swiper/swiper-bundle.css"

import { Row, Col } from "antd"
import useWindowDimensions from "../../useWindowDimensions"
Swiper.use([Navigation, Pagination, Controller, Autoplay])

const Manufacturing = (props) => {
  const { height, width } = useWindowDimensions()

  useEffect(() => {
    document.title = "Acey - Manufacturing & Testing Facilities"
    window.scrollTo(0, 0)

    new WOW.WOW({
      live: false,
    }).init()

    const manufacturinglist = new Swiper(".manufacturinglist-swiper", {
      slidesPerView: 3,
      spaceBetween: 100,
      loop: true,
      autoplay: {
        delay: 3000,
      },
      disableOnInteraction: true,
      navigation: {
        nextEl: ".manufacturing_sec1 .next",
        prevEl: ".manufacturing_sec1 .prev",
      },
      breakpoints: {
        1024: {
          spaceBetween: 80,
        },
      },
    })

    const mbmanufacturinglist = new Swiper(".mbmanufacturinglist-swiper", {
      slidesPerView: 1,
      loop: true,
      navigation: {
        nextEl: ".manufacturing_sec1 .rightarrow",
        prevEl: ".manufacturing_sec1 .leftarrow",
      },
    })

    const testinglist = new Swiper(".testinglist-swiper", {
      slidesPerView: 3,
      spaceBetween: 100,
      autoplay: {
        delay: 3000,
      },
      disableOnInteraction: true,
      loop: true,
      navigation: {
        nextEl: ".manufacturing_sec2 .next",
        prevEl: ".manufacturing_sec2 .prev",
      },
      breakpoints: {
        1024: {
          spaceBetween: 80,
        },
      },
    })

    const mbtestinglist = new Swiper(".mbtestinglist-swiper", {
      slidesPerView: 1,
      loop: true,
      navigation: {
        nextEl: ".manufacturing_sec2 .rightarrow",
        prevEl: ".manufacturing_sec2 .leftarrow",
      },
    })
  }, [])

  const onMouseEnterMaunFacturingClick = () => {
    var mySwiper = document.querySelector(".manufacturinglist-swiper").swiper
    mySwiper.autoplay.stop()
  }

  const onMouseExitMaunFacturingClick = () => {
    var mySwiper = document.querySelector(".manufacturinglist-swiper").swiper
    mySwiper.autoplay.start()
  }

  const onMouseEnterTestingClick = () => {
    var mySwiper = document.querySelector(".testinglist-swiper").swiper
    mySwiper.autoplay.stop()
  }

  const onMouseExitTestingClick = () => {
    var mySwiper = document.querySelector(".testinglist-swiper").swiper
    mySwiper.autoplay.start()
  }

  return (
    <>
      <BlueHeader
        title="MANUFACTURING & TESTING FACILITIES"
        img={deskheaderbg}
      />
      <section className="manufacturing_sec1 wow fadeInUp">
        {width >= 1280 ? (
          <div className="dotContainer1">
            <div className="d-f f-c">
              <div
                className="wow fadeIn"
                data-wow-duration="2s"
                data-wow-delay=".5s"
              >
                <img src={dots} className="fluid dots" />
              </div>
            </div>
          </div>
        ) : null}

        <div className="title">MANUFACTURING FACILITIES</div>
        <div className="desc">
          Having state-of-the-art manufacturing facilities at all our locations
          helps us in our quest to produce world-class products for our overseas
          and domestic customers both in the Automotive and
          Non-Automotive sectors. Over the last four decades, we have always
          collaborated with the leaders in the market to have the best machinery
          and equipment that are in-sync with the latest technology.
        </div>

        {width >= 1024 ? (
          <Row className="manufacturingrow">
            <Col className="firstcol" md={2}>
              <div className="c-p prev">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="45"
                  height="45"
                  viewBox="0 0 79 79"
                >
                  <g
                    id="Group_1087"
                    data-name="Group 1087"
                    transform="translate(-247 -1435)"
                  >
                    <g
                      id="Group_1088"
                      data-name="Group 1088"
                      transform="translate(247 1435)"
                    >
                      <circle
                        id="Ellipse_138"
                        data-name="Ellipse 138"
                        cx="39.5"
                        cy="39.5"
                        r="39.5"
                        fill="#f3f3f3"
                      />
                      <g
                        id="Icon_feather-arrow-right"
                        data-name="Icon feather-arrow-right"
                        transform="translate(30.967 23.168)"
                      >
                        <path
                          id="Path_2143"
                          data-name="Path 2143"
                          d="M34.809,7.5,18,24.309,34.809,41.118"
                          transform="translate(-18 -7.5)"
                          fill="none"
                          stroke="#000"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="3"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
            </Col>
            <Col md={20} className="secondCol">
              <div
                className="manufacturinglist-swiper swiper-container"
                onMouseLeave={onMouseExitMaunFacturingClick}
                onMouseEnter={onMouseEnterMaunFacturingClick}
              >
                <div className="swiper-wrapper">
                  <div className="swiper-slide">
                    <div className="d-f f-c j-c a-c">
                      <div>
                        <img
                          src={InnerStrandingMachinesdesktop}
                          className="fluid"
                        />
                      </div>
                      <div className="slidertitle">
                        Inner Stranding Machines
                      </div>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="d-f f-c j-c a-c">
                      <div>
                        <img
                          src={AutomaticSpringCoilersAutoWindersdesktop}
                          className="fluid"
                        />
                      </div>
                      <div className="slidertitle">
                        Automatic Spring Coilers
                      </div>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="d-f f-c j-c a-c">
                      <div>
                        <img
                          src={VerticalDieCastingMachinesdesktop}
                          className="fluid"
                        />
                      </div>
                      <div className="slidertitle">
                        Vertical Die Casting Machines
                      </div>
                    </div>
                  </div>

                  <div className="swiper-slide">
                    <div className="d-f f-c j-c a-c">
                      <div>
                        <img
                          src={HydroPneumaticImpactPressesdesktop}
                          className="fluid"
                        />
                      </div>
                      <div className="slidertitle">
                        Hydro Pneumatic Impact Presses
                      </div>
                    </div>
                  </div>

                  <div className="swiper-slide">
                    <div className="d-f f-c j-c a-c">
                      <div>
                        <img
                          src={SwagingMachinesforRailwaycablesdesktop}
                          className="fluid"
                        />
                      </div>
                      <div className="slidertitle">
                        Swaging Machine for Railway Cables
                      </div>
                    </div>
                  </div>

                  <div className="swiper-slide">
                    <div className="d-f f-c j-c a-c">
                      <div>
                        <img
                          src={PLCControlledInjectionMoldingdesktop}
                          className="fluid"
                        />
                      </div>
                      <div className="slidertitle">
                        PLC Controlled Injection Molding
                      </div>
                    </div>
                  </div>

                  <div className="swiper-slide">
                    <div className="d-f f-c j-c a-c">
                      <div>
                        <img
                          src={conveyorassemblylinesdesktop}
                          className="fluid"
                        />
                      </div>
                      <div className="slidertitle">
                        Conveyorised Assembly Lines
                      </div>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="d-f f-c j-c a-c">
                      <div>
                        <img src={machineshopmanufacturing} className="fluid" />
                      </div>
                      <div className="slidertitle">Machine Shop</div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col className="firstcol" md={2}>
              <div className="c-p next us-none">
                <svg
                  id="Group_1090"
                  data-name="Group 1090"
                  xmlns="http://www.w3.org/2000/svg"
                  width="45"
                  height="45"
                  viewBox="0 0 79 79"
                >
                  <g id="Group_1089" data-name="Group 1088">
                    <circle
                      id="Ellipse_139"
                      data-name="Ellipse 138"
                      cx="39.5"
                      cy="39.5"
                      r="39.5"
                      fill="#f3f3f3"
                    />
                    <g
                      id="Icon_feather-arrow-right"
                      data-name="Icon feather-arrow-right"
                      transform="translate(31.224 23.168)"
                    >
                      <path
                        id="Path_2144"
                        data-name="Path 2143"
                        d="M18,7.5,34.809,24.309,18,41.118"
                        transform="translate(-18 -7.5)"
                        fill="none"
                        stroke="#000"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="3"
                      />
                    </g>
                  </g>
                </svg>
              </div>
            </Col>
          </Row>
        ) : (
          <div className="manufacturingrow ">
            <div className="mbmanufacturinglist-swiper swiper-container">
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <div className="d-f f-c j-c a-c">
                    <div>
                      <img
                        src={mbInnerStrandingMachinesdesktop}
                        className="fluid"
                      />
                    </div>
                    <div className="slidertitle">Inner Stranding Machines</div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="d-f f-c j-c a-c">
                    <div>
                      <img
                        src={mbAutomaticSpringCoilersAutoWindersdesktop}
                        className="fluid"
                      />
                    </div>
                    <div className="slidertitle">Automatic Spring Coilers</div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="d-f f-c j-c a-c">
                    <div>
                      <img
                        src={mbVerticalDieCastingMachinesdesktop}
                        className="fluid"
                      />
                    </div>
                    <div className="slidertitle">
                      Vertical Die Casting Machines
                    </div>
                  </div>
                </div>

                <div className="swiper-slide">
                  <div className="d-f f-c j-c a-c">
                    <div>
                      <img
                        src={mbHydroPneumaticImpactPressesdesktop}
                        className="fluid"
                      />
                    </div>
                    <div className="slidertitle">
                      Hydro Pneumatic Impact Presses
                    </div>
                  </div>
                </div>

                <div className="swiper-slide">
                  <div className="d-f f-c j-c a-c">
                    <div>
                      <img
                        src={mbSwagingMachinesforRailwaycablesdesktop}
                        className="fluid"
                      />
                    </div>
                    <div className="slidertitle">
                      Swaging Machine for Railway Cables
                    </div>
                  </div>
                </div>

                <div className="swiper-slide">
                  <div className="d-f f-c j-c a-c">
                    <div>
                      <img
                        src={mbPLCControlledInjectionMoldingdesktop}
                        className="fluid"
                      />
                    </div>
                    <div className="slidertitle">
                      PLC Controlled Injection Molding
                    </div>
                  </div>
                </div>

                <div className="swiper-slide">
                  <div className="d-f f-c j-c a-c">
                    <div>
                      <img
                        src={mbconveyorassemblylinesdesktop}
                        className="fluid"
                      />
                    </div>
                    <div className="slidertitle">
                      Conveyorised Assembly Lines
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="d-f f-c j-c a-c">
                    <div>
                      <img src={mbmachineshopmanufacturing} className="fluid" />
                    </div>
                    <div className="slidertitle">Machine Shop</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-f j-c a-c">
              <div className="arrow d-f as-c">
                <div className="leftarrow">
                  <img src={leftarrowmb} className="fluid c-p"></img>{" "}
                </div>
                <div className="rightarrow">
                  <img src={rightarrowmb} className="fluid c-p"></img>{" "}
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
      <section className="manufacturing_sec2 wow fadeInUp">
        <div className="p-r">
          <div className="title">TESTING FACILITIES</div>
          <div className="desc">
            Acey products undergo stringent quality tests at all stages of
            manufacturing in our independent testing facilities. Our trained
            team of engineers persistently conduct checks and inspection to
            achieve zero-defect supplies for 100% client satisfaction.<br></br>
            <br></br>Aided by world-class testing equipment, tests like the
            compression of outer cables, elongation of inner cables, load/stroke
            efficiency, endurance and life cycle tests under different
            temperatures and conditions are conducted to drive excellence
            through all our products.
          </div>

          {width >= 1280 ? (
            <div className="dotContainer2">
              <div className="d-f f-c">
                <div
                  className="wow fadeIn"
                  data-wow-duration="2s"
                  data-wow-delay=".5s"
                >
                  <img src={dots} className="fluid dots" />
                </div>
              </div>
            </div>
          ) : null}
        </div>
        {width >= 1024 ? (
          <Row className="manufacturingrow">
            <Col className="firstcol" md={2}>
              <div className="c-p prev us-none">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="45"
                  height="45"
                  viewBox="0 0 79 79"
                >
                  <g
                    id="Group_1087"
                    data-name="Group 1087"
                    transform="translate(-247 -1435)"
                  >
                    <g
                      id="Group_1088"
                      data-name="Group 1088"
                      transform="translate(247 1435)"
                    >
                      <circle
                        id="Ellipse_140"
                        data-name="Ellipse 138"
                        cx="39.5"
                        cy="39.5"
                        r="39.5"
                        fill="#f3f3f3"
                      />
                      <g
                        id="Icon_feather-arrow-right"
                        data-name="Icon feather-arrow-right"
                        transform="translate(30.967 23.168)"
                      >
                        <path
                          id="Path_2145"
                          data-name="Path 2143"
                          d="M34.809,7.5,18,24.309,34.809,41.118"
                          transform="translate(-18 -7.5)"
                          fill="none"
                          stroke="#000"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="3"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
            </Col>
            <Col md={20} className="secondCol">
              <div
                className="testinglist-swiper swiper-container"
                onMouseLeave={onMouseExitTestingClick}
                onMouseEnter={onMouseEnterTestingClick}
              >
                <div className="swiper-wrapper">
                  <div className="swiper-slide">
                    <div className="d-f f-c j-c a-c">
                      <div>
                        <img src={BucklingTestingRig} className="fluid" />
                      </div>
                      <div className="slidertitle">
                        Buckling Load Testing Rig
                      </div>
                    </div>
                  </div>

                  <div className="swiper-slide">
                    <div className="d-f f-c j-c a-c">
                      <div>
                        <img src={CrushingLoad} className="fluid" />
                      </div>
                      <div className="slidertitle">
                        Crushing Load Testing Rig
                      </div>
                    </div>
                  </div>

                  <div className="swiper-slide">
                    <div className="d-f f-c j-c a-c">
                      <div>
                        <img
                          src={RockingDurabilityTestingRig}
                          className="fluid"
                        />
                      </div>
                      <div className="slidertitle">
                        Rocking Durability Testing Rig
                      </div>
                    </div>
                  </div>

                  <div className="swiper-slide">
                    <div className="d-f f-c j-c a-c">
                      <div>
                        <img
                          src={DigitalUniversalTensileTestingMachine}
                          className="fluid"
                        />
                      </div>
                      <div className="slidertitle">
                        Digital Universal Tensile<br></br>Testing Machine
                      </div>
                    </div>
                  </div>

                  <div className="swiper-slide">
                    <div className="d-f f-c j-c a-c">
                      <div>
                        <img src={DustTestChamber} className="fluid" />
                      </div>
                      <div className="slidertitle">Dust Test Chamber</div>
                    </div>
                  </div>

                  <div className="swiper-slide">
                    <div className="d-f f-c j-c a-c">
                      <div>
                        <img
                          src={DipstickAssemblyEnduranceTestingRig}
                          className="fluid"
                        />
                      </div>
                      <div className="slidertitle">
                        Dipstick Assembly Endurance Testing Rig.
                      </div>
                    </div>
                  </div>

                  <div className="swiper-slide">
                    <div className="d-f f-c j-c a-c">
                      <div>
                        <img src={LoadEfficiencyTestingRig} className="fluid" />
                      </div>
                      <div className="slidertitle">
                        Load/Stroke Efficiency Testing Rig
                      </div>
                    </div>
                  </div>

                  <div className="swiper-slide">
                    <div className="d-f f-c j-c a-c">
                      <div>
                        <img
                          src={MultipleEnduranceTestingRig}
                          className="fluid"
                        />
                      </div>
                      <div className="slidertitle">
                        Multiple Endurance Testing Rig.
                      </div>
                    </div>
                  </div>

                  <div className="swiper-slide">
                    <div className="d-f f-c j-c a-c">
                      <div>
                        <img src={ProofLoadTestingFixtures} className="fluid" />
                      </div>
                      <div className="slidertitle">
                        Proof Load Testing Fixtures
                      </div>
                    </div>
                  </div>

                  <div className="swiper-slide">
                    <div className="d-f f-c j-c a-c">
                      <div>
                        <img src={SaltSprayTestingChamber} className="fluid" />
                      </div>
                      <div className="slidertitle">
                        Salt Spray Testing Chamber
                      </div>
                    </div>
                  </div>

                  <div className="swiper-slide">
                    <div className="d-f f-c j-c a-c">
                      <div>
                        <img
                          src={EnvironmentalHotColdChamber}
                          className="fluid"
                        />
                      </div>
                      <div className="slidertitle">
                        Environmental Hot & Cold Chamber
                      </div>
                    </div>
                  </div>

                  <div className="swiper-slide">
                    <div className="d-f f-c j-c a-c">
                      <div>
                        <img src={EnduranceTestingRig} className="fluid" />
                      </div>
                      <div className="slidertitle">
                        Endurance Testing Rig as per AIS 067F
                      </div>
                    </div>
                  </div>

                  <div className="swiper-slide">
                    <div className="d-f f-c j-c a-c">
                      <div>
                        <img
                          src={SpeedometerInnerTorsionalLoadTesting}
                          className="fluid"
                        />
                      </div>
                      <div className="slidertitle">
                        Speedometer Inner Torsional Load Testing
                      </div>
                    </div>
                  </div>

                  <div className="swiper-slide">
                    <div className="d-f f-c j-c a-c">
                      <div>
                        <img
                          src={SpeedometerCableEnduranceTesting}
                          className="fluid"
                        />
                      </div>
                      <div className="slidertitle">
                        Speedometer Cable Endurance Testing Rig IS4278
                      </div>
                    </div>
                  </div>

                  <div className="swiper-slide">
                    <div className="d-f f-c j-c a-c">
                      <div>
                        <img src={TestingLab} className="fluid" />
                      </div>
                      <div className="slidertitle">Testing Lab</div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col className="firstcol" md={2}>
              <div className="c-p next us-none">
                <svg
                  id="Group_1090"
                  data-name="Group 1090"
                  xmlns="http://www.w3.org/2000/svg"
                  width="45"
                  height="45"
                  viewBox="0 0 79 79"
                >
                  <g id="Group_1089" data-name="Group 1088">
                    <circle
                      id="Ellipse_141"
                      data-name="Ellipse 138"
                      cx="39.5"
                      cy="39.5"
                      r="39.5"
                      fill="#f3f3f3"
                    />
                    <g
                      id="Icon_feather-arrow-right"
                      data-name="Icon feather-arrow-right"
                      transform="translate(31.224 23.168)"
                    >
                      <path
                        id="Path_2146"
                        data-name="Path 2143"
                        d="M18,7.5,34.809,24.309,18,41.118"
                        transform="translate(-18 -7.5)"
                        fill="none"
                        stroke="#000"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="3"
                      />
                    </g>
                  </g>
                </svg>
              </div>
            </Col>
          </Row>
        ) : (
          <div className="manufacturingrow ">
            <div className="mbtestinglist-swiper swiper-container">
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <div className="d-f f-c j-c a-c">
                    <div>
                      <img src={mbBucklingTestingRig} className="fluid" />
                    </div>
                    <div className="slidertitle">
                      Crushing/Buckling Load Testing Rig.
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="d-f f-c j-c a-c">
                    <div>
                      <img src={mbCrushingLoad} className="fluid" />
                    </div>
                    <div className="slidertitle">
                      Crushing Load Testing Rig.
                    </div>
                  </div>
                </div>

                <div className="swiper-slide">
                  <div className="d-f f-c j-c a-c">
                    <div>
                      <img
                        src={mbRockingDurabilityTestingRig}
                        className="fluid"
                      />
                    </div>
                    <div className="slidertitle">
                      Rocking Durability Testing Rig.
                    </div>
                  </div>
                </div>

                <div className="swiper-slide">
                  <div className="d-f f-c j-c a-c">
                    <div>
                      <img
                        src={mbDigitalUniversalTensileTestingMachine}
                        className="fluid"
                      />
                    </div>
                    <div className="slidertitle">
                      Digital Universal Tensile<br></br>Testing Machine
                    </div>
                  </div>
                </div>

                <div className="swiper-slide">
                  <div className="d-f f-c j-c a-c">
                    <div>
                      <img src={mbDustTestChamber} className="fluid" />
                    </div>
                    <div className="slidertitle">Dust Test Chamber</div>
                  </div>
                </div>

                <div className="swiper-slide">
                  <div className="d-f f-c j-c a-c">
                    <div>
                      <img
                        src={mbDipstickAssemblyEnduranceTestingRig}
                        className="fluid"
                      />
                    </div>
                    <div className="slidertitle">
                      Dipstick Assembly Endurance Testing Rig.
                    </div>
                  </div>
                </div>

                <div className="swiper-slide">
                  <div className="d-f f-c j-c a-c">
                    <div>
                      <img src={mbLoadEfficiencyTestingRig} className="fluid" />
                    </div>
                    <div className="slidertitle">
                      Load/Stroke Efficiency Testing Rig.
                    </div>
                  </div>
                </div>

                <div className="swiper-slide">
                  <div className="d-f f-c j-c a-c">
                    <div>
                      <img
                        src={mbMultipleEnduranceTestingRig}
                        className="fluid"
                      />
                    </div>
                    <div className="slidertitle">
                      Multiple Endurance Testing Rig.
                    </div>
                  </div>
                </div>

                <div className="swiper-slide">
                  <div className="d-f f-c j-c a-c">
                    <div>
                      <img src={mbProofLoadTestingFixtures} className="fluid" />
                    </div>
                    <div className="slidertitle">
                      Proof Load Testing Fixtures
                    </div>
                  </div>
                </div>

                <div className="swiper-slide">
                  <div className="d-f f-c j-c a-c">
                    <div>
                      <img src={mbSaltSprayTestingChamber} className="fluid" />
                    </div>
                    <div className="slidertitle">
                      Salt Spray Testing Chamber
                    </div>
                  </div>
                </div>

                <div className="swiper-slide">
                  <div className="d-f f-c j-c a-c">
                    <div>
                      <img
                        src={mbEnvironmentalHotColdChamber}
                        className="fluid"
                      />
                    </div>
                    <div className="slidertitle">
                      Environmental Hot & Cold Chamber
                    </div>
                  </div>
                </div>

                <div className="swiper-slide">
                  <div className="d-f f-c j-c a-c">
                    <div>
                      <img src={mbEnduranceTestingRig} className="fluid" />
                    </div>
                    <div className="slidertitle">
                      Endurance Testing Rig as per AIS 067F
                    </div>
                  </div>
                </div>

                <div className="swiper-slide">
                  <div className="d-f f-c j-c a-c">
                    <div>
                      <img
                        src={mbSpeedometerInnerTorsionalLoadTesting}
                        className="fluid"
                      />
                    </div>
                    <div className="slidertitle">
                      Speedometer Inner Torsional Load Testing
                    </div>
                  </div>
                </div>

                <div className="swiper-slide">
                  <div className="d-f f-c j-c a-c">
                    <div>
                      <img
                        src={mbSpeedometerCableEnduranceTesting}
                        className="fluid"
                      />
                    </div>
                    <div className="slidertitle">
                      Speedometer Cable Endurance Testing Rig IS4278
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="d-f f-c j-c a-c">
                    <div>
                      <img src={mbtestingLab} className="fluid" />
                    </div>
                    <div className="slidertitle">Testing Lab</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-f j-c a-c">
              <div className="arrow d-f as-c">
                <div className="leftarrow">
                  <img src={leftarrowmb} className="fluid c-p"></img>{" "}
                </div>
                <div className="rightarrow">
                  <img src={rightarrowmb} className="fluid c-p"></img>{" "}
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  )
}

export default Manufacturing
