import React, { useState, useEffect } from "react"
import BlueHeader from "../../component/BlueHeader/BlueHeader"
import WOW from "wowjs"

import rightarrowActive from "../../images/automotive/rightarrowActive.png"

import rightArrowListItem from "../../images/automotive/rightArrowListItem.png"

import { Link } from "react-router-dom"
import dots from "../../images/homepage/dots.png"

import "./style.less"

import { Row, Col } from "antd"
import useWindowDimensions from "../../useWindowDimensions"
import CsrItem from "../../component/CsrComponent/CsrItem"

// const csrItemArr = [
//     {img:rowimg1,smImg:tree,title:"TREE PLANTATION DRIVE",desc:"We have planted over 350 trees in the surrounding areas of all plants over the past three years. We have a mission to plant a minimum of 100 trees every year.",reverse:false},
//     {img:rowimg2,smImg:plastic,title:"NO TO PLASTICS",desc:"Use of Recyclable if and where required is the way forward at Acey. For us, it’s a big NO to single use plastics.",reverse:true},
//     {img:rowimg3,smImg:education,title:"EDUCATION",desc:"Providing free education to the underprivileged in surrounding schools, books and stationary to the needy, donating clothes, shoes and school bags where required is an annual activity at Acey.",reverse:false},
//     {img:rowimg4,smImg:community,title:"Community",desc:"Acey has allocated a community hall for social gatherings, seminars and cultural programmes so as to encourage communal activities and educational seminars.",reverse:true},

// ]

const AutomotiveListItem = (props) => {
  const { height, width } = useWindowDimensions()

  //console.log(props.link)
  return (
    <Link onClick={props.onClick} to={props.link}>
      <div className="d-f a-c jc-sb listItemContainer">
        <div
          className="listItemTitle"
          style={props.selected ? { color: "#ED1C24" } : null}
        >
          {props.title}
        </div>
        <div>
          <img
            src={props.selected ? rightarrowActive : rightArrowListItem}
            className="fluid"
          />{" "}
        </div>
      </div>
    </Link>
  )
}

export default AutomotiveListItem
