import React, { useState, useEffect } from 'react'
import BlueHeader from '../../component/BlueHeader/BlueHeader'
import WOW from 'wowjs'

import deskheaderbg from '../../images/headerbg.png'
import casting from '../../images/ourstory/casting.jpg'
import innermanufacturing from '../../images/ourstory/innermanufacturing.jpg'
import machineshop from '../../images/ourstory/machineshop.jpg'
import outermanufacturing from '../../images/ourstory/outermanufacturing.jpg'
import plasticmoulding from '../../images/ourstory/plasticmoulding.jpg'

import firstImage from '../../images/ourstory/1.jpg'
import secondImage from '../../images/ourstory/2.jpg'
import thirdImage from '../../images/ourstory/3.jpg'
import fourthImage from '../../images/ourstory/4.jpg'
import fifthImage from '../../images/ourstory/5.jpg'
import sixImage from '../../images/ourstory/6.jpg'
import sevenImage from '../../images/ourstory/7.jpg'

import plant1 from '../../images/ourstory/plant1.jpg'
import plant2 from '../../images/ourstory/plant2.jpg'
import plant3 from '../../images/ourstory/plant3.jpg'
import leftarrow from '../../images/ourstory/leftarrow.png'
import rightarrow from '../../images/ourstory/rightarrow.png'
import colorcircle from '../../images/ourstory/colorcircle.png'
import mbcolorcircle from '../../images/ourstory/mbcolorcircle.png'

import leader1 from '../../images/ourstory/leader1.png'
import leader2 from '../../images/ourstory/leader2.png'
import award1 from '../../images/ourstory/award1.png'

import mbrightarrow from '../../images/ourstory/mbrightarrow.png'
import mbleftarrow from '../../images/ourstory/mbleftarrow.png'
import dots from '../../images/homepage/dots.png'

import BajajAutoLTD2011 from '../../images/ourstory/BajajAutoLTD2011.png'
import BestSupplierDANASPICER2020 from '../../images/ourstory/BestSupplierDANASPICER2020.png'
import CII2010 from '../../images/ourstory/CII2010.png'
import TataMotors2019 from '../../images/ourstory/TataMotors2019.png'
import CII2002 from '../../images/ourstory/CII2002.png'
import BAJAJAUTOLTD2014 from '../../images/ourstory/BAJAJAUTOLTD2014.png'
import QualityAwardsSliverBajajAutoLTD2014 from '../../images/ourstory/QualityAwardsSliverBajajAutoLTD2014.png'
import QualityAwardsSliverBajajAutoLTD2018 from '../../images/ourstory/QualityAwardsSliverBajajAutoLTD2018.png'
import QualityAwardsSliverBajajAutoLTD from '../../images/ourstory/QualityAwardsSliverBajajAutoLTD.png'
import QualityGateTataMotors2019 from '../../images/ourstory/QualityGateTataMotors2019.png'
import QuickResponsetoQualityConcernsDANASPICER from '../../images/ourstory/QuickResponsetoQualityConcernsDANASPICER.png'
import TotalTeamInvolvementTatamotors2019 from '../../images/ourstory/TotalTeamInvolvementTatamotors2019.png'
import BalTpm from '../../images/ourstory/BALTPMAWARD2022.png'

//clienta

import adityamayankchoksi from '../../images/ourstory/adityamayankchoksi.png'
import mayankarvindchoksi from '../../images/ourstory/mayankarvindchoksi.png'
import parthmayankchoksi from '../../images/ourstory/parthmayankchoksi.png'
//import pradeepchabra from '../../images/ourstory/pradeepchabra.png'
import sandeepjhaveri from '../../images/ourstory/sandeepjhaveri.png'
import swapnilchogle from '../../images/ourstory/swapnilchogle.png'

import './style.less'
import Swiper, { Navigation, Pagination, Controller, Autoplay } from 'swiper'
import 'swiper/swiper-bundle.css'

import { Row, Col } from 'antd'
import useWindowDimensions from '../../useWindowDimensions'

import './style.css'

Swiper.use([Navigation, Pagination, Controller, Autoplay])

const Ourstory = (props) => {
	const { height, width } = useWindowDimensions()

	useEffect(() => {
		window.scrollTo(0, 0)

		document.title = 'Acey - Our Story'

		var timelineContents = new Swiper('.ourstory_sec4 .timeline-contents', {
			navigation: {
				nextEl: '.timeline-button-next',
				prevEl: '.timeline-button-prev',
			},

			grabCursor: true,
			spaceBetween: 10,
			autoHeight: true,
		})
		var timelineDates = new Swiper('.ourstory_sec4 .timeline-dates', {
			spaceBetween: 0,
			centeredSlides: true,
			// slidesPerView: 'auto',
			touchRatio: 0.2,
			disableOnInteraction: true,
			autoplay: {
				delay: 3000,
			},
			slideToClickedSlide: true,

			breakpoints: {
				1920: {
					slidesPerView: 5,
				},
				1024: {
					slidesPerView: 5,
				},
				1023: {
					slidesPerView: 1,
				},
			},
		})

		// timelineDates.params.control = timelineContents;
		// console.log(timelineDates)

		timelineDates.controller.control = timelineContents
		timelineContents.controller.control = timelineDates

		const ourstoryswiper = new Swiper('.ourstory-swiper', {
			slidesPerView: 1,
			spaceBetween: 0,
			loop: true,
			disableOnInteraction: true,
			autoplay: {
				delay: 3000,
			},
			pagination: {
				el: '.ourstory_sec1 .swiper-pagination',
				clickable: true,
			},
		})

		const plantsswiper = new Swiper('.plants-swiper', {
			slidesPerView: 1,
			spaceBetween: 0,
			loop: true,
			navigation: {
				nextEl: '.ourstory_sec3 .next',
				prevEl: '.ourstory_sec3 .prev',
			},
		})

		const mbplantsswiper = new Swiper('.mbplants-swiper', {
			slidesPerView: 1,
			spaceBetween: 0,
			loop: true,
			pagination: {
				el: '.ourstory_sec3 .swiper-pagination',
				clickable: true,
			},
		})

		const leaderlist = new Swiper('.leaderlist-swiper', {
			slidesPerView: 3,
			spaceBetween: 169,
			loop: true,
			disableOnInteraction: true,
			autoplay: {
				delay: 3000,
			},
			navigation: {
				nextEl: '.ourstory_sec5 .next',
				prevEl: '.ourstory_sec5 .prev',
			},
			breakpoints: {
				1025: {
					spaceBetween: 169,
				},
				1024: {
					spaceBetween: 100,
				},
			},
		})

		const mbleaderlist = new Swiper('.mbleaderlist-swiper', {
			slidesPerView: 1,
			spaceBetween: 0,
			loop: true,

			pagination: {
				el: '.ourstory_sec5 .swiper-pagination',
				clickable: true,
			},
		})

		const awardswiper = new Swiper('.award-swiper', {
			// spaceBetween:150,
			loop: true,
			disableOnInteraction: true,
			autoplay: {
				delay: 3000,
			},
			navigation: {
				nextEl: '.ourstory_sec6 .next',
				prevEl: '.ourstory_sec6 .prev',
			},
			breakpoints: {
				1920: {
					slidesPerView: 3,
					spaceBetween: 150,
				},
				1520: {
					slidesPerView: 3,
					spaceBetween: 100,
				},
				1440: {
					slidesPerView: 3,
					spaceBetween: 80,
				},
				1024: {
					slidesPerView: 3,
					spaceBetween: 50,
				},
				1023: {
					slidesPerView: 1,
				},
			},
		})

		new WOW.WOW({
			live: false,
		}).init()
	}, [])

	const onMouseEnterOurStoryClick = () => {
		var mySwiper = document.querySelector('.ourstory-swiper').swiper
		mySwiper.autoplay.stop()
	}

	const onMouseExitOurStoryClick = () => {
		var mySwiper = document.querySelector('.ourstory-swiper').swiper
		mySwiper.autoplay.start()
	}

	const onMouseEnterTimelineClick = () => {
		var mySwiper = document.querySelector('.ourstory_sec4 .timeline-dates')
			.swiper
		mySwiper.autoplay.stop()
	}

	const onMouseExitTimelineClick = () => {
		var mySwiper = document.querySelector('.ourstory_sec4 .timeline-dates')
			.swiper
		mySwiper.autoplay.start()
	}

	const onMouseEnterleaderShipClick = () => {
		try {
			var mySwiper = document.querySelector('.leaderlist-swiper').swiper
			mySwiper.autoplay.stop()
		} catch (error) { }
	}

	const onMouseExitleaderShipClick = () => {
		try {
			var mySwiper = document.querySelector('.leaderlist-swiper').swiper
			mySwiper.autoplay.start()
		} catch (error) { }
	}

	const onMouseEnterAwardsClick = () => {
		try {
			var mySwiper = document.querySelector('.award-swiper').swiper
			mySwiper.autoplay.stop()
		} catch (error) { }
	}

	const onMouseExitAwardsClick = () => {
		try {
			var mySwiper = document.querySelector('.award-swiper').swiper
			mySwiper.autoplay.start()
		} catch (error) { }
	}

	return (
		<>
			<BlueHeader title='our story' img={deskheaderbg} />
			<section className='ourstory_sec1 wow fadeIn'>
				{width > 1280 ? (
					<div className='dotContainer1'>
						<div className='d-f f-c'>
							<div
								className='wow fadeIn'
								data-wow-duration='2s'
								data-wow-delay='.5s'
							>
								<img src={dots} className='fluid dots' />
							</div>
						</div>
					</div>
				) : null}
				<Row>
					<Col lg={15} className='firstCol'>
						<div className='title'>
							Established in <span>1980</span>,
						</div>
						<div className='desc'>
							ACEY Engineering Pvt. Ltd. has successfully made a mark as one of
							India's leading manufacturer and supplier of Control Cables to
							major Original Equipment Manufacturers (OEM’s) in the automobile
							industry. We also cater to other non-automotive sectors like Earth
							Moving Equipment, Railways, Marine, Electrical, Agriculture etc.
							Started as a machine shop by visionary leaders Mr. Arvind C.
							Choksi and Mr. Mayank A. Choksi, Acey began manufacturing Control
							Cables in 1982. From there on, it has been a growth story, year on
							year, primarily because we have considered quality and
							technological innovation as our topmost priority. We are one of
							the only cable manufacturer in India to have all cable
							manufacturing facilities like outer making, machine shop, inner
							manufacturing, plastic moulding, casting etc. in-house. The last
							four decades have been a journey in the pursuit of excellence so
							that we can deliver what is committed to our clients, on time, all
							the time.
						</div>
					</Col>
					<Col lg={9} className='secondCol'>
						<>
							<div
								className='ourstory-swiper swiper-container'
								onMouseLeave={onMouseExitOurStoryClick}
								onMouseEnter={onMouseEnterOurStoryClick}
							>
								<div className='swiper-wrapper'>
									<div className='swiper-slide'>
										<img className='fluid' src={firstImage} />
										{/* <div className="swipertitle"> CASTING</div> */}
									</div>
									<div className='swiper-slide'>
										<img className='fluid' src={secondImage} />
										{/* <div className="swipertitle"> Plastic moulding</div> */}
									</div>
									<div className='swiper-slide'>
										<img className='fluid' src={thirdImage} />
										{/* <div className="swipertitle"> Machine shop</div> */}
									</div>
									<div className='swiper-slide'>
										<img className='fluid' src={fourthImage} />
										{/* <div className="swipertitle"> Inner manufacturing</div> */}
									</div>
									<div className='swiper-slide'>
										<img className='fluid' src={fifthImage} />
										{/* <div className="swipertitle"> Outer manufacturing</div> */}
									</div>
									<div className='swiper-slide'>
										<img className='fluid' src={sixImage} />
										{/* <div className="swipertitle"> Outer manufacturing</div> */}
									</div>
									<div className='swiper-slide'>
										<img className='fluid' src={sevenImage} />
										{/* <div className="swipertitle"> Outer manufacturing</div> */}
									</div>
								</div>
							</div>
							<div className='d-f j-c'>
								<div className='swiper-pagination'></div>
							</div>
						</>
					</Col>
				</Row>
			</section>
			<section className='ourstory_sec2 wow fadeIn'>
				<div className='desc'>
					{width >= 1024 ? (
						<>
							We take pride in being the single largest source of Control Cables
							to <span>Bajaj Auto Ltd.</span> for their three-wheelers and{' '}
							<span>Tata Motors</span> for their commercial vehicles. With a
							solid base in Bilimora, South Gujarat, we have expanded Acey’s
							portfolio to deliver products and services globally, with an
							installed capacity of 25 to 30 million cables per annum.
						</>
					) : (
						<>
							{' '}
							We take pride in being the single largest source of Control Cables
							to <span>Bajaj Auto Ltd.</span> for their three-wheelers and{' '}
							<span>Tata Motors</span> for their commercial vehicles.<br></br>
							<br></br> With a solid base in Bilimora, South Gujarat, we have
							expanded Acey’s portfolio to deliver products and services
							globally, with an installed capacity of 25 to 30 million cables
							per annum.
						</>
					)}
				</div>
			</section>
			<section className='ourstory_sec3 wow fadeIn'>
				{width >= 1280 ? (
					<div className='dotContainer2'>
						<div className='d-f f-c'>
							<div
								className='wow fadeIn'
								data-wow-duration='2s'
								data-wow-delay='.5s'
							>
								<img src={dots} className='fluid dots' />
							</div>
						</div>
					</div>
				) : null}
				<div className='title'>Acey has three state-of-the-art plants</div>
				{width >= 1024 ? (
					<Row>
						<Col className='firstcol' md={2}>
							<div className='c-p prev'>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='45'
									height='45'
									viewBox='0 0 79 79'
								>
									<g
										id='Group_1087'
										data-name='Group 1087'
										transform='translate(-247 -1435)'
									>
										<g
											id='Group_1088'
											data-name='Group 1088'
											transform='translate(247 1435)'
										>
											<circle
												id='Ellipse_140'
												data-name='Ellipse 138'
												cx='39.5'
												cy='39.5'
												r='39.5'
												fill='#f3f3f3'
											/>
											<g
												id='Icon_feather-arrow-right'
												data-name='Icon feather-arrow-right'
												transform='translate(30.967 23.168)'
											>
												<path
													id='Path_2145'
													data-name='Path 2143'
													d='M34.809,7.5,18,24.309,34.809,41.118'
													transform='translate(-18 -7.5)'
													fill='none'
													stroke='#000'
													stroke-linecap='round'
													stroke-linejoin='round'
													stroke-width='3'
												/>
											</g>
										</g>
									</g>
								</svg>
							</div>
						</Col>
						<Col md={20} className='secondCol'>
							<div className='plants-swiper swiper-container'>
								<div className='swiper-wrapper'>
									<div className='swiper-slide'>
										<div className='p-r'>
											<div>
												<img src={plant1} className='fluid' />
											</div>
											<div className='swipertitleContainer'>
												<div className='d-f a-c'>
													<div>
														<img src={colorcircle} className='fluid' />
													</div>
													<div className='swipertitle'>
														Bilimora, South Gujarat, India, which is spread over{' '}
														<span>6300 sq. metres of land</span>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div className='swiper-slide'>
										<div className='p-r'>
											<div>
												<img src={plant2} className='fluid' />
											</div>
											<div className='swipertitleContainer'>
												<div className='d-f a-c'>
													<div>
														<img src={colorcircle} className='fluid' />
													</div>
													<div className='swipertitle'>
														Pantnagar, Uttarakhand, India, which is spread over{' '}
														<span>7000 sq. metres of land</span>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div className='swiper-slide'>
										<div className='p-r'>
											<div>
												<img src={plant3} className='fluid' />
											</div>
											<div className='swipertitleContainer'>
												<div className='d-f a-c'>
													<div>
														<img src={colorcircle} className='fluid' />
													</div>
													<div className='swipertitle'>
														Aurangabad, Maharashtra, India, which is spread over{' '}
														<span>5200 sq. metres of land</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</Col>
						<Col md={2} className='firstcol'>
							<div className='c-p next'>
								<svg
									id='Group_1090'
									data-name='Group 1090'
									xmlns='http://www.w3.org/2000/svg'
									width='45'
									height='45'
									viewBox='0 0 79 79'
								>
									<g id='Group_1089' data-name='Group 1088'>
										<circle
											id='Ellipse_141'
											data-name='Ellipse 138'
											cx='39.5'
											cy='39.5'
											r='39.5'
											fill='#f3f3f3'
										/>
										<g
											id='Icon_feather-arrow-right'
											data-name='Icon feather-arrow-right'
											transform='translate(31.224 23.168)'
										>
											<path
												id='Path_2146'
												data-name='Path 2143'
												d='M18,7.5,34.809,24.309,18,41.118'
												transform='translate(-18 -7.5)'
												fill='none'
												stroke='#000'
												stroke-linecap='round'
												stroke-linejoin='round'
												stroke-width='3'
											/>
										</g>
									</g>
								</svg>
							</div>
						</Col>
					</Row>
				) : (
					<div className='mbplants-swiper swiper-container'>
						<div className='swiper-wrapper'>
							<div className='swiper-slide'>
								<div className='d-f f-c'>
									<div>
										<img src={plant1} className='fluid' />
									</div>
									<div className='mbplantsswiper d-f '>
										<div>
											<img src={mbcolorcircle} className='' />
										</div>
										<div className='mbswipertitle'>
											Bilimora, South Gujarat, India, which is spread over{' '}
											<span>6300 sq. metres of land</span>
										</div>
									</div>
								</div>
							</div>

							<div className='swiper-slide'>
								<div className='d-f f-c'>
									<div>
										<img src={plant2} className='fluid' />
									</div>
									<div className='mbplantsswiper d-f '>
										<div>
											<img src={mbcolorcircle} className='' />
										</div>
										<div className='mbswipertitle'>
											Pantnagar, Uttarakhand, India, which is spread over{' '}
											<span>7000 sq. metres of land</span>
										</div>
									</div>
								</div>
							</div>

							<div className='swiper-slide'>
								<div className='d-f f-c'>
									<div>
										<img src={plant3} className='fluid' />
									</div>
									<div className='mbplantsswiper d-f '>
										<div>
											<img src={mbcolorcircle} className='' />
										</div>
										<div className='mbswipertitle'>
											Aurangabad, Maharashtra, India, which is spread over{' '}
											<span>5200 sq. metres of land</span>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className='swiper-pagination'></div>
					</div>
				)}
			</section>

			<section className='ourstory_sec4 wow fadeIn'>
				<div className='title'>MILESTONES</div>
				<div className='desc'>
					Let us take you through the journey and achievements of Acey down the
					years.
				</div>

				<div className='h-f w-f timelineContainer'>
					<div
						className='timeline'
						onMouseLeave={onMouseExitTimelineClick}
						onMouseEnter={onMouseEnterTimelineClick}
					>
						<div className='swiper-container timeline-contents'>
							<div className='swiper-wrapper'>
								<div className='swiper-slide'>
									<div className='timelineTitle'>
										Started production of Push Pull Cables for Earthmoving
										Equipment & Marine applications.
									</div>
								</div>

								<div className='swiper-slide'>
									<div className='timelineTitle'>
										Set up a third manufacturing unit at Aurangabad in
										Maharashtra.
									</div>
								</div>

								<div className='swiper-slide'>
									<div className='timelineTitle'>
										Set up a manufacturing facility in Tata Vendor Park at
										Pantnagar in Uttarakhand.
									</div>
								</div>
								<div className='swiper-slide'>
									<div className='timelineTitle'>
										Developed cables for Fans & ventured into manufacture of
										Control Cables for Electrical Appliances.
									</div>
								</div>
								<div className='swiper-slide'>
									<div className='timelineTitle'>
										Started exports to Sri Lanka.
									</div>
								</div>
								<div className='swiper-slide'>
									<div className='timelineTitle'>
										Started production of Flexible Shafts and Speedometer
										Cables.
									</div>
								</div>
								<div className='swiper-slide'>
									<div className='timelineTitle'>
										Shifted base in Bilimora, Gujarat, and set-up a plant with
										in-house facilities like inner manufacturing, outer making
										and die casting.
									</div>
								</div>
								<div className='swiper-slide'>
									<div className='timelineTitle'>
										Ventured into manufacturing of Control Cables and started
										supplies to Bajaj Auto Ltd, who remains our single largest
										customer till date.
									</div>
								</div>
								<div className='swiper-slide'>
									<div className='timelineTitle'>
										The journey began as a small machine shop by visionary
										leaders Mr A. C. Choksi and Mr M. A. Choksi.
									</div>
								</div>
							</div>
						</div>

						<div className='middleLine'></div>

						<div className='swiper-container timeline-dates'>
							<div className='swiper-wrapper'>
								<div className='swiper-slide'>
									<div>2020</div>
								</div>
								<div className='swiper-slide'>
									<div>2016</div>
								</div>
								<div className='swiper-slide'>
									<div>2007</div>
								</div>
								<div className='swiper-slide'>
									<div>1999</div>
								</div>
								<div className='swiper-slide'>
									<div>1998</div>
								</div>
								<div className='swiper-slide'>
									<div>1995</div>
								</div>
								<div className='swiper-slide'>
									<div>1987</div>
								</div>
								<div className='swiper-slide'>
									<div>1982</div>
								</div>
								<div className='swiper-slide'>
									<div>1980</div>
								</div>
							</div>
						</div>

						<div className='timeline-buttons-container'>
							<div className='timeline-button-next'>
								<div className='next'>The Beginning</div>
							</div>
							<div className='timeline-button-prev'>
								<div className='prev'>The Present</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className='ourstory_sec5 wow fadeIn'>
				{width >= 1280 ? (
					<div className='dotContainer3'>
						<div className='d-f f-c'>
							<div
								className='wow fadeIn'
								data-wow-duration='2s'
								data-wow-delay='.5s'
							>
								<img src={dots} className='fluid dots' />
							</div>
						</div>
					</div>
				) : null}
				<div className='title'>leadership team</div>
				<div className='desc'>
					Meet the senior leadership team, who sets the strategic direction of
					our company and fuels the drive of the workforce to meet the needs of
					our valued customers.
				</div>

				{width >= 1024 ? (
					<Row className='leaderrow'>
						<Col className='firstcol' md={2}>
							<div className='c-p prev'>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='45'
									height='45'
									viewBox='0 0 79 79'
								>
									<g
										id='Group_1087'
										data-name='Group 1087'
										transform='translate(-247 -1435)'
									>
										<g
											id='Group_1088'
											data-name='Group 1088'
											transform='translate(247 1435)'
										>
											<circle
												id='Ellipse_150'
												data-name='Ellipse 138'
												cx='39.5'
												cy='39.5'
												r='39.5'
												fill='#f3f3f3'
											/>
											<g
												id='Icon_feather-arrow-right'
												data-name='Icon feather-arrow-right'
												transform='translate(30.967 23.168)'
											>
												<path
													id='Path_2150'
													data-name='Path 2143'
													d='M34.809,7.5,18,24.309,34.809,41.118'
													transform='translate(-18 -7.5)'
													fill='none'
													stroke='#000'
													stroke-linecap='round'
													stroke-linejoin='round'
													stroke-width='3'
												/>
											</g>
										</g>
									</g>
								</svg>
							</div>
						</Col>
						<Col md={20} className='secondCol'>
							<div
								className='leaderlist-swiper swiper-container'
								onMouseLeave={onMouseExitleaderShipClick}
								onMouseEnter={onMouseEnterleaderShipClick}
							>
								<div className='swiper-wrapper'>
									<div className='swiper-slide'>
										<div className='d-f f-c j-c a-c'>
											<div>
												<img src={mayankarvindchoksi} className='fluid' />
											</div>
											<div className='slidertitle'>Mayank Arvind Choksi</div>
											<div className='sliderdesc'>
												Managing Director and Chairman
											</div>
										</div>
									</div>
									<div className='swiper-slide'>
										<div className='d-f f-c j-c a-c'>
											<div>
												<img src={parthmayankchoksi} className='fluid' />
											</div>
											<div className='slidertitle'>PARTH MAYANK CHOKSI</div>
											<div className='sliderdesc'>Executive Director</div>
										</div>
									</div>
									<div className='swiper-slide'>
										<div className='d-f f-c j-c a-c'>
											<div>
												<img src={adityamayankchoksi} className='fluid' />
											</div>
											<div className='slidertitle'>Aditya Mayank choksi</div>
											<div className='sliderdesc'>Executive Director</div>
										</div>
									</div>

									<div className='swiper-slide'>
										<div className='d-f f-c j-c a-c'>
											<div>
												<img src={sandeepjhaveri} className='fluid' />
											</div>
											<div className='slidertitle'>Sandeep Jhaveri</div>
											<div className='sliderdesc'>VP Commercial and Admin</div>
										</div>
									</div>



									<div className='swiper-slide'>
										<div className='d-f f-c j-c a-c'>
											<div>
												<img src={swapnilchogle} className='fluid' />
											</div>
											<div className='slidertitle'>Swapnil Chogle</div>
											<div className='sliderdesc'>Chief Operating Officer</div>
										</div>
									</div>
								</div>
							</div>
						</Col>
						<Col className='firstcol' md={2}>
							<div className='c-p next'>
								<svg
									id='Group_1090'
									data-name='Group 1090'
									xmlns='http://www.w3.org/2000/svg'
									width='45'
									height='45'
									viewBox='0 0 79 79'
								>
									<g id='Group_1089' data-name='Group 1088'>
										<circle
											id='Ellipse_151'
											data-name='Ellipse 138'
											cx='39.5'
											cy='39.5'
											r='39.5'
											fill='#f3f3f3'
										/>
										<g
											id='Icon_feather-arrow-right'
											data-name='Icon feather-arrow-right'
											transform='translate(31.224 23.168)'
										>
											<path
												id='Path_2151'
												data-name='Path 2143'
												d='M18,7.5,34.809,24.309,18,41.118'
												transform='translate(-18 -7.5)'
												fill='none'
												stroke='#000'
												stroke-linecap='round'
												stroke-linejoin='round'
												stroke-width='3'
											/>
										</g>
									</g>
								</svg>
							</div>
						</Col>
					</Row>
				) : (
					<>
						<div className='mbleaderContainer'>
							<div className='mbleaderlist-swiper swiper-container'>
								<div className='swiper-wrapper'>
									<div className='swiper-slide'>
										<div className='d-f f-c j-c a-c'>
											<div>
												<img src={mayankarvindchoksi} className='fluid' />
											</div>
											<div className='mbslidertitle'>Mayank Arvind Choksi</div>
											<div className='mbsliderdesc'>
												Managing Director and Chairman
											</div>
										</div>
									</div>

									<div className='swiper-slide'>
										<div className='d-f f-c j-c a-c'>
											<div>
												<img src={parthmayankchoksi} className='fluid' />
											</div>
											<div className='mbslidertitle'>Parth Mayank Choksi</div>
											<div className='mbsliderdesc'>Executive Director</div>
										</div>
									</div>

									<div className='swiper-slide'>
										<div className='d-f f-c j-c a-c'>
											<div>
												<img src={adityamayankchoksi} className='fluid' />
											</div>
											<div className='mbslidertitle'>Aditya Mayank Choksi</div>
											<div className='mbsliderdesc'>Executive Director</div>
										</div>
									</div>

									<div className='swiper-slide'>
										<div className='d-f f-c j-c a-c'>
											<div>
												<img src={sandeepjhaveri} className='fluid' />
											</div>
											<div className='mbslidertitle'>Sandeep Jhaveri</div>
											<div className='mbsliderdesc'>
												VP Commercial and Admin
											</div>
										</div>
									</div>



									<div className='swiper-slide'>
										<div className='d-f f-c j-c a-c'>
											<div>
												<img src={swapnilchogle} className='fluid' />
											</div>
											<div className='mbslidertitle'>Swapnil Chogle</div>
											<div className='mbsliderdesc'>Chief Operating Officer</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className='swiper-pagination'></div>
					</>
				)}
			</section>

			<section className='ourstory_sec6 wow fadeIn'>
				<div className='title'>awards and certificates</div>
				<div className='desc'>
					Apart from acknowledging success, awards also recognize hard work,
					effort & excellence. Shown are some of the awards & certificates
					highlighting our commitment towards Quality & Services.
				</div>

				<Row className='awardsrow'>
					<Col className='firstcol mbleft' md={3} xs={3}>
						<div className='c-p prev'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='45'
								height='45'
								viewBox='0 0 79 79'
							>
								<g
									id='Group_1087'
									data-name='Group 1087'
									transform='translate(-247 -1435)'
								>
									<g
										id='Group_1088'
										data-name='Group 1088'
										transform='translate(247 1435)'
									>
										<circle
											id='Ellipse_160'
											data-name='Ellipse 138'
											cx='39.5'
											cy='39.5'
											r='39.5'
											fill='#f3f3f3'
										/>
										<g
											id='Icon_feather-arrow-right'
											data-name='Icon feather-arrow-right'
											transform='translate(30.967 23.168)'
										>
											<path
												id='Path_2160'
												data-name='Path 2143'
												d='M34.809,7.5,18,24.309,34.809,41.118'
												transform='translate(-18 -7.5)'
												fill='none'
												stroke='#000'
												stroke-linecap='round'
												stroke-linejoin='round'
												stroke-width='3'
											/>
										</g>
									</g>
								</g>
							</svg>
						</div>
						{/* <div>
                  <img src={width>=1024?leftarrow:mbleftarrow} className="fluid prev"/>
                </div> */}
					</Col>
					<Col md={18} xs={18} className='secondCol'>
						<div
							className='award-swiper swiper-container'
							onMouseLeave={onMouseExitAwardsClick}
							onMouseEnter={onMouseEnterAwardsClick}
						>
							<div className='swiper-wrapper'>
								<div className='swiper-slide'>
									<div className='d-f f-c j-c a-c'>
										<div>
											<img src={BalTpm} className='fluid' />
										</div>
										<div className='slidertitle'>Bal TPM AWARD</div>
										<div className='sliderdesc'> BAJAJ , 2022</div>
									</div>
								</div>
								<div className='swiper-slide'>
									<div className='d-f f-c j-c a-c'>
										<div>
											<img src={BestSupplierDANASPICER2020} className='fluid' />
										</div>
										<div className='slidertitle'>Best Supplier</div>
										<div className='sliderdesc'> Dana Spicer, 2020</div>
									</div>
								</div>
								<div className='swiper-slide'>
									<div className='d-f f-c j-c a-c'>
										<div>
											<img
												src={TotalTeamInvolvementTatamotors2019}
												className='fluid'
											/>
										</div>
										<div className='slidertitle'>Total Team Involvement</div>
										<div className='sliderdesc'> Tata Motors, 2019</div>
									</div>
								</div>

								<div className='swiper-slide'>
									<div className='d-f f-c j-c a-c'>
										<div>
											<img src={QualityGateTataMotors2019} className='fluid' />
										</div>
										<div className='slidertitle'>Quality Gate (Runner Up)</div>
										<div className='sliderdesc'> Tata Motors, 2019</div>
									</div>
								</div>

								<div className='swiper-slide'>
									<div className='d-f f-c j-c a-c'>
										<div>
											<img src={TataMotors2019} className='fluid' />
										</div>
										<div className='slidertitle'>Maximum No. Of Suggetions</div>
										<div className='sliderdesc'> Tata Motors, 2019</div>
									</div>
								</div>

								<div className='swiper-slide'>
									<div className='d-f f-c j-c a-c'>
										<div>
											<img
												src={QualityAwardsSliverBajajAutoLTD2018}
												className='fluid'
											/>
										</div>
										<div className='slidertitle'>Quality Award, Silver</div>
										<div className='sliderdesc'> Bajaj Auto Ltd, 2018</div>
									</div>
								</div>

								<div className='swiper-slide'>
									<div className='d-f f-c j-c a-c'>
										<div>
											<img
												src={QuickResponsetoQualityConcernsDANASPICER}
												className='fluid'
											/>
										</div>
										<div className='slidertitle'>
											Quick Response to Quality Concerns
										</div>
										<div className='sliderdesc'> Dana Spicer, 2017</div>
									</div>
								</div>

								<div className='swiper-slide'>
									<div className='d-f f-c j-c a-c'>
										<div>
											<img
												src={QualityAwardsSliverBajajAutoLTD2014}
												className='fluid'
											/>
										</div>
										<div className='slidertitle'>Quality Award, Silver</div>
										<div className='sliderdesc'> Bajaj Auto Ltd, 2014</div>
									</div>
								</div>

								<div className='swiper-slide'>
									<div className='d-f f-c j-c a-c'>
										<div>
											<img src={BAJAJAUTOLTD2014} className='fluid' />
										</div>
										<div className='slidertitle'>Quality Award, Gold</div>
										<div className='sliderdesc'> Bajaj Auto Ltd, 2014</div>
									</div>
								</div>

								<div className='swiper-slide'>
									<div className='d-f f-c j-c a-c'>
										<div>
											<img src={BajajAutoLTD2011} className='fluid' />
										</div>
										<div className='slidertitle'>Vision 2015 Award</div>
										<div className='sliderdesc'> Bajaj Auto Ltd, 2011</div>
									</div>
								</div>

								<div className='swiper-slide'>
									<div className='d-f f-c j-c a-c'>
										<div>
											<img src={CII2010} className='fluid' />
										</div>
										<div className='slidertitle'>
											Recognition for Association of Auto Enterprises
										</div>
										<div className='sliderdesc'> Cii, 2010</div>
									</div>
								</div>

								<div className='swiper-slide'>
									<div className='d-f f-c j-c a-c'>
										<div>
											<img
												src={QualityAwardsSliverBajajAutoLTD}
												className='fluid'
											/>
										</div>
										<div className='slidertitle'>Quality Award, Silver</div>
										<div className='sliderdesc'> Bajaj Auto Ltd, 2007</div>
									</div>
								</div>

								<div className='swiper-slide'>
									<div className='d-f f-c j-c a-c'>
										<div>
											<img src={CII2002} className='fluid' />
										</div>
										<div className='slidertitle'>
											Outstanding Customer Orientation
										</div>
										<div className='sliderdesc'> Cii, 2002</div>
									</div>
								</div>
							</div>
						</div>
					</Col>
					<Col className='firstcol mbRight' md={3} xs={3}>
						<div className='c-p next'>
							<svg
								id='Group_1090'
								data-name='Group 1090'
								xmlns='http://www.w3.org/2000/svg'
								width='45'
								height='45'
								viewBox='0 0 79 79'
							>
								<g id='Group_1089' data-name='Group 1088'>
									<circle
										id='Ellipse_161'
										data-name='Ellipse 138'
										cx='39.5'
										cy='39.5'
										r='39.5'
										fill='#f3f3f3'
									/>
									<g
										id='Icon_feather-arrow-right'
										data-name='Icon feather-arrow-right'
										transform='translate(31.224 23.168)'
									>
										<path
											id='Path_2161'
											data-name='Path 2143'
											d='M18,7.5,34.809,24.309,18,41.118'
											transform='translate(-18 -7.5)'
											fill='none'
											stroke='#000'
											stroke-linecap='round'
											stroke-linejoin='round'
											stroke-width='3'
										/>
									</g>
								</g>
							</svg>
						</div>
						{/* <div>
                    <img src={width>=1024?rightarrow:mbrightarrow} className="fluid next"/>
                    </div> */}
					</Col>
				</Row>
			</section>
		</>
	)
}

export default Ourstory
