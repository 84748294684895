import React from "react"

import "./style.css"
const InputText = (props) => {
  return (
    <div class="user-input-wrp">
      <input
        type="text"
        maxLength={props.limit ? 100 : null}
        class="inputText"
        value={props.value}
        onChange={props.onChange}
        required
      />
      <span class="floating-label">{props.title}</span>
    </div>
  )
}

export default InputText
