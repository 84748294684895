import React, { useState } from "react"

import { Row, Col, Image } from "antd"
import logo from "../../images/header/logo.png"
import rightArrowMob from "../../images/header/rightArrowMob.png"
import downarrowfilled from "../../images/header/downarrowfilled.png"
import plusblack from "../../images/header/plusblack.png"
import minus from "../../images/header/minusblue.png"
import useWindowDimensions from "../../useWindowDimensions"
import { Link } from "react-router-dom"

import "./style.less"
const Header = () => {
  const { height, width } = useWindowDimensions()
  const [about, setAbout] = useState(false)
  const [product, setProduct] = useState(false)
  const [automotive, setAutomotive] = useState(false)
  const [nonautomotive, setNonAutomotive] = useState(false)
  const [infra, setInfra] = useState(false)
  const [add, setAdd] = useState(false)

  const aboutUsbtnclick = () => {
    setAbout(!about)

    setProduct(false)
    setAutomotive(false)
    setNonAutomotive(false)
    setInfra(false)
  }
  const productbtnclick = () => {
    //console.log(about)
    setProduct(!product)

    setAutomotive(false)
    setNonAutomotive(false)
    setInfra(false)
    setAbout(false)
  }
  const automotivebtnclick = () => {
    setAutomotive(!automotive)
  }
  const nonautomotivebtnclick = () => {
    setNonAutomotive(!nonautomotive)
  }
  const infrabtnclick = () => {
    // console.log(about)

    setInfra(!infra)

    setProduct(false)
    setAutomotive(false)
    setNonAutomotive(false)
    setAbout(false)
  }

  const closedrawer = () => {
    setAbout(false)
    setProduct(false)
    setAutomotive(false)
    setNonAutomotive(false)
    setInfra(false)
    document.getElementById("nav-links").classList.toggle("nav-active")
    document.getElementById("hambuger").classList.toggle("toggle")
    document.getElementById("root").classList.toggle("active")
  }

  const burgerClick = () => {
    document.getElementById("nav-links").classList.toggle("nav-active")
    document.getElementById("hambuger").classList.toggle("toggle")
    document.getElementById("root").classList.toggle("active")

    if (add !== true) {
      setAbout(false)
      setProduct(false)
      setAutomotive(false)
      setNonAutomotive(false)
      setInfra(false)
    }
    setAdd(!add)
  }

  return (
    <div className="header">
      <nav className="headerContainer">
        <div>
          <Link to="/">
            <img className="logo fluid" src={logo} />
          </Link>
        </div>

        <ul id="nav-links" className="nav-links">
          {width > 999 ? (
            <>
              <li>
                <a>
                  About us{" "}
                  <svg
                    className="downarrow marginleft"
                    xmlns="http://www.w3.org/2000/svg"
                    width="15.828"
                    height="8.914"
                    viewBox="0 0 15.828 8.914"
                  >
                    <path
                      id="Icon_feather-chevron-down"
                      data-name="Icon feather-chevron-down"
                      d="M9,13.5,15.5,20,22,13.5"
                      transform="translate(-7.586 -12.086)"
                      fill="none"
                      stroke="#000000"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    />
                  </svg>
                </a>
                <ul>
                  <li>
                    <Link className="automotivetext" to="/ourstory">
                      Our Story
                    </Link>
                  </li>
                  <li>
                    <Link to="/ourvalues">Our Values</Link>
                  </li>
                </ul>
              </li>

              <li>
                <a>
                  Products{" "}
                  <svg
                    className="downarrow marginleft"
                    xmlns="http://www.w3.org/2000/svg"
                    width="15.828"
                    height="8.914"
                    viewBox="0 0 15.828 8.914"
                  >
                    <path
                      id="Icon_feather-chevron-down"
                      data-name="Icon feather-chevron-down"
                      d="M9,13.5,15.5,20,22,13.5"
                      transform="translate(-7.586 -12.086)"
                      fill="none"
                      stroke="#000000"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    />
                  </svg>
                </a>
                <ul>
                  <Row>
                    <Col md={11} className="firstCol">
                      <div className="automotivetext">AUTOMOTIVE</div>
                      <li>
                        <Link to="/product/automotive/twowheelers">
                          2 - Wheelers
                        </Link>
                      </li>
                      <li>
                        <Link to="/product/automotive/threeWheelers">
                          3 - Wheelers
                        </Link>
                      </li>
                      <li>
                        <Link to="/product/automotive/passengerVehicles">
                          Passenger Vehicles
                        </Link>
                      </li>
                      <li>
                        <Link to="/product/automotive/lcvmcvhcv">
                          LCV/MCV/HCV
                        </Link>
                      </li>
                      <li>
                        <Link to="/product/automotive/bicycles">Bicycle</Link>
                      </li>
                      <li className="viewtextli">
                        {" "}
                        <Link to="/product/automotive">View All</Link>{" "}
                      </li>
                    </Col>
                    <Col md={13} className="secondCol">
                      <div className="automotivetext">NON-AUTOMOTIVE</div>
                      <li>
                        <Link to="/product/nonautomotive/tractors">
                          Tractors
                        </Link>
                      </li>
                      <li>
                        <Link to="/product/nonautomotive/railways">
                          Railways
                        </Link>
                      </li>
                      <li>
                        <Link to="/product/nonautomotive/earthmovingequipment">
                          Earth Moving Equipment
                        </Link>
                      </li>
                      <li>
                        <Link to="/product/nonautomotive/marineapplication">
                          Marine Applications
                        </Link>
                      </li>
                      <li>
                        <Link to="/product/nonautomotive/otherApplication">
                          Other Applications
                        </Link>
                      </li>
                      <li className="viewtextli">
                        {" "}
                        <Link to="/product/nonautomotive">View All</Link>{" "}
                      </li>
                    </Col>
                  </Row>
                </ul>
              </li>
              <li>
                <a>
                  Infrastructure{" "}
                  <svg
                    className="downarrow marginleft"
                    xmlns="http://www.w3.org/2000/svg"
                    width="15.828"
                    height="8.914"
                    viewBox="0 0 15.828 8.914"
                  >
                    <path
                      id="Icon_feather-chevron-down"
                      data-name="Icon feather-chevron-down"
                      d="M9,13.5,15.5,20,22,13.5"
                      transform="translate(-7.586 -12.086)"
                      fill="none"
                      stroke="#000000"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    />
                  </svg>
                </a>
                <ul>
                  <li>
                    <Link to="/manufacturingFacilities">
                      Manufacturing & Testing Facilities
                    </Link>
                  </li>
                  <li>
                    <Link to="/developentandqualitycontrol">
                      Development & Quality Control
                    </Link>
                  </li>
                  {/* <li><Link>Quality control</Link></li> */}
                </ul>
              </li>
              <li>
                <Link to="/clients">Clients</Link>
              </li>
              <li>
                <Link to="/csr">CSR</Link>
              </li>
              <li>
                <Link to="/media">Media</Link>
              </li>
              <li>
                <Link to="/contactus">Contact us</Link>
              </li>
              
            </>
          ) : (
            <div className="headerMobile">
              <li className="mobileli">
                <div className="d-f jc-sb" onClick={aboutUsbtnclick}>
                  {" "}
                  <div
                    className="aboutUsMobile"
                    style={about ? { color: "#034EA2" } : null}
                  >
                    {" "}
                    ABOUT US
                  </div>
                  <div>
                    <img
                      className="fluid"
                      src={about ? downarrowfilled : rightArrowMob}
                    />
                  </div>
                </div>
                {about === true ? (
                  <>
                    <ul className="mobileul2">
                      <li>
                        <Link onClick={closedrawer} to="/ourstory">
                          Our Story
                        </Link>
                      </li>
                      <li>
                        <Link onClick={closedrawer} to="/ourvalues">
                          Our Values
                        </Link>
                      </li>
                    </ul>
                  </>
                ) : null}
              </li>
              <li className="mobileli">
                <div className="d-f jc-sb" onClick={productbtnclick}>
                  {" "}
                  <div className="aboutUsMobile"> PRODUCTS</div>{" "}
                  <div>
                    <img
                      className="fluid"
                      src={product === true ? downarrowfilled : rightArrowMob}
                    />
                  </div>
                </div>
                {product ? (
                  <>
                    <div
                      className="d-f jc-sb ai-b "
                      onClick={automotivebtnclick}
                    >
                      {" "}
                      <div className="automotiveproductTextMob">
                        AUTOMOTIVE PRODUCTS
                      </div>{" "}
                      <div>
                        <img
                          className="fluid"
                          src={automotive ? minus : plusblack}
                        />
                      </div>{" "}
                    </div>
                    {automotive ? (
                      <ul>
                        <li>
                          <Link
                            onClick={closedrawer}
                            to="/product/automotive/twowheelers"
                          >
                            2 - Wheelers
                          </Link>
                        </li>
                        <li>
                          <Link
                            onClick={closedrawer}
                            to="/product/automotive/threeWheelers"
                          >
                            3 - Wheelers
                          </Link>
                        </li>
                        <li>
                          <Link
                            onClick={closedrawer}
                            to="/product/automotive/passengerVehicles"
                          >
                            Passenger Vehicles
                          </Link>
                        </li>
                        <li>
                          <Link
                            onClick={closedrawer}
                            to="/product/automotive/lcvmcvhcv"
                          >
                            LCV/MCV/HCV
                          </Link>
                        </li>
                        <li>
                          <Link
                            onClick={closedrawer}
                            to="/product/automotive/bicycles"
                          >
                            Bicycle
                          </Link>
                        </li>
                        <li className="viewtextli">
                          {" "}
                          <Link onClick={closedrawer} to="/product/automotive">
                            View All
                          </Link>{" "}
                        </li>
                      </ul>
                    ) : null}
                    <div
                      className="d-f jc-sb ai-b "
                      onClick={nonautomotivebtnclick}
                    >
                      {" "}
                      <div className="automotiveproductTextMob">
                        NON-AUTOMOTIVE PRODUCTS
                      </div>{" "}
                      <div>
                        <img
                          className="fluid"
                          src={nonautomotive ? minus : plusblack}
                        />
                      </div>{" "}
                    </div>
                    {nonautomotive ? (
                      <ul>
                        <li>
                          <Link
                            onClick={closedrawer}
                            to="/product/nonautomotive/tractors"
                          >
                            Tractors
                          </Link>
                        </li>
                        <li>
                          <Link
                            onClick={closedrawer}
                            to="/product/nonautomotive/railways"
                          >
                            Railways
                          </Link>
                        </li>
                        <li>
                          <Link
                            onClick={closedrawer}
                            to="/product/nonautomotive/earthmovingequipment"
                          >
                            Earth Moving Equipment
                          </Link>
                        </li>
                        <li>
                          <Link
                            onClick={closedrawer}
                            to="/product/nonautomotive/marineapplication"
                          >
                            Marine Applications
                          </Link>
                        </li>
                        <li>
                          <Link
                            onClick={closedrawer}
                            to="/product/nonautomotive/otherApplication"
                          >
                            Other Applications
                          </Link>
                        </li>
                        <li className="viewtextli">
                          {" "}
                          <Link
                            onClick={closedrawer}
                            to="/product/nonautomotive"
                          >
                            View All
                          </Link>{" "}
                        </li>
                      </ul>
                    ) : null}
                  </>
                ) : null}
              </li>

              <li className="mobileli">
                <div className="d-f jc-sb" onClick={infrabtnclick}>
                  {" "}
                  <div
                    className="aboutUsMobile"
                    style={infra ? { color: "#034EA2" } : null}
                  >
                    {" "}
                    INFRASTRUCTURE
                  </div>
                  <div>
                    <img
                      className="fluid"
                      src={infra ? downarrowfilled : rightArrowMob}
                    />
                  </div>
                </div>
                {infra ? (
                  <>
                    <ul className="mobileul">
                      <li>
                        <Link
                          onClick={closedrawer}
                          to="/manufacturingFacilities"
                        >
                          Manufacturing & Testing Facilities
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={closedrawer}
                          to="/developentandqualitycontrol"
                        >
                          Development & Quality Control
                        </Link>
                      </li>
                      {/* <li><Link></Link></li> */}
                    </ul>
                  </>
                ) : null}
              </li>
              <li className="mobileli">
                <div className="d-f jc-sb">
                  {" "}
                  <Link
                    className="aboutUsMobile"
                    onClick={closedrawer}
                    to="/clients"
                  >
                    {" "}
                    CLIENTS
                  </Link>
                </div>
              </li>
              <li className="mobileli">
                <div className="d-f jc-sb">
                  {" "}
                  <Link
                    onClick={closedrawer}
                    to="/csr"
                    className="aboutUsMobile"
                  >
                    {" "}
                    CSR
                  </Link>
                </div>
              </li>
              <li className="mobileli">
                <div className="d-f jc-sb">
                  {" "}
                  <Link
                    onClick={closedrawer}
                    to="/media"
                    className="aboutUsMobile"
                  >
                    {" "}
                    Media
                  </Link>
                </div>
              </li>
              <li className="mobileli">
                <div className="d-f jc-sb">
                  {" "}
                  <Link
                    onClick={closedrawer}
                    to="/contactus"
                    className="aboutUsMobile"
                  >
                    {" "}
                    CONTACT US
                  </Link>
                </div>
              </li>
            </div>
          )}
        </ul>

        <div id="hambuger" className="burger" onClick={burgerClick}>
          <div className="line1"></div>
          <div className="line2"></div>
          <div className="line3"></div>
        </div>
      </nav>
    </div>
  )
}

export default Header

// Tractors
// Railways
// Earth Moving Equiment
// Marine Applications
// Other Applications
