import React, { useState, useEffect } from 'react'
import Dropdown from 'react-dropdown';
import BlueHeader from '../../component/BlueHeader/BlueHeader'
import WOW from 'wowjs'

import deskheaderbg from '../../images/headerbg.png'
import location from '../../images/contactus/location.png'
import user from '../../images/contactus/user.png'
import phone from '../../images/contactus/phone.png'
import mail from '../../images/contactus/mail.png'
import mbuser from '../../images/contactus/mbuser.png'
import mbphone from '../../images/contactus/mbphone.png'
import mbmail from '../../images/contactus/mbmail.png'
import mblocation from '../../images/contactus/mblocation.png'

import mbrightarrow from '../../images/ourstory/mbrightarrow.png'
import mbleftarrow from '../../images/ourstory/mbleftarrow.png'
import dots from '../../images/homepage/dots.png'
import Tab from '../../component/Tab/Tab'
import moment from 'moment'

import InputText from '../../component/InputText/InputText'
import Button from '../../component/Button/Button'

import urldata from '../../urldata'
import axios from 'axios'
import {
	ToastsContainer,
	ToastsStore,
	ToastsContainerPosition,
} from 'react-toasts'

import './style.less'
import Swiper, { Navigation, Pagination, Controller, Breakpoints } from 'swiper'
import 'swiper/swiper-bundle.css'

import { Row, Col } from 'antd'
import useWindowDimensions from '../../useWindowDimensions'
Swiper.use([Navigation, Pagination, Controller])

Swiper.use([Navigation, Pagination, Controller])

const ContactUs = (props) => {
	const { height, width } = useWindowDimensions()
	const [tab, setTab] = useState(0)
	const [name, setName] = useState('')
	const [nameerr, setNameerr] = useState('')
	const [phoneNumber, setPhoneNumber] = useState('')
	const [phoneNumbererr, setPhoneNumbererr] = useState('')
	const [emailId, setEmailId] = useState('')
	const [emailIderr, setEmailIderr] = useState('')
	const [message, setMessage] = useState('')
	const [messageerr, setMessageErr] = useState('')

	const [contactName, setContactName] = useState('')
	const [contactMail, setContactMail] = useState('')
	const [contactPhone, setContactPhone] = useState('')

	const [Careers, setCareers] = useState(false)
	const [Sales, setSales] = useState(false)
	const [Purchase, setPurchase] = useState(false)
	

	const handleChange = (event) => {

		if (event.name == "Careers")
			setCareers(event.target.checked);
		setSales(false)
	}

	useEffect(() => {
		window.scrollTo(0, 0)

		new WOW.WOW({
			live: false,
		}).init()

		document.title = 'Acey - Contact Us'

		const contactusswiper = new Swiper('.contact-swiper', {
			slidesPerView: 1,
			loop: true,
			spaceBetween: 0,
			autoHeight: true,
			pagination: {
				el: '.contactus_sec1 .swiper-pagination',
				clickable: true,
			},
		})
	}, [])

	const submitBtnClick = () => {
		var formIsValid = true

		if (!name) {
			formIsValid = false
			setNameerr('*Please enter your name.')
		} else {
			setNameerr('')
		}

		if (typeof name !== 'undefined') {
			if (!name.match(/^[a-zA-Z ]*$/)) {
				formIsValid = false
				setNameerr('*Please enter alphabet characters only.')
			}
		}

		if (typeof emailId !== 'undefined') {
			//regular expression for email validation
			var pattern = new RegExp(
				/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
			)
			if (!pattern.test(emailId)) {
				formIsValid = false
				setEmailIderr('*Please enter valid email-Id.')
			} else {
				setEmailIderr('')
			}
		}

		if (typeof phoneNumber !== 'undefined') {
			if (!phoneNumber.match(/^[0-9]{10}$/)) {
				formIsValid = false
				setPhoneNumbererr('*Please enter valid mobile no.')
			} else {
				setPhoneNumbererr('')
			}
		}

		if (formIsValid) {
			let data = {}
			data.name = name
			data.emailId = emailId
			data.phoneNumber = phoneNumber
			data.message = message
			data.date = moment().format('DD-MM-YYYY')
			data.time = moment().format('hh:mm a')
			console.log(checkBoxName); 
			if (tab === 0 && checkBoxName === "checkbox1") {
				data.type = 'careers'
			}

			if (tab === 1 && checkBoxName === "checkbox2") {
				data.type = 'sales'
			}

			if (tab === 2 && checkBoxName === "checkbox3") {
				data.type = 'supplier'
			}

			let url = new URL(urldata + 'user/enquiry')

			axios({
				method: 'post',
				url: url,
				data: data,
			})
				.then((response) => {
					setName('')
					setNameerr('')
					setEmailId('')
					setEmailIderr('')
					setPhoneNumber('')
					setPhoneNumbererr('')
					setMessage('')
					setMessageErr('')
					//console.log("login success  ")
					ToastsStore.success('Thank you for your query!')
				})
				.catch((error) => {
					// alert("Something Went Wrong")
					ToastsStore.error('Something Went Wrong')
				})
		}
	}

	useEffect(() => {
		switch (tab) {
			case 0:
				setContactName('Mr. Kiran Variya')
				setContactPhone('9016444155')
				setContactMail('hrbilimora@aceyindia.com')
				break
			case 1:
				setContactName('Ms. Brinda Garasia')
				setContactPhone('6354632440')
				setContactMail('sales@aceyindia.com')
				break

			case 2:
				setContactName('Mr. Dhaval Choksi')
				setContactPhone('99043319141')
				setContactMail('purchase@aceyindia.com')
				break

			default:
				break
		}
	}, [tab])
	const [checkboxState, setCheckboxState] = useState({
		checkbox1: false,
		checkbox2: false,
		checkbox3: false,
	});
	const [checkBoxName , setcheckBoxName] =useState('')

	const handleCheckboxChange = (checkboxName) => {
		setcheckBoxName(checkboxName)
		setCheckboxState((prevState) => ({
			...prevState,
			[checkboxName]: true,
		}));

		for (const key in checkboxState) {
			if (key !== checkboxName) {
				setCheckboxState((prevState) => ({
					...prevState,
					[key]: false,
				}));
			}
		}
	};

	return (
		<>
			<BlueHeader title='CONTACT US' img={deskheaderbg} />
			<section className='contactus_sec1 wow fadeIn'>
				{width >= 1280 ? (
					<div className='dotContainer1'>
						<div className='d-f f-c'>
							<div
								className='wow fadeIn'
								data-wow-duration='2s'
								data-wow-delay='.5s'
							>
								<img src={dots} className='fluid dots' />
							</div>
						</div>
					</div>
				) : null}
				{width >= 1024 ? (
					<Row>
						<Col md={6}>
							<div className='d-f f-c j-c a-c mr-auto locationContainer'>
								<div>
									<img src={location} className='fluid locationIcon' />
								</div>
								<div className='title'>MUMBAI OFFICE</div>
								<div className='desc'>
									Plot No.130<br></br>Kohli Villa<br></br>Near Shoppers <br></br> Stop,Andheri ( West ),<br></br> Mumbai-400058
								</div>
								
								<a
									className='c-p'
									href='https://www.google.com/maps/place/Acey+Engineering+pvt+ltd/@19.114411,72.8418725,15z/data=!4m2!3m1!1s0x0:0xe67c31aefe59a150?sa=X&ved=2ahUKEwjyh56HqfmBAxUQTWwGHV0-F24Q_BJ6BAhQEAA&ved=2ahUKEwjyh56HqfmBAxUQTWwGHV0-F24Q_BJ6BAhXEAM'
									target='_blank'
								>
									View on Google Maps
								</a>
							</div>
						</Col>
						<Col md={6}>
							<div className='d-f f-c j-c a-c mr-auto locationContainer'>
								<div>
									<img src={location} className='fluid locationIcon' />
								</div>
								<div className='title'>BILIMORA FACTORY</div>
								<div className='desc'>
									104-105,<br></br> G.I.D.C <br></br> Antalia, Bilimora -<br></br>396325, <br></br>Gujarat 
								</div>
								<a
									className='c-p'
									href='https://www.google.com/maps/place/ACEY+ENGINEERING+PVT+LTD/@20.7704064,72.9988202,17z/data=!4m12!1m6!3m5!1s0x3be0edd88954832d:0x3adf7cc791e3e3ed!2sACEY+ENGINEERING+PVT+LTD!8m2!3d20.7704014!4d73.0010089!3m4!1s0x3be0edd88954832d:0x3adf7cc791e3e3ed!8m2!3d20.7704014!4d73.0010089'
									target='_blank'
								>
									View on Google Maps
								</a>
							</div>
						</Col>

						<Col md={6}>
							<div className='d-f f-c j-c a-c m-auto locationContainer'>
								<div>
									<img src={location} className='fluid locationIcon' />
								</div>
								<div className='title'>PANTNAGAR FACTORY</div>
								<div className='desc'>
									Plot No. 24-B<br></br>Sector 11,I.I.E.<br></br> Tata Vendor Park<br></br>Pantnagar,
									Rudrapur - 263153<br></br>Uttrakhand
								</div>
								<a
									className='c-p'
									href='https://www.google.com/maps/place/Acey+Engineering+Pvt.+Ltd./@28.9875129,79.4119327,17z/data=!3m1!4b1!4m5!3m4!1s0x39a07fb5bfea8dc5:0xd1a8991042908d82!8m2!3d28.9875082!4d79.4141214'
									target='_blank'
								>
									View on Google Maps
								</a>
							</div>
						</Col>

						<Col md={6}>
							<div className='d-f f-c j-c a-c ml-auto locationContainer'>
								<div>
									<img src={location} className='fluid locationIcon' />
								</div>
								<div className='title'>AURANGABAD FACTORY</div>
								<div className='desc'>
									L-22<br></br> M.I.D.C <br></br>Waluj <br></br>Aurangabad - 431136 <br></br>Maharashtra
								</div>
								<a
									className='c-p'
									href='https://www.google.com/maps/place/Acey+Engineering+Pvt.+Ltd./@19.8399152,75.2340494,17z/data=!3m1!4b1!4m5!3m4!1s0x3bdb9b2efa665db1:0x7e6692d05f20c9d!8m2!3d19.8399102!4d75.2362381'
									target='_blank'
								>
									View on Google Maps
								</a>
							</div>
						</Col>
					</Row>
				) : (
					<>
						<div className='contact-swiper swiper-container '>
							<div className='swiper-wrapper'>

							<div className='swiper-slide'>
									<div className='d-f f-c j-c a-c locationContainer'>
										<div>
											<img src={mblocation} className='fluid' />
										</div>
										<div className='title'>MUMBAI OFFICE</div>
										<div className='desc'>
										Plot No.130, Kohli Villa<br></br>Near Shoppers <br></br> Stop,Andheri ( West ),<br></br> Mumbai-400058
										</div>
										<br></br>
										<a
											href='https://www.google.com/maps/place/ACEY+ENGINEERING+PVT+LTD/@20.7704064,72.9988202,17z/data=!4m12!1m6!3m5!1s0x3be0edd88954832d:0x3adf7cc791e3e3ed!2sACEY+ENGINEERING+PVT+LTD!8m2!3d20.7704014!4d73.0010089!3m4!1s0x3be0edd88954832d:0x3adf7cc791e3e3ed!8m2!3d20.7704014!4d73.0010089'
											target='_blank'
										>
											View on Google Maps
										</a>
									</div>
								</div>
								<div className='swiper-slide'>
									<div className='d-f f-c j-c a-c locationContainer'>
										<div>
											<img src={mblocation} className='fluid' />
										</div>
										<div className='title'>BILIMORA FACTORY</div>
										<div className='desc'>
											104-105, G.I.D.C.<br></br> Antalia, Bilimora - 396325
											<br></br>Gujarat
										</div>
										<br></br>
										<a
											href='https://www.google.com/maps/place/ACEY+ENGINEERING+PVT+LTD/@20.7704064,72.9988202,17z/data=!4m12!1m6!3m5!1s0x3be0edd88954832d:0x3adf7cc791e3e3ed!2sACEY+ENGINEERING+PVT+LTD!8m2!3d20.7704014!4d73.0010089!3m4!1s0x3be0edd88954832d:0x3adf7cc791e3e3ed!8m2!3d20.7704014!4d73.0010089'
											target='_blank'
										>
											View on Google Maps
										</a>
									</div>
								</div>

								<div className='swiper-slide'>
									<div className='d-f f-c j-c a-c locationContainer'>
										<div>
											<img src={mblocation} className='fluid' />
										</div>
										<div className='title'>PANTNAGAR FACTORY</div>
										<div className='desc'>
											Plot No. 24-B, Sector 11, I.I.E. Tata Vendor Park,
											Pantnagar, Rudrapur - 263153<br></br> Uttrakhand
										</div>
										<a
											href='https://www.google.com/maps/place/Acey+Engineering+Pvt.+Ltd./@28.9875129,79.4119327,17z/data=!3m1!4b1!4m5!3m4!1s0x39a07fb5bfea8dc5:0xd1a8991042908d82!8m2!3d28.9875082!4d79.4141214'
											target='_blank'
										>
											View on Google Maps
										</a>
									</div>
								</div>

								<div className='swiper-slide'>
									<div className='d-f f-c j-c a-c locationContainer'>
										<div>
											<img src={mblocation} className='fluid' />
										</div>
										<div className='title'>AURANGABAD FACTORY</div>
										<div className='desc'>
											L-22, M.I.D.C.<br></br>Waluj, Aurangabad - 431136
											Maharashtra
										</div>
										<br></br>
										<a
											href='https://www.google.com/maps/place/Acey+Engineering+Pvt.+Ltd./@19.8399152,75.2340494,17z/data=!3m1!4b1!4m5!3m4!1s0x3bdb9b2efa665db1:0x7e6692d05f20c9d!8m2!3d19.8399102!4d75.2362381'
											target='_blank'
										>
											View on Google Maps
										</a>
									</div>
								</div>
							</div>
						</div>
						<div className='pagination'>
							<div className='swiper-pagination'></div>
						</div>
					</>
				)}
			</section>

			<section className='contactus_sec2 wow fadeIn'>
				<div className='sectitle'>GET IN TOUCH with us</div>
				<div className='secdesc'>
					Please feel free to fill in the below form and send us your enquiries.
				</div>

				<div className='tabContainer'>
					<Tab
						onClick={() => setTab(0)}
						active={tab === 0 ? true : false}
						title='CAREERS'
					/>
					<Tab
						onClick={() => setTab(1)}
						active={tab === 1 ? true : false}
						title='SALES'
					/>
					<Tab
						onClick={() => setTab(2)}
						active={tab === 2 ? true : false}
						title='PURCHASE'
					/>
				</div>

				<div className='detailsContainer'>
					{width >= 1024 ? (
						<>
							<div className='d-f j-c a-c'>
								<div>
									<img src={user} className='fluid userIcon' />
								</div>
								<div className='name'>{contactName}</div>
							</div>
							<div className='d-f j-c a-c'>
								<div>
									<img src={phone} className='fluid userIcon' />
								</div>
								<a className='name' href={`tel:+91${contactPhone}`}>
									+91 {contactPhone}
								</a>
							</div>
							<div className='d-f j-c a-c'>
								<div>
									<img src={mail} className='fluid userIcon' />
								</div>

								<a className='name' href={`mailto:${contactMail}`}>
									{contactMail}
								</a>
							</div>
						</>
					) : (
						<>
							<div className='d-f a-c'>
								<div>
									{' '}
									<img src={mbuser} className='fluid' />
								</div>
								<div className='name'>{contactName}</div>
							</div>

							<div className='d-f a-c marginTopphone'>
								<div>
									<img src={mbphone} className='fluid' />
								</div>
								<a className='name' href={`tel:+91${contactPhone}`}>
									+91 {contactPhone}
								</a>
							</div>
							<div className='d-f  a-c marginTopphone'>
								<div>
									<img src={mbmail} className='fluid' />
								</div>

								<a className='name' href={`mailto:${contactMail}`}>
									{contactMail}
								</a>
							</div>
						</>
					)}
				</div>

				<Row>
					<Col md={11} xs={24} className='inputTextmarginTop'>
						<div>
							<InputText
								value={name}
								onChange={(e) => setName(e.target.value)}
								title='Name*'
							/>
							<div className='error'>{nameerr}</div>
						</div>
					</Col>
					<Col md={2} xs={0}></Col>
					<Col md={11} xs={24} className='inputTextmarginTop'>
						<div>
							<InputText
								value={phoneNumber}
								onChange={(e) => setPhoneNumber(e.target.value)}
								title='Phone Number*'
							/>
							<div className='error'>{phoneNumbererr}</div>
						</div>
					</Col>

					<Col md={24} xs={24} className='inputTextmarginTop'>
						<h4 className="secdesc2">
							Select The Department {" "}
						</h4>
						<div>
							<label>
								<input
									type="checkbox"
									name="checkbox1"
									checked={checkboxState.checkbox1}
									onChange={() => handleCheckboxChange('checkbox1')}
								/>
								{" "}
								CAREERS
							</label>
							<br />

							<label>
								<input
									type="checkbox"
									name="checkbox2"
									checked={checkboxState.checkbox2}
									onChange={() => handleCheckboxChange('checkbox2')}
								/>
								{" "}
								SALES
							</label>
							<br />

							<label>
								<input
									type="checkbox"
									name="checkbox3"
									checked={checkboxState.checkbox3}
									onChange={() => handleCheckboxChange('checkbox3')}
								/>
								{" "}
								PURCHASE
							</label>
						</div>
					</Col>


					<Col md={24} xs={24} className='inputTextmarginTop'>
						<div>
							<InputText
								value={emailId}
								onChange={(e) => setEmailId(e.target.value)}
								title='E-mail Id*'
							/>
							<div className='error'>{emailIderr}</div>
						</div>
					</Col>

					<Col md={24} xs={24} className='inputTextmarginTop'>
						<div>
							<InputText
								value={message}
								limit
								onChange={(e) => setMessage(e.target.value)}
								title='Message (upto 100 character )'
							/>
							<div className='error'>{messageerr}</div>
						</div>
					</Col>
				</Row>

				<div className='submitbtnContainer'>
					<Button onClick={submitBtnClick} title='SUBMIT' />
				</div>
				{width >= 1280 ? (
					<div className='dotContainer2'>
						<div className='d-f f-c'>
							<div
								className='wow fadeIn'
								data-wow-duration='2s'
								data-wow-delay='.5s'
							>
								<img src={dots} className='fluid dots' />
							</div>
						</div>
					</div>
				) : null}
			</section>
			<ToastsContainer
				position={ToastsContainerPosition.BOTTOM_RIGHT}
				store={ToastsStore}
			/>
		</>
	)
}

export default ContactUs
