import React, { useState, useEffect } from 'react'
import MaterialTable from 'material-table'
import axios from 'axios'
import urldata from '../../../urldata'

const CareerList = (props) => {
	const [loading, setLoading] = useState(true)
	const [list, setList] = useState([])

	useEffect(() => {
		setLoading(true)
		var data = {}
		data.type = 'sales'
		let url = new URL(urldata + 'admin/getEnquiryList')

		axios({
			method: 'post',
			url: url,
			data: data,
			withCredentials: true,
			crossdomain: true,
		})
			.then((response) => {
				// console.log("data success  ",response.data)
				// let temp={}
				// let tempData = temp.response.data

				try {
					let list = response.data
					list = list.reverse()
					setList(list)
					setLoading(false)
				} catch (error) {}
			})
			.catch((error) => {
				try {
					if (error.response.status === 401) {
						alert('user Not Loggedin')
						props.history.push('/admin')
					}
				} catch (error) {}
				props.history.push('/admin')
				localStorage.clear()
				setList([])
				setLoading(false)
			})
	}, [])
	if (loading) {
		return <h1 style={{ textAlign: 'start' }}>Loading...</h1>
	}
	return (
		<>
			<MaterialTable
				columns={[
					{
						title: 'Date',
						field: 'date',
					},
					{
						title: 'Time',
						field: 'time',
					},
					{
						title: 'Name',
						field: 'name',
					},
					{
						title: 'Email Id',
						field: 'emailId',
					},
					{
						title: 'Phone Number',
						field: 'phoneNumber',
					},
					{
						title: 'Message',
						field: 'message',
					},
				]}
				options={{
					exportButton: true,
					pageSize: 10,
					pageSizeOptions: [10],
				}}
				data={list}
				title='Sales Enquiry List'
			/>
		</>
	)
}

export default CareerList
