import React from "react"

import "./style.less"

const BlueHeader = (props) => {
  //console.log(props.img)
  return (
    <>
      <div className="mainpageHeader">
        <div className="title">{props.title}</div>
      </div>
    </>
  )
}

export default BlueHeader
