import React, { useEffect, useState } from "react"
import { Row, Col, Image, Tabs } from "antd"
import "./style.less"
import WOW from "wowjs"

import bike from "../../images/homepage/bike.png"
import auto from "../../images/homepage/auto.png"
import fourwheeler from "../../images/homepage/fourwheeler.png"
import tempo from "../../images/homepage/LCV1_edited.png";
import tractor from "../../images/homepage/tractor.png"
import jcb from "../../images/homepage/jcb.png"
import train from "../../images/homepage/train.png"
import logo from "../../images/footer/logo.png"

import bikethumb from "../../images/homepage/bikethumb.png"
import autothumb from "../../images/homepage/autothumb.png"
import fourwheelerthumb from "../../images/homepage/fourwheelerthumb.png"
import tempothumb from "../../images/homepage/tempothumb.png"
import tractorthumb from "../../images/homepage/tractorthumb.png"
import jcbthumb from "../../images/homepage/jcbthumb.png"
import trainthumb from "../../images/homepage/trainthumb.png"

import cablesperyear from "../../images/homepage/cablesperyear.png"
import factories from "../../images/homepage/factories.png"
import journey from "../../images/homepage/journey.png"
import varietyofcables from "../../images/homepage/varietyofcables.png"

import mbcablesperyear from "../../images/homepage/mbcablesperyear.png"
import mbfactories from "../../images/homepage/mbfactories.png"
import mbjourney from "../../images/homepage/mbjourney.png"
import mbvarietyofcables from "../../images/homepage/mbvarietyofcables.png"

import CountUp from "react-countup"
import VisibilitySensor from "react-visibility-sensor"

import Button from "../../component/Button/Button"
import CounterContainer from "../../component/CounterContainerItem/CounterContainer"

import "./style.css"
import Tab from "../../component/Tab/Tab"

import acc from "../../images/homepage/acc.png"
import brake from "../../images/homepage/brake.png"
import choke from "../../images/homepage/choke.png"
import clutch from "../../images/homepage/clutch.png"

import facilities1 from "../../images/homepage/facilities1.jpg"
import facilities2 from "../../images/homepage/facilities2.jpg"
import facilities3 from "../../images/homepage/facilities3.jpg"
import facilities4 from "../../images/homepage/facilities4.jpg"
import facilities5 from "../../images/homepage/facilities5.jpg"

import facilitiesmob1 from "../../images/homepage/facilitiesmob1.jpg"
import facilitiesmob2 from "../../images/homepage/facilitiesmob2.jpg"
import facilitiesmob3 from "../../images/homepage/facilitiesmob3.jpg"
import facilitiesmob4 from "../../images/homepage/facilitiesmob4.jpg"
import facilitiesmob5 from "../../images/homepage/facilitiesmob5.jpg"

import ProductItem from "../../component/ProductItem/ProductItem"
import FacilityHomeItem from "../../component/FacilityHomeItem/FacilityHomeItem"

import bajaj from "../../images/homepage/bajaj.png"
import fiat from "../../images/homepage/fiat.png"
import force from "../../images/homepage/force.png"
import jbm from "../../images/homepage/jbm.png"
import lt from "../../images/homepage/ltIcon.png"
import mahendra from "../../images/clients/MahindraRise.png"
import marco from "../../images/homepage/marco.png"
import tata from "../../images/homepage/tatas.png"

import Escorts from "../../images/clients/Escorts.png"

import Swiper, {
  Navigation,
  Pagination,
  Controller,
  Breakpoints,
  Autoplay,
} from "swiper"

import "swiper/swiper-bundle.css"
import useWindowDimensions from "../../useWindowDimensions"

import mbquote from "../../images/homepage/mbquote.png"
import dots from "../../images/homepage/dots.png"

import quote from "../../images/homepage/quote.png"

import Two_ClutchCable from "../../images/automotive/TwoWheelers/ClutchCable.png"
import GearCableBLACK from "../../images/automotive/ThreeWheelers/GearCableBLACK.png"
import p_HoodReleaseCable from "../../images/automotive/passanger/HoodReleaseCable.png"
import l_AcceleratorCable from "../../images/automotive/LCV_MCV_HCV/AcceleratorCable.png"

// import AcceleratorCable from "../../images/automotive/ThreeWheelers/AcceleratorCable.png"
// import BrakeCable from "../../images/automotive/ThreeWheelers/BrakeCable.png"
// import ChokeCable from "../../images/automotive/ThreeWheelers/ChokeCable.png"
// import ClutchCable from "../../images/automotive/ThreeWheelers/ClutchCable.png"

import t_HandOperatedAcceleratorCable from "../../images/automotive/tractors/HandOperatedAcceleratorCable.png"
import r_WireRopeArrangementforBrakingSystem from "../../images/automotive/railways/WireRopeArrangementforBrakingSystem.png"
import e_GearselectcableEarthMovingEquipment from "../../images/automotive/earth/GearselectcableEarthMovingEquipment.png"
import m_throttlecableandmarinecable from "../../images/automotive/marine/throttlecableandmarinecable.png"

Swiper.use([Navigation, Pagination, Controller, Autoplay])

const HomePage = (props) => {
  const [index, setindex] = useState(0)
  const [tab, setTab] = useState(0)
  const [bg, setBg] = useState(facilities1)
  const [animationShow, setanimationShow] = useState(false)
  const { height, width } = useWindowDimensions()

  const [d, setd] = useState([])
  var swiper, swiper12, swiper4

  const timer = () => {
    setindex((index) => (index >= 6 ? 0 : index + 1))
  }

  useEffect(() => {
    window.scrollTo(0, 0)

    document.title = "Acey - Homepage"

    swiper = new Swiper(".automotiveProductContainer .product-swiper", {
      slidesPerView: 1.2,
      spaceBetween: 0,
      observer: true,
      observeParents: true,
      initialSlide: 0,
      loop: true,
      pagination: {
        el: ".productContainer .automotiveProductContainer .swiper-pagination",
        clickable: true,
      },
      breakpoints: {
        834: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 2,
        },
      },
    })

    swiper12 = new Swiper(".nonautomotiveProductContainer .product-swiper2", {
      slidesPerView: 1.2,
      spaceBetween: 0,
      observer: true,
      initialSlide: 0,
      observeParents: true,
      loop: true,
      pagination: {
        el: ".swiperpagination2",
        clickable: true,
      },
      breakpoints: {
        834: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 2,
        },
      },
    })

    setd(swiper12)

    const swiper2 = new Swiper(".facilities-swiper", {
      slidesPerView: 1,
      spaceBetween: 0,
      loop: true,
      pagination: {
        el: ".homepg-sec4 .swiper-pagination",
        clickable: true,
      },
    })

    const swiper3 = new Swiper(".partnerContainer-swiper", {
      slidesPerView: 1,
      spaceBetween: 0,
      loop: true,
      pagination: {
        el: ".homepg-sec5 .swiper-pagination",
        clickable: true,
      },
      breakpoints: {
        834: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 2,
        },
      },
    })
    swiper4 = new Swiper(".testominal-swiper", {
      slidesPerView: 1,
      spaceBetween: 0,
      loop: true,
      disableOnInteraction: true,
      autoplay: {
        delay: 3000,
      },
      pagination: {
        el: ".homepg-sec6 .swiper-pagination",
        clickable: true,
      },
      navigation: {
        nextEl: ".next",
        prevEl: ".prev",
      },
    })
    new WOW.WOW({
      live: false,
    }).init()

    const interval = setInterval(timer, 5000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  const onMuseEnterClick = () => {
    var mySwiper = document.querySelector(".testominal-swiper").swiper
    mySwiper.autoplay.stop()
  }

  const onMuseExitClick = () => {
    var mySwiper = document.querySelector(".testominal-swiper").swiper
    mySwiper.autoplay.start()
  }

  var imagelist = <div></div>
  var DescrSpan = <div></div>

  var item1 = <div></div>
  var item2 = <div></div>
  var item3 = <div></div>
  var item4 = <div></div>

  useEffect(() => {
    setanimationShow(true)
    setTimeout(() => {
      setanimationShow(false)
    }, 2000)
  }, [index])

  switch (index) {
    case 0:
      imagelist = (
        <div className="wow fadeIn">
          <Image src={bike} placeholder={<Image src={bike} />} />
        </div>
      )
      DescrSpan = <span>2 Wheelers:</span>

      item1 = "Throttle Cable"
      item2 = "Clutch  Cable"
      item3 = "Brake Cable"
      item4 = "Speedometer Cable"

      break

    case 1:
      imagelist = (
        <div className="wow fadeIn">
          <Image src={auto} placeholder={<Image src={auto} />} />
        </div>
      )

      DescrSpan = <span>3 Wheelers:</span>

      item1 = "Clutch Cable"
      item2 = "Accelerator Cable"
      item3 = "Gear Cable"
      item4 = "Decompression Cable"

      break

    case 2:
      imagelist = (
        <div className="wow fadeIn">
          <Image src={fourwheeler} placeholder={<Image src={fourwheeler} />} />
        </div>
      )

      DescrSpan = <span>Passenger Vehicles (PV):</span>

      item1 = "Accelerator Cable"
      item2 = "Clutch Cable"
      item3 = "Hood Release Cable"
      item4 = "Gear Shifter/ Selector Cable"

      break

    case 3:
      imagelist = (
        <div className="wow fadeIn">
          <Image src={tempo} placeholder={<Image src={tempo} />} />
        </div>
      )

      DescrSpan = <span>Light Commercial Vehicles (LCV):</span>

      item1 = "Accelerator Cable"
      item2 = "Dipstick assembly"
      item3 = "PARKING BRAKE CABLE Rear"
      item4 = "Bonnet release Cable"

      break

    case 4:
      imagelist = (
        <div className="wow fadeIn">
          <Image src={tractor} placeholder={<Image src={tractor} />} />
        </div>
      )

      DescrSpan = <span>Tractors:</span>

      item1 = "Throttle Cable"
      item2 = "Stop Cable"
      item3 = "Transmission Cable"
      item4 = "Foot Operated Accelerator Cable"

      break

    case 5:
      imagelist = (
        <div className="wow fadeIn">
          <Image src={jcb} placeholder={<Image src={jcb} />} />
        </div>
      )

      DescrSpan = <span>Earth Moving Equipment:</span>

      item1 = "Gear Select Assy with Cable"

      break

    case 6:
      imagelist = (
        <div className="wow fadeIn">
          <Image src={train} placeholder={<Image src={train} />} />
        </div>
      )

      DescrSpan = <span>Locomotives:</span>

      item1 = "WIRE ROPE ARRANGEMENT FOR BRAKING SYSTEM"

      break

    default:
      break
  }

  const VisibleBorder = (v) => {
    if (v === true) {
      document.getElementById("middlecontainer").classList.add("activess")
    }
  }

  const sec1SeeMoreProductClick = () => {
    switch (index) {
      case 0:
        props.history.push("/product/automotive/twowheelers")
        break
      case 1:
        props.history.push("/product/automotive/threeWheelers")
        break
      case 2:
        props.history.push("/product/automotive/passengerVehicles")
        break

      case 3:
        props.history.push("/product/automotive/lcvmcvhcv")
        break
      case 4:
        props.history.push("/product/nonautomotive/tractors")
        break
      case 5:
        props.history.push("/product/nonautomotive/earthmovingequipment")
        break

      case 6:
        props.history.push("/product/nonautomotive/railways")
        break

      default:
        break
    }
  }

  const viewAllAppClick = () => {
    props.history.push("/product/nonautomotive/otherApplication")
  }

  const ViewAllProductClick = () => {
    switch (tab) {
      case 0:
        props.history.push("/product/automotive")
        break

      case 1:
        props.history.push("/product/nonautomotive")
        break

      default:
        break
    }
  }

  useEffect(() => {
    const swiper14 = new Swiper(".automotiveProductContainer .product-swiper", {
      slidesPerView: 1.2,
      spaceBetween: 0,
      observer: true,
      observeParents: true,
      initialSlide: 0,
      loop: true,
      pagination: {
        el: ".productContainer .automotiveProductContainer .swiper-pagination",
        clickable: true,
      },
      breakpoints: {
        834: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 2,
        },
      },
    })

    const swiper13 = new Swiper(
      ".nonautomotiveProductContainer .product-swiper2",
      {
        slidesPerView: 1.2,
        spaceBetween: 0,
        observer: true,
        initialSlide: 0,
        observeParents: true,
        loop: true,
        pagination: {
          el: ".swiperpagination2",
          clickable: true,
        },
        breakpoints: {
          834: {
            slidesPerView: 2,
          },
          768: {
            slidesPerView: 2,
          },
        },
      }
    )
  }, [tab])

  const twoclick = () => {
    const swiper12 = new Swiper(
      ".nonautomotiveProductContainer .product-swiper2",
      {
        slidesPerView: 1.2,
        spaceBetween: 0,
        observer: true,
        initialSlide: 0,
        observeParents: true,
        loop: true,
        pagination: {
          el: ".swiperpagination2",
          clickable: true,
        },
        breakpoints: {
          834: {
            slidesPerView: 2,
          },
          768: {
            slidesPerView: 2,
          },
        },
      }
    )

    setTab(1)

    // const interval = setInterval(()=>{
    //     setTab(1)
    //     clearInterval(interval);
    // },1)
  }

  return (
    <>
      <section className="homepg-sec1">
        <Row className="row wow ">
          <Col lg={9}>
            <div className="commitedText wow fadeIn">
              COMMITTED TO DELIVERING<br></br>EXCELLENCE
            </div>
            <div className="browsethroughttext wow fadeIn">
              Browse through our range of products for {DescrSpan}
            </div>
            <div className="cablelistcontainer">
              <div className="d-f cablelistone wow fadeIn ">
                <div
                  style={
                    (index === 5 && width < 1023) ||
                    (index === 6 && width < 1023)
                      ? {
                          width: "58%",
                          textAlign: "center",
                          margin: "auto",
                          padding: "0",
                        }
                      : null
                  }
                  className="cabletext w-50"
                >
                  {item1}
                </div>
                <div
                  style={
                    index === 5 || index === 6
                      ? { width: "0%", padding: "0" }
                      : null
                  }
                  className="cabletext  w-50"
                >
                  {item2}
                </div>
              </div>

              <div className="d-f cablelisttwo wow fadeIn ">
                <div className="cabletext w-50">{item3}</div>
                <div style={{ marginRight: "0px" }} className="cabletext  w-50">
                  {item4}
                </div>
              </div>
            </div>

            <div className="btnContainer">
              <Button
                onClick={sec1SeeMoreProductClick}
                small={width >= 1024 ? true : false}
                title="SEE MORE PRODUCTS"
              />
            </div>
          </Col>
          <Col
            lg={15}
            className={
              animationShow ? "secondcol animationfadeIn" : "secondcol"
            }
            className="secondcol"
          >
            {imagelist}
          </Col>
        </Row>

        <div className="thubnaildivContainer wow fadeIn">
          <div
            onClick={() => setindex(0)}
            className={index === 0 ? "selected" : "thumbnaildiv"}
          >
            <img className="fluid thmbImg" src={bikethumb} />
          </div>
          <div
            onClick={() => setindex(1)}
            className={index === 1 ? "selected" : "thumbnaildiv"}
          >
            <img className="fluid thmbImg" src={autothumb} />
          </div>
          <div
            onClick={() => setindex(2)}
            className={index === 2 ? "selected" : "thumbnaildiv"}
          >
            <img className="fluid thmbImg" src={fourwheelerthumb} />
          </div>
          <div
            onClick={() => setindex(3)}
            className={index === 3 ? "selected" : "thumbnaildiv"}
          >
            <img className="fluid thmbImg" src={tempothumb} />
          </div>
          <div
            onClick={() => setindex(4)}
            className={index === 4 ? "selected" : "thumbnaildiv"}
          >
            <img className="fluid thmbImg" src={tractorthumb} />
          </div>
          <div
            onClick={() => setindex(5)}
            className={index === 5 ? "selected" : "thumbnaildiv"}
          >
            <img className="fluid thmbImg" src={jcbthumb} />
          </div>
          <div
            onClick={() => setindex(6)}
            className={index === 6 ? "selected" : "thumbnaildiv"}
          >
            <img className="fluid thmbImg" src={trainthumb} />
          </div>
          <div onClick={viewAllAppClick} className="viewalldiv c-p">
            <div className="viewalltext">
              View More<br></br>Applications
            </div>
          </div>
        </div>
      </section>
      <section className="homepg-sec2 wow fadeIn">
        {width >= 1024 ? (
          <>
            {width > 1280 ? (
              <div className="dotContainer1">
                <div className="d-f f-c">
                  <div
                    className="wow fadeIn d-f f-c"
                    data-wow-duration="2s"
                    data-wow-delay=".5s"
                  >
                    <img src={dots} className="fluid dots" />
                  </div>
                </div>
              </div>
            ) : null}
            <div className="line"></div>
            <div className="rotatingText">
              <div>
                Customer First Approach, Transparency, Long Term Relationships,
                Sustainable Growth, Deep Rooted Morals Customer First Approach,
                Transparency, Long Term Relationships, Sustainable Growth, Deep
                Rooted Morals Customer First Approach, Transparency, Long Term
                Relationships, Sustainable Growth, Deep Rooted Morals Customer
                First Approach, Transparency, Long Term Relationships,
                Sustainable Growth, Deep Rooted Morals Customer First Approach,
                Transparency, Long Term Relationships, Sustainable Growth, Deep
                Rooted Morals Customer First Approach, Transparency, Long Term
                Relationships, Sustainable Growth, Deep Rooted Morals&nbsp;
              </div>
            </div>

            <div className="firstCounter">
              <CounterContainer
                img={cablesperyear}
                title="Cables per year"
                span="M+"
                count="25"
              />
            </div>
            <div className="secondCounter">
              <CounterContainer
                img={journey}
                title="Years of journey"
                span="+"
                count="40"
              />
            </div>
            <div className="thirdCounter">
              <CounterContainer
                img={factories}
                title="Factories"
                span=""
                count="03"
              />
            </div>
            <div className="fourthCounter">
              <CounterContainer
                img={varietyofcables}
                title="Variety of cables"
                span="+"
                count="50"
              />
            </div>

            <VisibilitySensor onChange={VisibleBorder}>
              <div> .</div>
            </VisibilitySensor>
            <div id="middlecontainer" className="middleContainer">
              <div className="rotatecontainer">
                <div id="halfclip">
                  <div className="halfcircle" id="clipped"></div>
                </div>
                <div className="halfcircle" id="fixed"></div>
              </div>
              <div className="title">
                proven expertise <br></br>
                <span>Since 1980</span>
              </div>
              <div className="descr">
                ACEY Engineering Pvt. Ltd. has succeeded<br></br> in making a
                mark as one of India's leading manufacturer and<br></br>{" "}
                supplier of control cables to major original equipment<br></br>{" "}
                manufacturers (OEM) in the automobile industry.<br></br>
                <br></br>We take pride in being the single largest source of
                control<br></br> cables to <span>Bajaj Auto Ltd.</span> for
                their three-wheelers and{" "}
                <span>
                  Tata<br></br> Motors
                </span>{" "}
                for their commercial vehicles.<br></br>
                <br></br>With a solid base in South Gujarat, we have expanded
                Acey’s<br></br> portfolio to deliver products and services
                anywhere in the<br></br> country.
              </div>

              <div className="knowmorebtncontainer">
                <Button
                  onClick={() => props.history.push("/ourstory")}
                  title="KNOW MORE"
                />
              </div>
            </div>
          </>
        ) : (
          <div className="middleContainerMob">
            <div className="title">
              proven expertise<br></br> <span>since 1980</span>
            </div>
            <div className="descr">
              Established in 1980, ACEY Engineering Pvt. Ltd. has succeeded in
              making a mark as one of India's leading manufacturers and
              suppliers of control cables to major original equipment
              manufacturers (OEM) in the automobile industry.<br></br>
              <br></br>We take pride in being the single largest source of
              control cables to <span>Bajaj Auto Ltd.</span> for their
              three-wheelers and <span>Tata Motors</span> for their commercial
              vehicles.<br></br>
              <br></br>With a solid base in South Gujarat, we have expanded
              Acey’s portfolio to deliver products and services anywhere in the
              country.
            </div>

            <div className="knowmorebtncontainer">
              <Button
                onClick={() => props.history.push("/ourstory")}
                title="KNOW MORE"
              />
            </div>

            <div className="counter_container d-f f-c">
              <div className="d-f">
                <CounterContainer
                  img={mbcablesperyear}
                  title="Cables per year"
                  span="M+"
                  count={25}
                />
                <CounterContainer
                  img={mbjourney}
                  title="Years of journey"
                  span="+"
                  count={40}
                />
              </div>
              <div className="d-f">
                <CounterContainer
                  img={mbfactories}
                  title="Factories"
                  span=""
                  count={3}
                />
                <CounterContainer
                  img={mbvarietyofcables}
                  title="Variety of cables"
                  span="+"
                  count={50}
                />
              </div>
            </div>
          </div>
        )}
      </section>
      <section className="homepg-sec3 wow fadeInUp">
        <div className="ourProductText ">Our Products</div>

        <div className="d-f tabcontainer">
          <Tab
            onClick={() => setTab(0)}
            active={tab === 0 ? true : false}
            title="AUTOMOTIVE"
          />
          <Tab
            onClick={() => {
              twoclick()
            }}
            active={tab === 1 ? true : false}
            title="NON-AUTOMOTIVE"
          />
        </div>

        <div className="productContainer">
          {width >= 1024 ? (
            <>
              {" "}
              {width > 1280 ? (
                <div className="dotContainer1">
                  <div className="d-f f-c">
                    <div
                      className="wow fadeIn d-f f-c"
                      data-wow-duration="2s"
                      data-wow-delay=".5s"
                    >
                      <div>
                        <img src={dots} className="fluid dots" />
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              {tab === 0 ? (
                <Row justify="center">
                  <Col lg={6}>
                    <ProductItem
                      br={true}
                      img={Two_ClutchCable}
                      title="CLUTCH CABLE"
                      decr="Accelerator cables are used in 2 and 3 wheelers, LCV, MCV, HCV, PV and tractors."
                    />
                  </Col>
                  <Col lg={6}>
                    <ProductItem
                      img={GearCableBLACK}
                      title="GEAR CABLE"
                      decr="Brake cables are used in 2 and 3 wheelers, LCV, MCV, HCV, PV, tractors, electric vehicles, railways and others."
                    />
                  </Col>
                  <Col lg={6}>
                    <ProductItem
                      br2={true}
                      img={p_HoodReleaseCable}
                      title="HOOD RELEASE CABLE"
                      decr="Choke cables are used in 2 and 3 wheelers, LCV and cars."
                    />
                  </Col>
                  <Col lg={6}>
                    <ProductItem
                      br={true}
                      img={l_AcceleratorCable}
                      title="ACCELERATOR CABLE"
                      decr="Clutch cables are used in 2 and 3 wheelers, LCV, HCV, cars and tractors."
                    />
                  </Col>
                </Row>
              ) : (
                <Row justify="center">
                  <Col lg={6}>
                    <ProductItem
                      br2={true}
                      img={t_HandOperatedAcceleratorCable}
                      title="Hand operated accelerator cables"
                      decr="Hand operated accelerator cables are used in tractors for agriculture purposes."
                    />
                  </Col>
                  <Col lg={6}>
                    <ProductItem
                      img={r_WireRopeArrangementforBrakingSystem}
                      title="Wire rope arrangement for braking system"
                      decr="The wire rope arrangement for the railway braking system offers optimum tensile strength."
                    />
                  </Col>
                  <Col lg={6}>
                    <ProductItem
                      br2={true}
                      img={e_GearselectcableEarthMovingEquipment}
                      title="Gear Select assy with cable"
                      decr="The push pull cable assembly in a precise design works for an earth-moving equipment."
                    />
                  </Col>
                  <Col lg={6}>
                    <ProductItem
                      br3={true}
                      img={m_throttlecableandmarinecable}
                      title="Throttle cables"
                      decr="Marine throttle and shift systems are kept operating smoothly with throttle cables."
                    />
                  </Col>
                </Row>
              )}
            </>
          ) : (
            <>
              {tab === 0 ? (
                <div className="automotiveProductContainer">
                  <div className="product-swiper swiper-container">
                    <div className="swiper-wrapper">
                      <div className="swiper-slide">
                        <ProductItem
                          br={true}
                          img={Two_ClutchCable}
                          title="CLUTCH CABLE"
                          decr="Accelerator cables are used in 2 and 3 wheelers, LCV, MCV, HCV, PV and tractors."
                        />
                      </div>
                      <div className="swiper-slide">
                        <ProductItem
                          img={GearCableBLACK}
                          title="GEAR CABLE"
                          decr="Brake cables are used in 2 and 3 wheelers, LCV, MCV, HCV, PV, tractors, electric vehicles, railways and others."
                        />
                      </div>

                      <div className="swiper-slide">
                        <ProductItem
                          br2={true}
                          img={p_HoodReleaseCable}
                          title="HOOD RELEASE CABLE"
                          decr="Choke cables are used in 2 and 3 wheelers, LCV and cars."
                        />
                      </div>

                      <div className="swiper-slide">
                        <ProductItem
                          br={true}
                          img={l_AcceleratorCable}
                          title="ACCELERATOR CABLE"
                          decr="Clutch cables are used in 2 and 3 wheelers, LCV, HCV, cars and tractors."
                        />
                      </div>
                    </div>
                  </div>
                  <div className="swiper-pagination"></div>
                </div>
              ) : null}
              {tab === 1 ? (
                <div className="nonautomotiveProductContainer">
                  <div className="product-swiper2 swiper-container">
                    <div className="swiper-wrapper">
                      <div className="swiper-slide">
                        <ProductItem
                          br2={true}
                          img={t_HandOperatedAcceleratorCable}
                          title="Hand operated accelerator cables"
                          decr="Hand operated accelerator cables are used in tractors for agriculture purposes."
                        />
                      </div>
                      <div className="swiper-slide">
                        <ProductItem
                          img={r_WireRopeArrangementforBrakingSystem}
                          title="Wire rope arrangement for braking system"
                          decr="The wire rope arrangement for the railway braking system offers optimum tensile strength."
                        />
                      </div>

                      <div className="swiper-slide">
                        <ProductItem
                          br2={true}
                          img={e_GearselectcableEarthMovingEquipment}
                          title="Gear Select assy with cable"
                          decr="The push pull cable assembly in a precise design works for an earth-moving equipment."
                        />
                      </div>

                      <div className="swiper-slide">
                        <ProductItem
                          br3={true}
                          img={m_throttlecableandmarinecable}
                          title="Throttle cables"
                          decr="Marine throttle and shift systems are kept operating smoothly with throttle cables."
                        />
                      </div>
                    </div>
                  </div>
                  <div className="swiper-pagination swiperpagination2"></div>
                </div>
              ) : null}
            </>
          )}

          <div className="knowmorebtncontainer d-f j-c a-c">
            <Button onClick={ViewAllProductClick} title="VIEW ALL PRODUCTS" />
          </div>
        </div>
      </section>
      <section className="homepg-sec4 wow fadeInUp" data-wow-delay=".5s">
        <div className="p-r">
          <div className="title">
            We are one of the only cable manufacturers in India to have these
            manufacturing facilities in-house
          </div>
          {width > 1280 ? (
            <div className="dotContainer1">
              <div className="d-f f-c">
                <div
                  className="wow fadeIn"
                  data-wow-duration="2s"
                  data-wow-delay=".5s"
                >
                  <img src={dots} className="fluid dots" />
                </div>
              </div>
            </div>
          ) : null}
        </div>

        {width >= 1024 ? (
          <>
            <div
              className="facilities_container d-f"
              style={{ backgroundImage: `url(${bg})` }}
            >
              <FacilityHomeItem
                title="Casting"
                onClick={() => props.history.push("/manufacturingFacilities")}
                desc="Highly suited to manufacture complex components & casted ends in high volumes"
                onEnter={() => setBg(facilities1)}
              />

              <FacilityHomeItem
                title="Machine shop"
                onClick={() => props.history.push("/manufacturingFacilities")}
                desc="For end-to-end, comprehensive building, machining, repairs and quality check"
                onEnter={() => setBg(facilities3)}
              />

              <FacilityHomeItem
                onClick={() => props.history.push("/manufacturingFacilities")}
                title="Inner manufacturing"
                desc="The interior mechanism of a control cable"
                onEnter={() => setBg(facilities4)}
              />

              <FacilityHomeItem
                onClick={() => props.history.push("/manufacturingFacilities")}
                title="Outer MANUFACTURING"
                desc="The exterior sheath of a control cable"
                onEnter={() => setBg(facilities5)}
              />
              <FacilityHomeItem
                title="PLASTIC MOULDING"
                onClick={() => props.history.push("/manufacturingFacilities")}
                desc="Suitable alternatives for reduction in costs and weight"
                onEnter={() => setBg(facilities2)}
              />
            </div>
          </>
        ) : (
          <>
            <div className="facilities-swiper swiper-container">
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <FacilityHomeItem
                    onClick={() =>
                      props.history.push("/manufacturingFacilities")
                    }
                    mobile
                    bg={facilitiesmob1}
                    title="CASTING"
                    desc="Highly suited to manufacture complex components & casted ends in high volumes"
                  />
                </div>

                <div className="swiper-slide">
                  <FacilityHomeItem
                    onClick={() =>
                      props.history.push("/manufacturingFacilities")
                    }
                    mobile
                    bg={facilitiesmob3}
                    title="Machine shop"
                    desc="For end-to-end, comprehensive building, machining, repairs and quality check"
                  />
                </div>
                <div className="swiper-slide">
                  <FacilityHomeItem
                    onClick={() =>
                      props.history.push("/manufacturingFacilities")
                    }
                    mobile
                    bg={facilitiesmob4}
                    title="Inner manufacturing"
                    desc="The interior mechanism of a control cable"
                  />
                </div>
                <div className="swiper-slide">
                  <FacilityHomeItem
                    onClick={() =>
                      props.history.push("/manufacturingFacilities")
                    }
                    mobile
                    bg={facilitiesmob5}
                    title="Outer MANUFACTURING"
                    desc="The exterior sheath of a control cable"
                  />
                </div>
                <div className="swiper-slide">
                  <FacilityHomeItem
                    onClick={() =>
                      props.history.push("/manufacturingFacilities")
                    }
                    mobile
                    bg={facilitiesmob2}
                    title="PLASTIC MOULDING"
                    desc="Suitable alternatives for reduction in costs and weight"
                  />
                </div>
              </div>
            </div>
            <div className="swiper-pagination"></div>
          </>
        )}
      </section>
      <section className="homepg-sec5 wow fadeInUp" data-wow-delay=".5s">
        <div className="title">our valued business partners</div>
        {width > 1280 ? (
          <div className="dotContainer1">
            <div className="d-f f-c">
              <div
                className="wow fadeIn"
                data-wow-duration="2s"
                data-wow-delay=".5s"
              >
                <img src={dots} className="fluid dots" />
              </div>
            </div>
          </div>
        ) : null}
        {width >= 1024 ? (
          <Row justify="center" className="logoContainer">
            <Col md={5}>
              <div className="d-f f-c iconItem">
                <div className="first d-f j-c a-c">
                  <img className="fluid logoIcon" src={bajaj} />
                </div>
                <div className="d-f j-c a-c second">
                  <img className="fluid logoIcon" src={lt} />
                </div>
              </div>
            </Col>
            <Col md={5}>
              <div className="d-f f-c iconItem">
                <div className="first d-f j-c a-c">
                  <img className="fluid logoIcon" src={tata} />
                </div>
                <div className="d-f j-c a-c second">
                  <img className="fluid logoIcon" src={jbm} />
                </div>
              </div>
            </Col>
            <Col md={5}>
              <div className="d-f f-c iconItem">
                <div className="first d-f j-c a-c">
                  <img className="fluid logoIcon" src={force} />
                </div>
                <div className="d-f j-c a-c second">
                  <img className="fluid logoIcon" src={mahendra} />
                </div>
              </div>
            </Col>
            <Col md={5}>
              <div className="d-f f-c iconItem last">
                <div className="first d-f j-c a-c">
                  <img className="fluid logoIcon" src={fiat} />
                </div>
                <div className="d-f j-c a-c second">
                  <img className="fluid logoIcon" src={Escorts} />
                </div>
              </div>
            </Col>
          </Row>
        ) : (
          <>
            <div className="partnerContainer-swiper swiper-container">
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <div className="d-f">
                    <div className="d-f j-c a-c">
                      <img className="fluid" src={bajaj} />
                    </div>
                    <div className="d-f j-c a-c second">
                      <img className="fluid" src={tata} />
                    </div>
                  </div>
                  <div className="d-f swipersecondrow">
                    <div className="d-f j-c a-c">
                      <img className="fluid" src={lt} />
                    </div>
                    <div className="d-f j-c a-c">
                      <img className="fluid" src={jbm} />
                    </div>
                  </div>
                </div>

                <div className="swiper-slide">
                  <div className="d-f">
                    <div className="d-f j-c a-c">
                      <img className="fluid" src={force} />
                    </div>
                    <div className="d-f j-c a-c second">
                      <img className="fluid" src={fiat} />
                    </div>
                  </div>
                  <div className="d-f swipersecondrow">
                    <div className="d-f j-c a-c">
                      <img className="fluid" src={mahendra} />
                    </div>
                    <div className="d-f j-c a-c">
                      <img className="fluid" src={Escorts} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-pagination"></div>
          </>
        )}

        <div className="btnContainer">
          <Button
            onClick={() => props.history.push("/clients")}
            title="VIEW ALL"
          />
        </div>
      </section>
      <section className="homepg-sec6 wow fadeInUp" data-wow-delay=".5s">
        <div className="title">customer testimonials</div>

        <div className="tesominalsContainer">
          {width >= 1024 ? (
            <div className="d-f ">
              <div>
                <img className="img-fluid quote" src={quote} />
              </div>

              <div
                className="testominal-swiper swiper-container"
                onMouseLeave={onMuseExitClick}
                onMouseEnter={onMuseEnterClick}
              >
                <div className="swiper-wrapper">
                  <div className="swiper-slide">
                    <div className="tc_title"> Anal Vijay Singh</div>
                    <div className="tc_subtitle">
                      Plant head – Tata Motors Limited :Pantnagar
                    </div>
                    <div className="tc_desc">
                      Since the start of production at Tata Motors, Pantnagar,
                      Acey Engineering has been a strategic partner for all our
                      requirements of Control Cables. The support and capability
                      of the whole Acey team is commendable and instrumental to
                      the partnership between our organisations.
                    </div>
                  </div>

                  <div className="swiper-slide">
                    <div className="tc_title">D. V. Ranganath</div>
                    <div className="tc_subtitle">
                      Head materials – Bajaj Auto Limited
                    </div>
                    <div className="tc_desc">
                      Acey Engineering has been a major strategic source for our
                      requirements of CV-control cables since 1985. It has been
                      journey of unbreakable trust in terms of Quality and
                      Supply and excellent vendor rating.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="mobtesominalsContainer wow fadeIn">
              <div className="mbquote">
                <img className="fluid" src={mbquote} />
              </div>
              <div className="testominal-swiper swiper-container">
                <div className="swiper-wrapper">
                  <div className="swiper-slide">
                    <div className="tc_title">Anal Vijay Singh</div>
                    <div className="tc_subtitle">
                      Plant head – Tata Motors Limited :Pantnagar
                    </div>
                    <div className="tc_desc">
                      Since the start of production at Tata Motors, Pantnagar,
                      Acey Engineering has been a strategic partner for all our
                      requirements of Control Cables. The support and capability
                      of the whole Acey team is commendable and instrumental to
                      the partnership between our organisations.
                    </div>
                  </div>

                  <div className="swiper-slide">
                    <div className="tc_title">D. V. Ranganath</div>
                    <div className="tc_subtitle">
                      Head materials – Bajaj Auto Limited
                    </div>
                    <div className="tc_desc">
                      Acey Engineering has been a major strategic source for our
                      requirements of CV-control cables since 1985. It has been
                      journey of unbreakable trust in terms of Quality and
                      Supply and excellent vendor rating.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="d-f w-f jc-sb">
            <div className="prev">Prev</div>
            <div className="next">Next</div>
          </div>
        </div>
        {width > 1024 ? (
          <div className="swiper-pagination"></div>
        ) : (
          <div className="swiper-pagination testominal"></div>
        )}
      </section>

      <section>
        <div className="footerfirstContainer">
          <Row className="row">
            <Col lg={8} xs={24} className="j-c  d-f">
              <div className="d-f j-c a-c">
                {" "}
                <img className="logo" src={logo} />
              </div>
            </Col>
            <Col lg={2}></Col>
            <Col lg={14} md={24}>
              <div className="d-f getintouchcontainer">
                <div className="d-f f-c">
                  <div className="getintouchtext">Get in touch with us</div>
                  <div className="pleasefreetxt">
                    Please feel free to send us your business enquiries or
                    feedback.
                  </div>
                </div>

                <div className="btnContainer">
                  <Button
                    white
                    onClick={() => props.history.push("/contactus")}
                    title="ENQUIRE NOW"
                  />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </>
  )
}

export default HomePage
