import React from 'react'

import './style.less'


const ProductItem = props => {
    return <div className="productItem">
    <img src={props.img} className="fluid" />
    <div className="title">{props.title}</div>
    {/* <div className="descr">{props.decr}{props.br ? <><br></br><br></br> </>:null} {props.br2 ?<> <br></br><br></br><br></br></>:null} {props.br3 ?<> <br></br><br></br><br></br><br></br></>:null} </div> */}
    </div>
}

export default ProductItem